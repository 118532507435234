import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { Table, Button, TableButtons, ConfirmationModal } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { getLocale } from 'shared/DatePicker/constants';
import '../../../styles.scss';

import {
  getWorkerVacations,
  addWorkerVacations,
  editWorkerVacations,
  deleteWorkerVacations,
  getPaginatedWorkerVacations,
} from '../actions';

const Vacations = ({ t, isReadOnly, currentUser, workerId, companyId, updateAbsenceAndVacationDays, currentUserRole }) => {
  const [workerVacations, setWorkerVacations] = useState({
    data: [],
    isLoading: true,
    count: 0,
    next: null,
    previous: null,
  });
  const [sortingAndFilteringDays, setSortingAndFilteringDays] = useState({
    selectedSort: 'year',
    selectedAscDesc: 'desc',
  });
  const [workerVacationsTablePage, setWorkerVacationsTablePage] = useState(0);
  const [workerVacationsModalData, setWorkerVacationsModalData] = useState({});

  const [showConfirmationDialogVacations, setShowConfirmationDialogVacations] = useState(false);
  const [deleteDataVacations, setDeleteDataVacations] = useState(null);

  const handleAddDaysForAnotherYear = () => {
    setWorkerVacationsTablePage(1);
    setWorkerVacationsModalData({
      year: moment().format('YYYY'),
    });
  };

  const handleBackToDaysTable = () => {
    setWorkerVacationsModalData({});
    setWorkerVacationsTablePage(0);
  };

  const handleSortingWorkerVacations = (newSorted) => {
    setSortingAndFilteringDays((prevState) => ({
      ...prevState,
      selectedSort: newSorted.id,
      selectedAscDesc: newSorted.desc ? 'desc' : 'asc',
    }));
  };

  const fetchWorkerVacations = async () => {
    const asc = sortingAndFilteringDays?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${sortingAndFilteringDays?.selectedSort}`;

    await getWorkerVacations(companyId, workerId, apiFilters)
      .then((res) => {
        setWorkerVacations({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => setWorkerVacations((prevState) => ({ ...prevState, isLoading: false })));
  };

  const fetchPaginatedWorkerVacations = async (url) => {
    setWorkerVacations((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    await getPaginatedWorkerVacations(url)
      .then((res) => {
        setWorkerVacations({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => setWorkerVacations((prevState) => ({ ...prevState, isLoading: false })));
  };

  useEffect(() => {
    fetchWorkerVacations();
  }, [sortingAndFilteringDays]);

  const handleSaveWorkerVacation = async () => {
    const data = {
      year: moment(workerVacationsModalData.year, 'YYYY').format('YYYY'),
      assigned_days: workerVacationsModalData.assigned_days,
      used_days: workerVacationsModalData.used_days,
      worker: workerId,
      user: currentUser.id,
    };

    if (workerVacationsModalData?.id) {
      await editWorkerVacations(companyId, workerVacationsModalData?.id, data)
        .then(() => {
          fetchWorkerVacations();
          setWorkerVacationsModalData({});
          setWorkerVacationsTablePage(0);
        });
    } else {
      await addWorkerVacations(companyId, data)
        .then(() => {
          fetchWorkerVacations();
          setWorkerVacationsModalData({});
          setWorkerVacationsTablePage(0);
        });
    }
    updateAbsenceAndVacationDays();
  };

  const handleShowConfirmationModalVacations = (row) => {
    setDeleteDataVacations(row);
    setShowConfirmationDialogVacations(true);
  };

  const handleDeleteVacations = async () => {
    await deleteWorkerVacations(companyId, deleteDataVacations?.id)
      .then(() => {
        setDeleteDataVacations(null);
        setShowConfirmationDialogVacations(false);
        fetchWorkerVacations();
      });
    updateAbsenceAndVacationDays();
  };

  const handleVacationModalChange = (key, value) => {
    setWorkerVacationsModalData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleVacationTableRowClick = (row) => {
    setWorkerVacationsModalData(row);
    setWorkerVacationsTablePage(1);
  };

  const tableColumnConfigVacations = [
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.year')}</span>,
      accessor: 'year',
      Cell: (row) => (row?.value ? moment(row.value, 'YYYY').format('YYYY') : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.assigned_days')}</span>,
      accessor: 'assigned_days',
      Cell: (row) => (row?.value || '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.used_days')}</span>,
      accessor: 'used_days',
      Cell: (row) => (row?.value || '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.created_at')}</span>,
      accessor: 'created_at',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.updated_at')}</span>,
      accessor: 'updated_at',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
  ];

  return (
    <div className="worker_details_absence__table2">
        {
            workerVacationsTablePage === 0 ?
            <>
                <div className="worker_details_absence__table2__filters">
                <div className="worker_details_absence__table2__filters__add">
                    <Button type="add" onClick={handleAddDaysForAnotherYear} disabled={(currentUserRole === 'HR' ? false : isReadOnly)}>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.add_days_for_another_year')}</Button>
                </div>
                </div>
                <Table
                  style={{ userSelect: 'text' }}
                  columns={tableColumnConfigVacations}
                  data={workerVacations?.data || []}
                  defaultPageSize={15}
                  loading={workerVacations.isLoading}
                  minRows={0}
                  noDataText={t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.no_data_found')}
                  enableEdit
                  enableDelete
                  onEdit={(row) => handleVacationTableRowClick(row)}
                  onDelete={(row) => handleShowConfirmationModalVacations(row)}
                  isActionsDisabled={(currentUserRole === 'HR' ? false : isReadOnly)}
                  defaultSorted={[{ id: 'year', desc: true }]}
                  onSortedChange={(newSorted) => { handleSortingWorkerVacations(newSorted[0]); }}
                />
                <TableButtons
                  previous={workerVacations.previous}
                  next={workerVacations.next}
                  count={workerVacations.count}
                  fetchFunction={fetchPaginatedWorkerVacations}
                />
            </>
              : workerVacationsTablePage === 1 &&
                <>
                    <div className="worker_details_absence__table2__filters">
                        <div className="worker_details_absence__table2__filters__back">
                            <Button onClick={handleBackToDaysTable}>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.back')}</Button>
                        </div>
                    </div>
                    <div className="worker_details_absence__table2__add_edit_form">
                        <div className="modal_row">
                          <div className="left_text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.year')}:*</div>
                          <div className="right_datePicker">
                            <DatePicker
                              dateFormat="yyyy"
                              showYearPicker
                              placeholderText={t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.select_a_year')}
                              selected={workerVacationsModalData?.year ? moment(workerVacationsModalData.year, 'YYYY').toDate() : null}
                              onChange={(date) => handleVacationModalChange('year', moment(date).format('YYYY'))}
                              locale={getLocale(t)}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                        </div>
                        <div className="modal_row">
                          <div className="left_text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.assigned_days')}:*</div>
                          <div className="right_input">
                              <input type="number" value={workerVacationsModalData?.assigned_days || ''} onChange={(e) => handleVacationModalChange('assigned_days', e.target.value)} />
                          </div>
                        </div>
                        <div className="modal_row">
                          <div className="left_text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.used_days')}:*</div>
                          <div className="right_input">
                              <input type="number" value={workerVacationsModalData?.used_days || ''} onChange={(e) => handleVacationModalChange('used_days', e.target.value)} />
                          </div>
                        </div>
                        <div className="modal_row">
                          <div style={{ marginLeft: 'auto' }}>
                            <Button
                              type="add"
                              disabled={!workerVacationsModalData?.year || !workerVacationsModalData?.assigned_days || !workerVacationsModalData?.used_days}
                              onClick={handleSaveWorkerVacation}
                            >
                                {t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_tab.save')}
                            </Button>
                          </div>
                        </div>
                    </div>
                </>
        }
        <ConfirmationModal
          itemName={`${deleteDataVacations?.year} - ${deleteDataVacations?.assigned_days}` || ''}
          showModal={showConfirmationDialogVacations}
          handleCloseModal={() => setShowConfirmationDialogVacations(false)}
          handleConfirmModal={handleDeleteVacations}
          type="warning"
        />
    </div>
  );
};

Vacations.propTypes = {
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  workerId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  updateAbsenceAndVacationDays: PropTypes.func.isRequired,
};

export default (withTranslation()(Vacations));
