import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ContentLoader, JsonEditor } from 'shared';
import Select from 'react-select';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { withTranslation } from 'react-i18next';
import './style.scss';

class ProductTypeForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      productTypeObject: {
        name: '',
        code: '',
        description: '',
        department: null,
        category: '',
        product_type: '',
        oee_metric: '',
        base_unit: 'PCE',
        technology: null,
        company: props.companyId,
        data: props.productDataTemplate,
        default_warehouse: null,
        is_scrap: false,
      },
    };
    if (props.pType) {
      const { pType } = props;
      const updatedProductTypeObject = {
        ...this.state.productTypeObject,
        name: pType.name || this.state.productTypeObject.name,
        code: pType.code || this.state.productTypeObject.code,
        description: pType.description || this.state.productTypeObject.description,
        department: pType.department || this.state.productTypeObject.department,
        category: pType.category || this.state.productTypeObject.category,
        product_type: pType.product_type || this.state.productTypeObject.product_type,
        oee_metric: pType.oee_metric || this.state.productTypeObject.oee_metric,
        base_unit: pType.base_unit || this.state.productTypeObject.base_unit,
        technology: pType.technology || this.state.productTypeObject.technology,
        company: pType.company || this.state.productTypeObject.company,
        data: pType.data || this.state.productTypeObject.data,
        id: pType.id,
        default_warehouse: pType?.default_warehouse || this.state.productTypeObject.default_warehouse,
        is_scrap: pType?.is_scrap || this.state.productTypeObject.is_scrap,
      };
      this.state = { productTypeObject: updatedProductTypeObject, jsonEditorError: false };
    }
  }

  isNumeric = (str) => {
    return /^\d+$/.test(str);
  }

  handleSave = () => {
    const { productTypeObject } = this.state;
    const { saveProductType } = this.props;

    const data = {
      ...productTypeObject,
      external_id: productTypeObject && productTypeObject.code && this.isNumeric(productTypeObject.code) ? productTypeObject.code : 0,
    };

    saveProductType(data);
  }

  handleModalChange = (key, value) => {
    this.setState((prevState) => ({
      productTypeObject: {
        ...prevState.productTypeObject,
        [key]: value,
      },
    }));
  }

  handleJSONEditorError = (value) => {
    if (value && value.length > 0) {
      this.setState({
        jsonEditorError: true,
      });
    } else {
      this.setState({
        jsonEditorError: false,
      });
    }
  }

  render() {
    const {
      productTypeObject,
      jsonEditorError,
    } = this.state;
    const {
      isOpen,
      closeModal,
      pType,
      t,
      productDepartments,
      returnTechnologies,
      productTypeOptions,
      categoryOptions,
      loading,
      warehouses,
    } = this.props;

    const productDepartment = productTypeObject.department;
    const productTechnology = productTypeObject.technology;
    const availableProductTechnologies = returnTechnologies(productDepartment, productDepartments);

    return (
      <Modal
        disableSave={productTypeObject.name.length === 0 || productTypeObject.code.length === 0 || !productTypeObject.product_type || !productTypeObject.category || jsonEditorError}
        isOpen={isOpen}
        onRequestClose={closeModal}
        handleSave={this.handleSave}
        handleClose={closeModal}
        title={pType ? t('settings.timing_settings.product_type_form.edit_title') : t('settings.timing_settings.product_type_form.add_title')}
      >
        {loading && <ContentLoader />}
        {!loading &&
          <div className="modal_container">
            <div className="modal_row">
              <div className="left_text">{`${t('settings.product_types.table_column_name')} *`}</div>
              <div className="right_input">
                <input onChange={(e) => this.handleModalChange('name', e.target.value)} value={productTypeObject.name} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{`${t('settings.product_types.table_column_code')} *`}</div>
              <div className="right_input">
                <input onChange={(e) => this.handleModalChange('code', e.target.value)} value={productTypeObject.code} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('settings.product_types.table_column_description')}</div>
              <div className="right_input">
                <input onChange={(e) => this.handleModalChange('description', e.target.value)} value={productTypeObject.description} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('settings.product_types.table_column_unit')}</div>
              <div className="right_input">
                <input type="text" maxLength="5" onChange={(e) => this.handleModalChange('base_unit', e.target.value)} value={productTypeObject.base_unit} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('settings.product_types.table_column_oee_metric')}</div>
              <div className="right_input">
                <input type="text" onChange={(e) => this.handleModalChange('oee_metric', e.target.value)} value={productTypeObject.oee_metric} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('settings.product_types.is_scrap')}</div>
              <div className="right_checkbox">
                <input type="checkbox" onChange={(e) => this.handleModalChange('is_scrap', e.target.checked)} checked={productTypeObject?.is_scrap} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('settings.product_types.default_warehouse')}</div>
              <div className="right_select">
                <Select
                  options={warehouses}
                  getOptionLabel={(warehouse) => warehouse.name}
                  getOptionValue={(warehouse) => warehouse.id}
                  isSearchable
                  isClearable
                  placeholder=""
                  menuPosition="fixed"
                  onChange={(value) => this.handleModalChange('default_warehouse', value?.id || null)}
                  value={(warehouses.find((p) => p.id === productTypeObject.default_warehouse)) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('settings.product_types.table_column_department')}</div>
              <div className="right_select">
                <Select
                  isDisabled={productDepartments && productDepartments.length === 0}
                  options={productDepartments}
                  getOptionLabel={(dep) => dep.name}
                  getOptionValue={(dep) => dep.id}
                  isSearchable
                  menuPosition="fixed"
                  placeholder={(productDepartments && productDepartments.length === 0) ? t('settings.timing_settings.product_type_form.no_product_departments') : ''}
                  onChange={(value) => this.handleModalChange('department', value.id)}
                  value={(productDepartments.find((p) => p.id === productTypeObject.department)) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('settings.product_types.table_column_technology')}</div>
              <div className="right_select">
                <Select
                  isDisabled={(productDepartment && availableProductTechnologies.length === 0) || (!productDepartment)}
                  options={(productDepartment && availableProductTechnologies) || []}
                  getOptionLabel={(technology) => technology.name}
                  getOptionValue={(technology) => technology.id}
                  isSearchable
                  menuPosition="fixed"
                  placeholder={(!productDepartment) ? t('settings.timing_settings.product_type_form.technology_requirement') : (productDepartment && availableProductTechnologies.length === 0) ? t('settings.timing_settings.product_type_form.no_technologies') : ''}
                  onChange={(value) => this.handleModalChange('technology', value.id)}
                  value={productDepartments && productDepartment && productTechnology
                    ? availableProductTechnologies.find((tech) => tech.id === productTechnology) || ''
                    : ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{`${t('settings.product_types.table_column_productType')} *`}</div>
              <div className="right_select">
                <Select
                  options={productTypeOptions}
                  getOptionLabel={(ptype) => ptype.name}
                  getOptionValue={(ptype) => ptype.id}
                  isSearchable
                  placeholder=""
                  menuPosition="fixed"
                  onChange={(value) => this.handleModalChange('product_type', value.id)}
                  value={(productTypeOptions.find((p) => p.id === productTypeObject.product_type)) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{`${t('settings.product_types.table_column_category')} *`}</div>
              <div className="right_select">
                <Select
                  options={categoryOptions}
                  getOptionLabel={(cat) => cat.name}
                  getOptionValue={(cat) => cat.id}
                  isSearchable
                  placeholder=""
                  menuPosition="fixed"
                  onChange={(value) => this.handleModalChange('category', value.id)}
                  value={(categoryOptions.find((p) => p.id === productTypeObject.category)) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('settings.timing_settings.product_type_form.data')}</div>
              <div className="right_select">
                <JsonEditor
                  value={productTypeObject.data}
                  onChange={(e) => { this.handleModalChange('data', e); }}
                  isError={this.handleJSONEditorError}
                />
              </div>
            </div>
          </div>}
      </Modal>
    );
  }
}

ProductTypeForm.propTypes = {
  pType: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  saveProductType: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  productDepartments: PropTypes.array.isRequired,
  returnTechnologies: PropTypes.func.isRequired,
  productTypeOptions: PropTypes.array.isRequired,
  categoryOptions: PropTypes.array.isRequired,
  productDataTemplate: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  warehouses: PropTypes.array.isRequired,
};

export default withTranslation()(ProductTypeForm);
