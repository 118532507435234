import React, { useEffect, useState, useRef } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import leftChevron from 'shared/Icons/left-chevron.png';
import rightChevron from 'shared/Icons/right-chevron.png';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Calendar from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';

import { Button } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { getProcurementItems, getMaterialTypes } from '../actions';
import '../styles.scss';
import DeliveryLegend from './DeliveryLegend';

const ProcurementItemsCalendar = ({ t, companyId, locationId, statusDropdownOptions }) => {
  const calendarRef = useRef();
  const [tableData, setTableData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });

  const [filters, setFilters] = useState({
    materialType: null,
    status: 'all',
  });

  const [materialTypes, setMaterialTypes] = useState({
    data: [],
    isLoading: false,
  });

  const [view, setView] = useState('month');

  const installationLocationsOptions = [
    { id: 'factory', name: t('page_content.procurement.details.items_table.installation_location_factory') },
    { id: 'site', name: t('page_content.procurement.details.items_table.installation_location_site') },
  ];

  const isDelivered = (item) => {
    return !!item?.delivery_date;
  };

  const isPlannedDeliveryDatePassed = (item) => {
    return item?.planned_delivery_date ? moment().isAfter(moment(item?.planned_delivery_date), 'day') : false;
  };

  const transformData = (data) => {
    return data.map((o) => {
      o.title = `${o?.name} ${o?.quantity}${o.unit} (${o?.installation_location ? installationLocationsOptions.find((i) => i.id === o?.installation_location)?.name : '-'})`;
      o.body = `<b>${t('page_content.procurement.details.items_table.table_column_ordered_quantity')}:</b> ${o?.ordered_quantity}<br>
      <b>${t('page_content.procurement.details.items_table.table_column_delivery_date')}:</b> ${o?.delivery_date ? moment(o?.delivery_date).format(defaultDateFormat) : '-'}<br>
      <b>${t('page_content.procurement.details.items_table.table_column_note')}:</b> ${o?.note}<br>
      <a href="/${companyId}/industry/location/${locationId}/procurement/order/${o?.procurement?.id}" target="_blank">${t('page_content.procurement.details.items_table.open_procurement_order')}</a>`;
      o.caledarId = '1';
      o.category = 'allday';
      o.isVisible = true;
      o.dueDateClass = '';
      o.bgColor = '#364252';
      o.color = '#fff';
      o.isAllday = true;
      o.start = new Date(o?.planned_delivery_date);
      o.end = new Date(o?.planned_delivery_date);

      if (isDelivered(o)) {
        o.bgColor = '#10AC84'; // Green
      } else if (!isDelivered(o) && isPlannedDeliveryDatePassed(o)) {
        o.bgColor = '#E54949'; // Red
      } else {
        o.bgColor = '#AAB0BF'; // Grey
      }

      return o;
    });
  };

  const fetchProcurementItems = () => {
    const startDate = moment(calendarRef?.current?.calendarInst?._renderRange?.start?._date).format(defaultDateFormat);
    const endDate = moment(calendarRef?.current?.calendarInst?._renderRange?.end?._date).format(defaultDateFormat);

    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let apiFilters = `company=${companyId}&limit=9999`;

    if (startDate) apiFilters += `&delivery_date__gte=${startDate}`;
    if (endDate) apiFilters += `&delivery_date__lte=${endDate}`;
    if (filters?.materialType?.id) apiFilters += `&material_type=${filters?.materialType?.id}`;
    if (filters?.status && filters?.status !== 'all') apiFilters += `&delivery_date__isnull=${filters?.status !== 'delivered'}`;

    getProcurementItems(apiFilters)
      .then((res) => {
        const fetchedData = get(res, 'data.results', []);
        setTableData({
          data: transformData(fetchedData),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchMaterialTypes = () => {
    const apiFilters = `company=${companyId}&limit=9999`;

    getMaterialTypes(apiFilters)
      .then((res) => {
        const fetchedData = get(res, 'data.results', []);
        setMaterialTypes({
          data: fetchedData,
          isLoading: false,
        });
      })
      .catch(() => {
        setMaterialTypes((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchMaterialTypes();
  }, []);

  useEffect(() => {
    fetchProcurementItems();
  }, [filters, view]);

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleClickTodayButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.today();
    fetchProcurementItems();
  };

  const handleClickNextButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.next();
    fetchProcurementItems();
  };

  const handleClickPreviousButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.prev();
    fetchProcurementItems();
  };

  return (
        <div className="procurement-items">
            <div className="procurement-items__view-button">
                <label className="switch">
                    <Button onClick={() => setView('week')} type={view === 'week' ? 'dark-blue' : 'plain'}>{t('page_content.procurement.details.items_table.week')}</Button>
                    <Button onClick={() => setView('month')} type={view === 'month' ? 'dark-blue' : 'plain'}>{t('page_content.procurement.details.items_table.month')}</Button>
                </label>
            </div>

            <div className="procurement-items__filters">
            <Select
              className="select-style"
              options={materialTypes?.data || []}
              isLoading={materialTypes?.isLoading}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.procurement.details.items_table.table_column_material_type')}
              isClearable
              onChange={(e) => handleFilterChange('materialType', e || null)}
              value={filters?.materialType || null}
              styles={selectModalStyles}
            />

            <Select
              className="select-style"
              options={statusDropdownOptions || []}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(e) => handleFilterChange('status', e?.value || null)}
              value={statusDropdownOptions.find((o) => o.value === filters?.status) || null}
              styles={selectModalStyles}
            />
            </div>

            <div className="procurement-items__legend-and-scroll">
            <DeliveryLegend />
            <div className="procurement-items__legend-and-scroll__scroll-buttons">
            <p>{moment(calendarRef?.current?.calendarInst?._renderRange?.start?._date).format(defaultDateFormat)} - {moment(calendarRef?.current?.calendarInst?._renderRange?.end?._date).format(defaultDateFormat)}</p>
            <div className="buttons-wrapper">
            <Button onClick={handleClickPreviousButton}>
                <img src={leftChevron} alt="<" />
              </Button>
              <Button onClick={handleClickTodayButton}>
              {t('page_content.procurement.details.today')}
              </Button>
              <Button onClick={handleClickNextButton}>
                <img src={rightChevron} alt=">" />
              </Button>
            </div>
            </div>
            </div>
            <Calendar
              ref={calendarRef}
              height="700px"
              isReadOnly
              calendars={[
                {
                  id: '1',
                  name: 'Company',
                  bgColor: '#f3f3f3',
                  borderColor: '#cccccc',
                },
              ]}
              disableDblClick
              disableClick={false}
              week={{ daynames: t('date_picker_locale.days', { returnObjects: true }), startDayOfWeek: 1 }}
              month={{ daynames: t('date_picker_locale.days', { returnObjects: true }), startDayOfWeek: 1 }}
              schedules={tableData?.data}
              scheduleView={['allday']}
              taskView={false}
              useCreationPopup={false}
              useDetailPopup
              view={view}
            />
        </div>
  );
};

ProcurementItemsCalendar.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  statusDropdownOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withRouter(ProcurementItemsCalendar);
