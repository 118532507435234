import api from 'helpers/api';
import { Notification } from 'shared';

export const getAbsence = async (companyId, workerId, filters = '') => {
  const urlString = `/api/v1/workforce/worker_absences/?company=${companyId}&worker=${workerId}${filters}`;
  try {
    const res = await api.get(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching worker absence', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedAbsence = async (url) => {
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching worker absence', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addAbsence = async (companyId, data) => {
  const urlString = `/api/v1/workforce/worker_absences/?company=${companyId}`;
  try {
    const res = await api.post(urlString, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while creating new absence', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editAbsence = async (companyId, id, data) => {
  const urlString = `/api/v1/workforce/worker_absences/${id}/?company=${companyId}`;
  try {
    const res = await api.put(urlString, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while updating absence', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteAbsence = async (companyId, id) => {
  const urlString = `/api/v1/workforce/worker_absences/${id}/?company=${companyId}`;
  try {
    const res = await api.delete(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting absence', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWorkerVacations = async (companyId, workerId, filters = '') => {
  const urlString = `/api/v1/workforce/worker_vacations/?company=${companyId}&worker=${workerId}${filters}`;
  try {
    const res = await api.get(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching worker vacations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedWorkerVacations = async (url) => {
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching worker vacations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addWorkerVacations = async (companyId, data) => {
  const urlString = `/api/v1/workforce/worker_vacations/?company=${companyId}`;
  try {
    const res = await api.post(urlString, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while creating new worker vacation', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editWorkerVacations = async (companyId, id, data) => {
  const urlString = `/api/v1/workforce/worker_vacations/${id}/?company=${companyId}`;
  try {
    const res = await api.put(urlString, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while updating worker vacation', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteWorkerVacations = async (companyId, id) => {
  const urlString = `/api/v1/workforce/worker_vacations/${id}/?company=${companyId}`;
  try {
    const res = await api.delete(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting worker vacation', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDayTypes = async () => {
  const url = '/api/v1/workforce/absence-types/';
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching day types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
