/* eslint-disable react/prop-types */
import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props} viewBox="0 0 15 15" fill={props.color || 'none'}>
    <path
      d="M3.5 12.5H1.5C0.947715 12.5 0.5 12.0523 0.5 11.5V7.5C0.5 6.94772 0.947715 6.5 1.5 6.5H13.5C14.0523 6.5 14.5 6.94772 14.5 7.5V11.5C14.5 12.0523 14.0523 12.5 13.5 12.5H11.5M3.5 6.5V1.5C3.5 0.947715 3.94772 0.5 4.5 0.5H10.5C11.0523 0.5 11.5 0.947715 11.5 1.5V6.5M3.5 10.5H11.5V14.5H3.5V10.5Z"
      stroke={props.stroke || '#000000'}
    />
</Base>);
