import React, { Component } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import api from 'helpers/api';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Table, Button, TableButtons, Notification } from 'shared';
import { IconSearch } from 'shared/Icons';
import { selectStyles } from 'styles/modules/reactSelect';
import { ordersCreatedAndUpdated } from 'shared/constants';
import { getOeeResults, getPaginatedOeeResults, getOeeAssets, editCycleTime } from '../actions';
import CycleTimesModal from './CycleTimesModal';
import '../styles.scss';

class CycleTimes extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      isLoadingOee: false,
      isCycleTimeModalOpen: false,
      selectedCycleTime: null,
      next: null,
      previous: null,
      count: null,
      oeeResults: [],
      oeeAssets: [],
      assetFilterValue: '',
      isLoadingExport: false,
      query: '',
      selectedAscDesc: 'desc',
      selectedSort: 'created_at',
    };
  }

  componentDidMount() {
    this.fetchOeeResults();
    this.fetchOeeAssets();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchOeeResults();
    });
  }

  fetchOeeResults = () => {
    this.setState({
      isLoadingOee: true,
    });
    const { locationId } = this.props;
    const { assetFilterValue, productTypeFilterValue, selectedAscDesc, selectedSort, query } = this.state;
    let filters = '';

    if (query) {
      filters += `&product_type_code=${query}`;
    }
    if (assetFilterValue) {
      filters += `&asset=${assetFilterValue}`;
    }
    if (productTypeFilterValue) {
      filters += `&product_type=${productTypeFilterValue}`;
    }
    const asc = selectedAscDesc === 'desc' ? '-' : '';
    let sortKey = selectedSort;
    if (sortKey === 'product_type.code' ||
    sortKey === 'product_type.name' ||
    sortKey === 'asset.name') {
      sortKey = sortKey.replace('.', '__');
    }
    filters += `&order_by=${asc}${sortKey}`;

    getOeeResults(locationId, filters)
      .then((re) => {
        this.setState({
          oeeResults: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingOee: false,
        });
      });
  }

  fetchPaginatedOeeResults = (url) => {
    this.setState({
      isLoadingOee: true,
    });
    getPaginatedOeeResults(url)
      .then((re) => {
        this.setState({
          oeeResults: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingOee: false,
        });
      });
  }

  openCycleTimeModal = (rowInfo) => {
    this.setState({
      isCycleTimeModalOpen: true,
      selectedCycleTime: rowInfo && rowInfo.original ? rowInfo.original : null,
    });
  }

  handleSaveOrEditCycleTime = (id, data) => {
    const { locationId } = this.props;

    this.setState({
      isLoadingOee: true,
    });

    editCycleTime(id, locationId, data)
      .then(() => {
        this.handleCloseModal();
        this.fetchOeeResults();
      });
  }

  handleCloseModal = () => {
    this.setState({
      isCycleTimeModalOpen: false,
      selectedCycleTime: null,
      isLoadingOee: false,
    });
  }

  fetchOeeAssets = () => {
    this.setState({
      isLoadingOee: true,
    });
    const { locationId, companyId } = this.props;
    getOeeAssets(locationId, companyId)
      .then((re) => {
        this.setState({
          oeeAssets: get(re, 'data.results') || [],
          isLoadingOee: false,
        });
      });
  }

  handleQueryOrFilterChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleStateQueryChange = () => {
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      this.fetchOeeResults();
    }, 600);
  }

  exportToExcel = () => {
    const { locationId } = this.props;
    const { count, assetFilterValue, query } = this.state;

    this.setState({ isLoadingExport: true });

    let filters = '';

    if (query) {
      filters += `&product_type_code=${query}`;
    }
    if (assetFilterValue) {
      filters += `&asset=${assetFilterValue}`;
    }

    filters += `&limit=${count}`;

    api.get(`/api/v1/oee/cycle_times/?location=${locationId}${filters}&format=xlsx`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'oee_cycleTimes.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({ isLoadingExport: false });
      })
      .catch(() => {
        this.setState({ isLoadingExport: false });
        return Notification('error', 'An error occurred while exporting.', 'We could not perform your request, please try again.');
      });
  }

  render() {
    const { t, isReadOnly } = this.props;
    const {
      oeeResults,
      previous,
      next,
      isLoadingOee,
      oeeAssets,
      assetFilterValue,
      isCycleTimeModalOpen,
      selectedCycleTime,
      count,
      isLoadingExport,
      query,
    } = this.state;

    const tableColumnConfig = [
      {
        Header: () => <span>{t('page_content.oee.table_column_createdAt')}</span>,
        accessor: 'created_at',
        Cell: (row) => (row.original.created_at ? moment(row.original.created_at).format(ordersCreatedAndUpdated) : '-'),
      },
      {
        Header: () => <span>{t('page_content.oee.table_column_productCode')}</span>,
        accessor: 'product_type.code',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.oee.table_column_productName')}</span>,
        accessor: 'product_type.name',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.oee.table_column_asset')}</span>,
        accessor: 'asset.name',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.oee.target_time')}</span>,
        accessor: 'target_per_time',
        Cell: (row) => (row.value ? parseFloat(row.value).toFixed(1) : '-'),
      },
      {
        Header: () => <span>{t('page_content.oee.table_column_avgPerTime')}</span>,
        accessor: 'avg_per_time',
        Cell: (row) => (row.value ? parseFloat(row.value).toFixed(1) : '-'),
      },
      {
        Header: () => <span>{t('page_content.oee.table_column_medianPerTime')}</span>,
        accessor: 'median_per_time',
        Cell: (row) => (row.value ? parseFloat(row.value).toFixed(1) : '-'),
      },
      {
        Header: () => <span>{t('page_content.oee.table_column_perc99PerTime')}</span>,
        accessor: 'perc99_per_time',
        Cell: (row) => (row.value ? parseFloat(row.value).toFixed(1) : '-'),
      },
    ];

    return (
      <div className="industry-tab fullscreen oee_container">
        <div className="filter_row">
        <div className="oee__bookings__filters_input">
          <input
            onChange={(e) => this.handleQueryOrFilterChange('query', e.target.value)}
            placeholder={t('page_content.oee.search_by_product_type')}
            value={query || ''}
          />
          <div className="icon_container">
            <IconSearch
              color="#555"
              height="26px"
              width="26px"
            />
          </div>
        </div>
          <Select
            options={oeeAssets}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            placeholder={t('page_content.oee.filter.assets')}
            onChange={(value) => this.handleQueryOrFilterChange('assetFilterValue', value.id)}
            value={(oeeAssets.find((asset) => asset.id === assetFilterValue)) || ''}
            styles={selectStyles}
          />
          <Button
            onClick={() => {
              this.setState({
                assetFilterValue: '',
                query: '',
              }, () => {
                this.handleStateQueryChange();
              });
            }}
          >{t('page_content.dms.button_clear_all')}</Button>

          <div className="export_button">
            <Button
              type="export"
              onClick={this.exportToExcel}
              loading={isLoadingExport}
              loadingText=""
            >{t('page_content.oee.export_to_excel_button')}</Button>
          </div>

        </div>
        <div className="table_area">
          <Table
            style={{ userSelect: 'text' }}
            columns={tableColumnConfig}
            data={oeeResults}
            minRows={0}
            defaultPageSize={30}
            noDataText=" "
            showPagination={false}
            loading={isLoadingOee}
            handleClick={(rowInfo) => !isReadOnly && this.openCycleTimeModal(rowInfo)}
            defaultSorted={[{ id: 'created_at', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons previous={previous} next={next} fetchFunction={this.fetchPaginatedOeeResults} count={count} />
        </div>
        {
          isCycleTimeModalOpen &&
          <CycleTimesModal
            selectedCycleTime={selectedCycleTime}
            t={t}
            handleCloseModal={this.handleCloseModal}
            isModalOpen={isCycleTimeModalOpen}
            isLoadingModal={isLoadingOee}
            handleSaveOrEditCycleTime={this.handleSaveOrEditCycleTime}
          />
        }
      </div>
    );
  }
}

CycleTimes.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default (withRouter(CycleTimes));
