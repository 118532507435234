// eslint-disable-next-line import/prefer-default-export
export const getStyleByDayType = (day_type) => {
  const styles = {
    working: {
      background: 'rgba(0, 128, 0, 0.4)',
      border: '1px solid darkgreen',
      overflow: 'hidden',
      color: '#555',
    },
    'sick-leave': {
      background: 'rgba(255, 140, 0, 0.4)',
      border: '1px solid darkorange',
      overflow: 'hidden',
      color: '#555',
    },
    vacation: {
      background: 'rgba(128, 128, 128, 0.4)',
      border: '1px solid grey',
      overflow: 'hidden',
      color: '#555',
    },
    'sick-leave-child': {
      background: 'rgba(255, 165, 0, 0.4)',
      border: '1px solid orange',
      overflow: 'hidden',
      color: '#555',
    },
    'recorded-work': {
      background: 'rgba(0, 128, 0, 0.4)',
      border: '1px solid darkgreen',
      overflow: 'hidden',
      color: '#555',
    },
    'home-office': {
      background: 'rgba(0, 0, 255, 0.4)',
      border: '1px solid blue',
      overflow: 'hidden',
      color: '#555',
    },
    'work-exclusion': {
      background: 'rgba(255, 0, 0, 0.4)',
      border: '1px solid darkred',
      overflow: 'hidden',
      color: '#555',
    },
    'unpaid-leave': {
      background: 'rgba(128, 0, 0, 0.4)',
      border: '1px solid maroon',
      overflow: 'hidden',
      color: '#555',
    },
    'unjustified-absence': {
      background: 'rgba(255, 69, 0, 0.4)',
      border: '1px solid orangered',
      overflow: 'hidden',
      color: '#555',
    },
    'maternity-leave': {
      background: 'rgba(255, 192, 203, 0.4)',
      border: '1px solid pink',
      overflow: 'hidden',
      color: '#555',
    },
    'paternity-leave': {
      background: 'rgba(0, 191, 255, 0.4)',
      border: '1px solid deepskyblue',
      overflow: 'hidden',
      color: '#555',
    },
    'justified-absence': {
      background: 'rgba(0, 128, 128, 0.4)',
      border: '1px solid teal',
      overflow: 'hidden',
      color: '#555',
    },
    'deposited-leave': {
      background: 'rgba(75, 0, 130, 0.4)',
      border: '1px solid indigo',
      overflow: 'hidden',
      color: '#555',
    },
    'day-off': {
      background: 'rgba(255, 215, 0, 0.4)',
      border: '1px solid gold',
      overflow: 'hidden',
      color: '#555',
    },
    'business-trip': {
      background: 'rgba(0, 255, 255, 0.4)',
      border: '1px solid cyan',
      overflow: 'hidden',
      color: '#555',
    },
    'official-exit': {
      background: 'rgba(255, 20, 147, 0.4)',
      border: '1px solid deeppink',
      overflow: 'hidden',
      color: '#555',
    },
    'field-work': {
      background: 'rgba(34, 139, 34, 0.4)',
      border: '1px solid forestgreen',
      overflow: 'hidden',
      color: '#555',
    },
  };

  return styles[day_type] || {};
};
