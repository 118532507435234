import React, { Component } from 'react';
import { get } from 'lodash';
import api from 'helpers/api';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Modal } from 'shared';
import { getOrders, getWarehousePallets } from '../actions';
import WarehouseDropdown from './WarehouseDropdown';

class IssuePalletsModal extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      warehouse_postions: [],
      pallet: {
        order: props?.selectedPallet?.order || props?.order || null,
        partner: !props.isParentPallet && !props.selectedPalletRoot?.issued_to_partner && props?.selectedPallet?.partner ? props.selectedPallet.partner :
          props?.selectedPallet?.order?.partner ? props.selectedPallet.order.partner : null,
        movement_type: '',
        warehouse: props?.selectedPallet?.warehouse?.id || null,
        warehouse_position: props?.selectedPallet?.warehouse_position || '',
        parent_pallet: props?.selectedPalletRoot?.parent_pallet || null,
      },
      error_order: false,
      error_partner: false,
      error_warehouse: false,
      error_warehouse_position: false,
      isLoadingOrders: false,
      inputValue: '',
      orders: [props?.selectedPallet?.order],
      isLoadingPallets: false,
      pallets: [props.selectedPalletRoot?.parent_pallet ? props.selectedPalletRoot.parent_pallet : {}],
    };
  }

  componentDidMount() {
    this.fetchWarehousePositions();
  }

  onQueryPalletChange = (palletsInputValue) => {
    const { companyId, locationId } = this.props;

    if (palletsInputValue?.length && palletsInputValue.length >= 3) {
      this.setState({ palletsInputValue, isLoadingPallets: true });

      let filters = '';

      if (this.timerRef.current) {
        clearTimeout(this.timerRef.current);
        this.timerRef.current = undefined;
      }

      this.timerRef.current = setTimeout(() => {
        this.timerRef.current = undefined;

        if (palletsInputValue) {
          filters += `&external_id_contains=${palletsInputValue}`;
        }

        filters += '&order_by=-updated_at,-created_at';

        getWarehousePallets(companyId, locationId, filters)
          .then((re) => {
            this.setState({
              pallets: get(re, 'data.results') || [],
              isLoadingPallets: false,
            });
          });
      }, 500);
    } else {
      this.setState({ palletsInputValue, orders: [] });
    }
  };

  onSave = () => {
    const { pallet } = this.state;
    const { issuePallets, issueToProduction, palletId } = this.props;

    if (pallet.movement_type === 'issue_delivery') {
      if (!pallet.partner) {
        this.setState({
          error_partner: !pallet.partner,
        });
      } else {
        const data = {
          pallet: palletId || null,
          movement_type: pallet.movement_type,
          partner: pallet && pallet.partner ? typeof pallet.partner === 'number' ? pallet.partner : pallet.partner.id : null,
        };

        issuePallets(data);
      }
    } else if (pallet.movement_type === 'issue_transfer') {
      if (!pallet.warehouse || !pallet.warehouse_position) {
        this.setState({
          error_warehouse: !pallet.warehouse,
          error_warehouse_position: !pallet.warehouse_position,
        });
      } else {
        const data = {
          pallet: palletId || null,
          movement_type: pallet.movement_type,
          order: pallet?.order?.id || null,
          warehouse: pallet.warehouse,
          warehouse_position: pallet.warehouse_position && pallet.warehouse_position.id ? pallet.warehouse_position.id : null,
          parent_pallet: typeof pallet.parent_pallet === 'number' ? pallet.parent_pallet : pallet.parent_pallet?.id,
        };

        issuePallets(data);
      }
    } else if (pallet.movement_type === 'issue_waste') {
      const data = {
        pallet: palletId || null,
        movement_type: pallet.movement_type,
      };

      issuePallets(data);
    } else if (pallet.movement_type === 'cancellation') {
      const data = {
        pallet: palletId || null,
        movement_type: pallet.movement_type,
      };

      issuePallets(data);
    } else if (pallet.movement_type === 'issue_production') {
      if (!pallet.order) {
        this.setState({
          error_order: !pallet.order,
        });
      } else {
        const data = {
          pallet: palletId || null,
          movement_type: pallet.movement_type,
          order: pallet.order.id,
          products_all: true,
          allocate_to_order: true,
        };
        issueToProduction(data);
      }
    }
  }

  onQueryOrderChange = (inputValue) => {
    const { companyId, locationId } = this.props;

    if (inputValue && inputValue.length && inputValue.length >= 3) {
      this.setState({ inputValue, isLoadingOrders: true });

      let filters = '';

      if (this.timerRef.current) {
        clearTimeout(this.timerRef.current);
        this.timerRef.current = undefined;
      }

      this.timerRef.current = setTimeout(() => {
        this.timerRef.current = undefined;
        filters += '&order_by=-updated_at,-created_at';

        if (inputValue) {
          filters += `&external_id=${inputValue}`;
        }

        getOrders(companyId, locationId, filters)
          .then((res) => {
            this.setState({
              orders: get(res, 'data.results') || [],
              isLoadingOrders: false,
            });
          });
      }, 500);
    } else {
      this.setState({ inputValue, orders: [] });
    }
  };

  onInputChange = (key, value) => {
    const { pallet } = this.state;

    const error_key = `error_${key}`;

    if (key === 'warehouse') {
      this.setState({
        pallet: { ...pallet, [key]: value, warehouse_position: '' },
        [error_key]: false,
      }, () => { this.fetchWarehousePositions(); });
    } else if (key === 'movement_type') {
      this.setState({
        warehouse_postions: [],
        pallet: {
          order: this?.props?.selectedPallet?.order || this?.props?.order || null,
          partner: null,
          movement_type: value,
          warehouse: this.props && this.props.selectedPallet && this.props.selectedPallet.warehouse && this.props.selectedPallet.warehouse.id ? this.props.selectedPallet.warehouse.id : null,
          warehouse_position: this.props && this.props.selectedPallet && this.props.selectedPallet.warehouse_position && this.props.selectedPallet.warehouse_position ? this.props.selectedPallet.warehouse_position : '',
          parent_pallet: this.props?.selectedPalletRoot?.parent_pallet || null,
        },
        error_order: false,
        error_partner: false,
        error_warehouse: false,
        error_warehouse_position: false,
      }, () => { this.fetchWarehousePositions(); });
    } else {
      this.setState({
        pallet: { ...pallet, [key]: value },
        [error_key]: false,
      });
    }
  }

  fetchWarehousePositions = () => {
    const { companyId, locationId, t } = this.props;
    const { pallet } = this.state;

    if (pallet && pallet.warehouse) {
      api.get(`/api/v1/wms/warehousepositions/?company=${companyId}&location=${locationId}&warehouse=${pallet.warehouse}&expand=true&only_free=true&limit=999&order_by=external_id`)
        .then((res) => {
          this.setState({
            warehouse_postions: [{ external_id: t('page_content.warehouse.no_position'), id: '' }, ...get(res, 'data.results')] || [],
          });
        });
    }
  }

  handleBlur = () => {
    this.setState({
      orders: [],
      isLoadingOrders: false,
      inputValue: '',
    });
  };

  handleBlurPallets = () => {
    this.setState({
      pallets: [],
      isLoadingPallets: false,
      palletsInputValue: '',
    });
  };

  render() {
    const {
      pallet,
      warehouse_postions,
      error_partner,
      error_warehouse,
      error_warehouse_position,
      isLoadingOrders,
      inputValue,
      orders,
      error_order,
      pallets,
      isLoadingPallets,
      palletsInputValue,
    } = this.state;
    const { t, closeIssuePalletsModal, partners, listOfWarehouses, selectedPalletRoot, isParentPallet } = this.props;

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '300px',
        minHeight: '34px',
        height: '34px',
        padding: 0,
        fontSize: '14px',
        color: '#555',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0 10px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: '0',
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 300,
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    const issue_to_options = [
      !selectedPalletRoot?.issued_to_partner && !isParentPallet ? { value: 'issue_delivery' } : null,
      !selectedPalletRoot?.issued_to_partner ? { value: 'issue_transfer' } : null,
      !selectedPalletRoot?.issued_to_partner && !isParentPallet ? { value: 'issue_waste' } : null,
      { value: 'cancellation' },
      !selectedPalletRoot?.issued_to_partner && !isParentPallet ? { value: 'issue_production' } : null,
    ].filter((option) => option !== null);

    return (
      <Modal
        isOpen
        handleSave={this.onSave}
        disableSave={!pallet?.movement_type}
        handleClose={closeIssuePalletsModal}
        title={t('page_content.warehouse.pallets_table.issue_pallets')}
        error={(error_partner || error_warehouse || error_warehouse_position || (pallet.movement_type === 'issue_production' && error_order)) ? t('page_content.warehouse.error_msg') : ''}
      >
        {
          pallet.movement_type && pallet.movement_type === 'issue_transfer' &&
          <p
            style={{
              padding: '10px',
              fontSize: '13px',
              fontWeight: '600',
              color: 'red',
            }}
          >{t('page_content.warehouse.move_pallet_info')}</p>
        }

        {
          pallet.movement_type && pallet.movement_type === 'issue_production' &&
          <p
            style={{
              padding: '10px',
              fontSize: '13px',
              fontWeight: '600',
              color: 'red',
            }}
          >{t('page_content.warehouse.transfers_pallet_goods')}</p>
        }

        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  <label>{t('page_content.warehouse.movement_type')}</label>
                </td>
                <td className="input">
                  <select value={pallet.movement_type || ''} onChange={(e) => this.onInputChange('movement_type', e.target.value)}>
                    <option key="" value="">------</option>
                    {issue_to_options.map((type) => (
                      <option key={type.value} value={type.value}>
                        {t([`page_content.warehouse.issue_options.${type.value}`])}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>

              {pallet.movement_type === 'issue_transfer' && (
                <tr>
                  <td className="label">
                    <label>{t('page_content.warehouse.pallets_table.parent_pallet')}</label>
                  </td>
                  <td className="input">
                    <Select
                      options={pallets}
                      getOptionLabel={(option) => option.external_id}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      isClearable
                      placeholder=""
                      menuPosition="fixed"
                      onChange={(parent_pallet) => { this.onInputChange('parent_pallet', parent_pallet); }}
                      // eslint-disable-next-line eqeqeq
                      value={typeof pallet.parent_pallet === 'number' ? pallets.find((o) => o.id == pallet.parent_pallet) : pallet.parent_pallet}
                      styles={selectStyles}
                      onInputChange={this.onQueryPalletChange}
                      onBlur={this.handleBlurPallets}
                      isLoading={isLoadingPallets}
                      inputValue={palletsInputValue}
                    />
                  </td>
                </tr>
              )}

              {pallet.movement_type === 'issue_delivery' && (
                <tr>
                  <td className="label">
                    <label>{t('page_content.warehouse.partners')}*</label>
                  </td>
                  <td className="input">
                    <Select
                      options={partners}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      placeholder=""
                      menuPosition="fixed"
                      onChange={(partner) => { this.onInputChange('partner', partner); }}
                      // eslint-disable-next-line eqeqeq
                      value={typeof pallet.partner === 'number' ? partners.find((o) => o.id == pallet.partner) : pallet.partner}
                      styles={selectStyles}
                    />
                  </td>
                </tr>
              )}

              {(pallet.movement_type === 'issue_transfer' || pallet.movement_type === 'issue_production') && (
                <tr>
                  <td className="label">
                    <label>{t('page_content.warehouse.order')}{pallet.movement_type === 'issue_production' ? '*' : ''}</label>
                  </td>
                  <td className="input">
                    <Select
                      options={orders}
                      getOptionLabel={(option) => option.external_id}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      menuPosition="fixed"
                      placeholder={t('page_content.warehouse.search_placeholder_2')}
                      onChange={(order) => { this.onInputChange('order', order); }}
                      value={pallet.order || ''}
                      styles={selectStyles}
                      onInputChange={this.onQueryOrderChange}
                      onBlur={this.handleBlur}
                      isLoading={isLoadingOrders}
                      inputValue={inputValue}
                    />
                  </td>
                </tr>
              )}

              {pallet.movement_type === 'issue_transfer' && (
                <tr>
                  <td className="label">
                    <label>{t('page_content.warehouse.warehouse')}*</label>
                  </td>
                  <td className="input">
                    <WarehouseDropdown
                      changeWarehouse={(e) => { this.onInputChange('warehouse', e.target.value); }}
                      selectedWarehouse={pallet.warehouse}
                      listOfWarehouses={listOfWarehouses}
                    />
                  </td>
                </tr>
              )}

              {pallet.movement_type === 'issue_transfer' && (
                <tr>
                  <td className="label">
                    <label>{t('page_content.warehouse.warehouse_positions')}*</label>
                  </td>
                  <td className="input">
                    <Select
                      isDisabled={!pallet.warehouse}
                      options={warehouse_postions}
                      getOptionLabel={(option) => option.external_id}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      placeholder=""
                      menuPosition="fixed"
                      onChange={(warehouse_position) => { this.onInputChange('warehouse_position', warehouse_position); }}
                      value={pallet.warehouse_position || ''}
                      styles={selectStyles}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

IssuePalletsModal.propTypes = {
  palletId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  t: PropTypes.func.isRequired,
  closeIssuePalletsModal: PropTypes.func.isRequired,
  partners: PropTypes.array.isRequired,
  listOfWarehouses: PropTypes.array.isRequired,
  selectedPallet: PropTypes.object,
  order: PropTypes.object,
  issuePallets: PropTypes.func.isRequired,
  issueToProduction: PropTypes.func.isRequired,
  selectedPalletRoot: PropTypes.object.isRequired,
  isParentPallet: PropTypes.bool.isRequired,
};

export default withTranslation()(IssuePalletsModal);
