import React, { Component } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Tooltip from 'rc-tooltip';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { selectStyles, multiSelectStylesAutoHeight } from 'styles/modules/reactSelect';
import { Button, ConfirmationModal, Notification, Table, TableButtons } from 'shared';
import { IconRemove, IconSearch, IconEdit, IconInfo, IconLocked } from 'shared/Icons';
import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { defaultDateFormat, modalSizes } from 'shared/constants';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import { setDateRange } from 'app/actions';
import 'react-table/react-table.css';
import './style.scss';

import {
  assignRoleToUser,
  createOrUpdateRoleResource,
  // getUsers,
  createOrUpdateUser,
  createRole,
  deleteRoleResource,
  deleteUser,
  deleteUserRole,
  editRole,
  getResources,
  getRoleResources,
  getRoles,
  getUserRoles,
  getUsersUrl,
  getWorkerCategories,
  searchUsers,
  editResource,
} from './actions';

import RoleModal from './components/RoleModal';
import UserDetails from './components/UserDetails';

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      users: [],
      isLoadingUsers: false,
      query: '',
      searchBy: 'name',
      previous: null,
      next: null,
      count: 0,
      previousRoles: null,
      nextRoles: null,
      countRoles: 0,
      showRoleModal: false,
      roleData: {
        name: '',
        code: '',
        location: null,
        is_protected: false,
      },
      editRoleData: {
        name: '',
        code: '',
        location: null,
        is_protected: false,
      },
      showUserModal: false,
      newUserData: {
        email: '',
        isActive: true,
        isAdmin: false,
        password: '',
        invitation: false,
        check_in_id: '',
        external_id: '',
        worker_category: '',
        department: '',
        position: '',
      },
      editUserData: {
        email: '',
        isActive: null,
        isAdmin: null,
        id: null,
        password: '',
        check_in_id: '',
        external_id: '',
        worker_category: '',
        department: '',
        position: '',
      },
      isEdit: false,
      roles: null,
      userRoles: null,
      resources: [],
      showResources: false,
      roleResources: [],
      currentRole: null,
      isReadOnly: false,
      workerCategories: [],
      rolesFilter: '',
      queryDepartment: '',
      queryPosition: '',
      isLoadingRoles: true,
      currentUrl: '',
      selectedAscDesc: 'asc',
      selectedSort: 'last_name',
      selectedAscDescRoles: 'asc',
      selectedSortRoles: 'name',
      showConfirmationDialog: false,
      deleteData: null,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        } else {
          this.fetchRoles();
          getResources(companyId)
            .then((re) => this.setState({
              resources: get(re, 'data'),
            }))
            .catch((error) => console.log(error));
          this.fetchData();
          this.fetchWorkerCategories(companyId);
        }
      });
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchData();
    });
  }

  handleSortingRoles = (sortData) => {
    let column = sortData.id;
    if (column === 'location.name') {
      column = 'location';
    }
    this.setState({
      selectedSortRoles: column,
      selectedAscDescRoles: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchRoles();
    });
  }

  fetchRoles = () => {
    const { companyId, locationId } = this.props;
    const { selectedAscDescRoles, selectedSortRoles } = this.state;

    let url = `/api/v1/roles/?company=${companyId}&location=${locationId}`;

    const asc = selectedAscDescRoles === 'desc' ? '-' : '';
    url += `&order_by=${asc}${selectedSortRoles}`;
    getRoles(url)
      .then((re) => this.setState({
        roles: get(re, 'data.results'),
        previousRoles: get(re, 'data.previous'),
        nextRoles: get(re, 'data.next'),
        countRoles: get(re, 'data.count'),
        isLoadingRoles: false,
        currentUrl: url,
      }))
      .catch((error) => console.log(error));
  }

  fetchWorkerCategories = (companyId) => {
    getWorkerCategories(companyId)
      .then((re) => this.setState({
        workerCategories: get(re, 'data.results'),
      }))
      .catch((error) => console.log(error));
  };

  fetchRoleResources = (companyId, roleId) => {
    getRoleResources(companyId, roleId)
      .then((re) => this.setState({
        roleResources: get(re, 'data.results'),
      }))
      .catch((error) => console.log(error));
  }

  fetchData = () => {
    const { query, searchBy, rolesFilter, selectedAscDesc, selectedSort } = this.state;
    const {
      locationId,
      match: {
        params: {
          companyId,
        },
      },
    } = this.props;

    this.setState({
      isLoadingUsers: true,
    });

    let filter = '';

    if (selectedSort) {
      const asc = selectedAscDesc === 'desc' ? '-' : '';
      let sortKey = selectedSort;
      if (selectedSort === 'first_name' ||
      selectedSort === 'last_name' ||
      selectedSort === 'email' ||
      selectedSort === 'last_login' ||
      selectedSort === 'check_in_id') {
        sortKey = `user__${selectedSort}`;
      }
      if (selectedSort === 'worker_category') {
        sortKey = 'user__workers__worker_category__name';
      }
      if (selectedSort === 'department' || selectedSort === 'position') {
        sortKey = `user__workers__${selectedSort}`;
      }
      filter += `&order_by=${asc}${sortKey || ''}`;
    }

    if (searchBy === 'email') {
      filter += `&email=${query}`;
    }
    if (searchBy === 'name') {
      let name = '';
      let lastName = '';
      if (query.includes(' ')) {
        name = query.split(' ')[0];
        lastName = query.split(' ')[1];
        filter += `&first_name=${name}&last_name=${lastName}`;
      } else {
        filter += `&first_or_last_name=${query}`;
      }
    }
    if (searchBy === 'checkid') {
      filter += `&check_in_id=${query}`;
    }
    if (searchBy === 'external_id') {
      filter += `&external_id=${query}`;
    }
    if (rolesFilter?.length && rolesFilter.some((item) => item.id !== 'no_roles')) {
      filter += `&role=${rolesFilter?.map((r) => r.id)?.join(',')}`;
    }
    if (rolesFilter?.length && rolesFilter.some((item) => item.id === 'no_roles')) {
      filter += `&no_role=${true}`;
    }

    searchUsers(companyId, locationId, filter)
      .then((res) => {
        this.setState({
          users: get(res, 'data.results'),
          count: get(res, 'data.count'),
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoadingUsers: false,
        });
        getUserRoles(companyId)
          .then((re) => this.setState({
            userRoles: get(re, 'data.results'),
          }))
          .catch((error) => console.log(error));
      })
      .catch((e) => console.error('Error while fetching users', e));
  }

  handleChange = (e) => {
    this.setState({
      query: e,
    }, () => this.handleStateQueryChange());
  }

  handleChangeDepartment = (e) => {
    this.setState({
      queryDepartment: e,
    }, () => this.handleStateQueryChange());
  }

  handleChangePosition = (e) => {
    this.setState({
      queryPosition: e,
    }, () => this.handleStateQueryChange());
  }

  handleStateQueryChange = () => {
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      this.handleSearch();
    }, 600);
  }

  handleSearch = () => {
    const { query, searchBy, rolesFilter, queryDepartment, queryPosition } = this.state;
    const { companyId, locationId } = this.props;
    this.setState({
      isLoadingUsers: true,
    });
    let filter = '';
    if (searchBy === 'email') {
      filter += `&email=${query}`;
    }
    if (searchBy === 'name') {
      let name = '';
      let lastName = '';
      if (query.includes(' ')) {
        name = query.split(' ')[0];
        lastName = query.split(' ')[1];
        filter += `&first_name=${name}&last_name=${lastName}`;
      } else {
        filter += `&first_or_last_name=${query}`;
      }
    }
    if (searchBy === 'checkid') {
      filter += `&check_in_id=${query}`;
    }
    if (searchBy === 'external_id') {
      filter += `&external_id=${query}`;
    }
    if (rolesFilter?.length && rolesFilter.some((item) => item.id !== 'no_roles')) {
      filter += `&role=${rolesFilter?.map((r) => r.id)?.join(',')}`;
    }
    if (rolesFilter?.length && rolesFilter.some((item) => item.id === 'no_roles')) {
      filter += `&no_role=${true}`;
    }
    if (queryDepartment) {
      filter += `&department=${queryDepartment}`;
    }
    if (queryPosition) {
      filter += `&position=${queryPosition}`;
    }
    searchUsers(companyId, locationId, filter)
      .then((res) => {
        this.setState({
          users: get(res, 'data.results') || [],
          count: get(res, 'data.count'),
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoadingUsers: false,
        });
      })
      .catch((error) => console.log(error));
  }

  handleClearSearch = () => {
    this.setState({
      query: '',
    }, () => {
      this.fetchData();
    });
  }

  handleSaveRole = () => {
    const { companyId } = this.props;
    const { roleData, currentUrl } = this.state;
    const { name, code, location, is_protected } = roleData;

    this.setState({ isLoadingRoles: true });

    if (name && code) {
      createRole(companyId, { name, code, is_protected, company: companyId, location: location?.id ?? null })
        .then(() => {
          getRoles(currentUrl)
            .then((re) => this.setState({
              roles: get(re, 'data.results'),
              nextRoles: get(re, 'data.next'),
              previousRoles: get(re, 'data.previous'),
              countRoles: get(re, 'data.count'),
              roleData: { name: '', code: '', location: null, is_protected: false },
              showRoleModal: false,
              isLoadingRoles: false,
            }))
            .catch((error) => console.log(error));
        });
    } else {
      alert('Please enter role name');
    }
  }

  handleEditRole = () => {
    const { companyId } = this.props;
    const { editRoleData, currentUrl } = this.state;
    const { name, code, id, location, is_protected } = editRoleData;

    if (name && code) {
      editRole(companyId, { name, code, id, is_protected, company: parseInt(companyId, 10), location: location?.id ?? null })
        .then(() => {
          getRoles(currentUrl)
            .then((re) => this.setState({
              roles: get(re, 'data.results'),
              editRoleData: { name: '', code: '', location: null, is_protected: false },
              nextRoles: get(re, 'data.next'),
              previousRoles: get(re, 'data.previous'),
              countRoles: get(re, 'data.count'),
              showRoleModal: false,
            }))
            .catch((error) => console.log(error));
        });
    } else {
      alert('Please enter role name');
    }
  }

  handleCreateOrEditUser = () => {
    const {
      newUserData,
      isEdit,
      editUserData,
    } = this.state;
    const { companyId } = this.props;

    if (!isEdit && (!newUserData || !newUserData.email)) return;

    this.setState({
      isLoadingUsers: true,
    });

    // TODO check password strength when we agree on the policy

    let payload = {
      first_name: newUserData.first_name,
      last_name: newUserData.last_name,
      email: newUserData.email,
      is_active: newUserData.isActive,
      company: companyId,
      is_admin: newUserData.isAdmin,
      password: newUserData.password,
      invitation: newUserData.invitation,
      check_in_id: newUserData.check_in_id && newUserData.check_in_id.length ? newUserData.check_in_id.toLowerCase() : null,
      external_id: newUserData.external_id,
      worker_category: newUserData.worker_category,
      department: newUserData.department,
      position: newUserData.position,
    };

    if (!payload.invitation) {
      delete payload.invitation;
    }

    if (isEdit) {
      payload = {
        first_name: editUserData.first_name,
        last_name: editUserData.last_name,
        email: editUserData.email,
        is_active: editUserData.isActive,
        is_admin: editUserData.isAdmin,
        company: companyId,
        id: editUserData.id,
        check_in_id: editUserData.check_in_id && editUserData.check_in_id.length ? editUserData.check_in_id.toLowerCase() : null,
        external_id: editUserData.external_id,
        worker_category: editUserData.worker_category,
        department: editUserData.department,
        position: editUserData.position,
      };

      if (editUserData.password) {
        payload.password = editUserData.password;
      }
    }

    createOrUpdateUser(companyId, payload, isEdit, editUserData.id)
      .then(() => {
        const { users } = this.state;
        if (isEdit && editUserData.roles) {
          const existingRoles = users.filter((user) => user.id === editUserData.id);
          const newRoles = [];
          const deletedRoles = [];
          const roles = editUserData.roles;
          roles.forEach((r) => {
            const hasRole = existingRoles[0].roles.find((eR) => eR.id === r.id);
            if (!hasRole) {
              newRoles.push(r);
            }
          });

          existingRoles[0].roles.forEach((role) => {
            const hasRole = roles.find((r) => r.id === role.id);
            if (!hasRole) {
              deletedRoles.push(role);
            }
          });

          if (!newRoles.length && !deletedRoles.length) {
            this.setState({
              newUserData: {
                first_name: '',
                last_name: '',
                email: '',
                isActive: true,
                isAdmin: false,
                password: '',
                invitation: false,
                check_in_id: '',
                external_id: '',
                worker_category: '',
                department: '',
                position: '',
              },
              editUserData: {
                first_name: '',
                last_name: '',
                email: '',
                isActive: null,
                isAdmin: null,
                password: '',
                check_in_id: '',
                external_id: '',
                worker_category: '',
                department: '',
                position: '',
              },
              showUserModal: false,
              isEdit: false,
            });
            this.fetchData();

            return;
          }

          newRoles.forEach((role, i) => {
            if (i + 1 === newRoles.length) {
              assignRoleToUser(companyId, { role: role.id, company_member: editUserData.id })
                .then(() => {
                  if (!deletedRoles.length) {
                    this.setState({
                      newUserData: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        isActive: true,
                        isAdmin: false,
                        password: '',
                        invitation: false,
                        check_in_id: '',
                        external_id: '',
                        worker_category: '',
                        department: '',
                        position: '',
                      },
                      editUserData: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        isActive: null,
                        isAdmin: null,
                        password: '',
                        check_in_id: '',
                        external_id: '',
                        worker_category: '',
                        department: '',
                        position: '',
                      },
                      showUserModal: false,
                      isEdit: false,
                    });
                    this.fetchData();
                  }
                })
                .catch((error) => console.log(error));
            } else {
              assignRoleToUser(companyId, { role: role.id, company_member: editUserData.id });
            }
          });

          deletedRoles.forEach((role, i) => {
            if (i + 1 === deletedRoles.length) {
              deleteUserRole(companyId, role.id)
                .then(() => {
                  this.setState({
                    newUserData: {
                      first_name: '',
                      last_name: '',
                      email: '',
                      isActive: true,
                      isAdmin: false,
                      password: '',
                      invitation: false,
                      check_in_id: '',
                      external_id: '',
                      worker_category: '',
                      department: '',
                      position: '',
                    },
                    editUserData: {
                      first_name: '',
                      last_name: '',
                      email: '',
                      isActive: null,
                      isAdmin: null,
                      password: '',
                      check_in_id: '',
                      external_id: '',
                      worker_category: '',
                      department: '',
                      position: '',
                    },
                    showUserModal: false,
                    isEdit: false,
                  });
                  this.fetchData();
                })
                .catch((error) => console.log(error));
            } else {
              deleteUserRole(companyId, role.id);
            }
          });
        } else {
          this.setState({
            newUserData: {
              first_name: '',
              last_name: '',
              email: '',
              isActive: true,
              isAdmin: false,
              password: '',
              check_in_id: '',
              external_id: '',
              worker_category: '',
              department: '',
              position: '',
            },
            editUserData: {
              first_name: '',
              last_name: '',
              email: '',
              isActive: null,
              isAdmin: null,
              password: '',
              check_in_id: '',
              external_id: '',
              worker_category: '',
              department: '',
              position: '',
            },
            showUserModal: false,
            isEdit: false,
          });
          this.fetchData();
        }
      })
      .catch((error) => {
        this.setState({
          isLoadingUsers: false,
        });
        Notification('error', 'An error occurred', error.response.data.message || 'Check if first name, last name and email are valid and filled.');
      });
  }

  toggleShowUserModal = (open) => {
    this.setState({
      showUserModal: open,
      isEdit: false,
      newUserData: {
        first_name: '',
        last_name: '',
        email: '',
        isActive: true,
        isAdmin: false,
        password: '',
        check_in_id: '',
        external_id: '',
        worker_category: '',
        department: '',
        position: '',
      },
      editUserData: {
        first_name: '',
        last_name: '',
        email: '',
        isActive: null,
        isAdmin: null,
        id: null,
        password: '',
        check_in_id: '',
        external_id: '',
        worker_category: '',
        department: '',
        position: '',
      },
    });
  }

  toggleShowRoleModal = (open) => {
    this.setState({
      showRoleModal: open,
      roleData: {
        name: '',
        code: '',
        location: null,
        is_protected: false,
      },
      editRoleData: {
        name: '',
        code: '',
        id: null,
        location: null,
        is_protected: false,
      },
      isEditRole: false,
      showResources: !open,
    });
  }

  handleShowDetails = (row) => {
    this.setState({
      editUserData: {
        first_name: row.original.first_name,
        last_name: row.original.last_name,
        email: row.original.email,
        isAdmin: row.original.is_admin,
        isActive: row.original.is_active,
        id: row.original.id,
        password: '',
        check_in_id: row.original.check_in_id,
        roles: row.original.roles,
        external_id: row.original.external_id,
        worker_category: row.original.worker_category,
        department: row.original.department,
        position: row.original.position,
      },
      showUserModal: true,
      isEdit: true,
    });
  }

  handleShowEditRole = (row) => {
    const { name, code, id, location, is_protected } = row.original;
    this.setState({
      editRoleData: {
        name,
        code,
        id,
        location,
        is_protected,
      },
      isEditRole: true,
      showRoleModal: true,
      showResources: false,
    });
  }

  handleEditNewUserData = (value, field) => {
    const { newUserData } = this.state;
    switch (field) {
      case 'first_name':
        this.setState({
          newUserData: {
            ...newUserData,
            first_name: value,
          },
        });
        break;
      case 'last_name':
        this.setState({
          newUserData: {
            ...newUserData,
            last_name: value,
          },
        });
        break;
      case 'email':
        this.setState({
          newUserData: {
            ...newUserData,
            email: value,
          },
        });
        break;
      case 'isActive':
        this.setState({
          newUserData: {
            ...newUserData,
            isActive: value,
          },
        });
        break;
      case 'isAdmin':
        this.setState({
          newUserData: {
            ...newUserData,
            isAdmin: value,
          },
        });
        break;
      case 'password':
        this.setState({
          newUserData: {
            ...newUserData,
            password: value,
          },
        });
        break;
      case 'check_in_id':
        if (value.length >= 21) return;
        this.setState({
          newUserData: {
            ...newUserData,
            check_in_id: value.toLowerCase(),
          },
        });
        break;
      case 'roles':
        this.setState({
          newUserData: {
            ...newUserData,
            roles: value.map((r) => r.value),
          },
        });
        break;
      case 'invitation':
        this.setState({
          newUserData: {
            ...newUserData,
            invitation: value,
          },
        });
        break;
      case 'external_id':
        this.setState({
          newUserData: {
            ...newUserData,
            external_id: value,
          },
        });
        break;
      case 'worker_category':
        this.setState({
          newUserData: {
            ...newUserData,
            worker_category: value.id,
          },
        });
        break;
      case 'department':
        this.setState({
          newUserData: {
            ...newUserData,
            department: value,
          },
        });
        break;
      case 'position':
        this.setState({
          newUserData: {
            ...newUserData,
            position: value,
          },
        });
        break;
      default:
    }
  }

  handleEditUserData = (value, field) => {
    const { editUserData } = this.state;

    switch (field) {
      case 'first_name':
        this.setState({
          editUserData: {
            ...editUserData,
            first_name: value,
          },
        });
        break;
      case 'last_name':
        this.setState({
          editUserData: {
            ...editUserData,
            last_name: value,
          },
        });
        break;
      case 'email':
        this.setState({
          editUserData: {
            ...editUserData,
            email: value,
          },
        });
        break;
      case 'isActive':
        this.setState({
          editUserData: {
            ...editUserData,
            isActive: value,
          },
        });
        break;
      case 'isAdmin':
        this.setState({
          editUserData: {
            ...editUserData,
            isAdmin: value,
          },
        });
        break;
      case 'password':
        this.setState({
          editUserData: {
            ...editUserData,
            password: value,
          },
        });
        break;
      case 'check_in_id':
        if (value.length >= 21) return;
        this.setState({
          editUserData: {
            ...editUserData,
            check_in_id: value.toLowerCase(),
          },
        });
        break;
      case 'roles':
        this.setState({
          editUserData: {
            ...editUserData,
            roles: value ? value.map((r) => r.value) : [],
          },
        });
        break;
      case 'external_id':
        this.setState({
          editUserData: {
            ...editUserData,
            external_id: value,
          },
        });
        break;
      case 'worker_category':
        this.setState({
          editUserData: {
            ...editUserData,
            worker_category: value.id,
          },
        });
        break;
      case 'department':
        this.setState({
          editUserData: {
            ...editUserData,
            department: value,
          },
        });
        break;
      case 'position':
        this.setState({
          editUserData: {
            ...editUserData,
            position: value,
          },
        });
        break;
      default:
    }
  }

  handleChangeRoleData = (value, field) => {
    switch (field) {
      case 'name':
        this.setState((prevState) => ({
          roleData: {
            ...prevState.roleData,
            name: value,
            code: value.toLowerCase().replace(/ /g, '-'),
          },
        }));
        break;
      case 'location':
        this.setState((prevState) => ({
          roleData: {
            ...prevState.roleData,
            location: value ?? null,
          },
        }));
        break;
      case 'is_protected':
        this.setState((prevState) => ({
          roleData: {
            ...prevState.roleData,
            is_protected: value ?? false,
          },
        }));
        break;
      default:
    }
  }

  handleChangeEditRoleData = (value, field) => {
    const { editRoleData } = this.state;
    const id = editRoleData.id;
    const code = editRoleData.code;
    switch (field) {
      case 'name':
        this.setState((prevState) => ({
          editRoleData: {
            ...prevState.editRoleData,
            name: value,
            code,
            id,
          },
        }));
        break;
      case 'location':
        this.setState((prevState) => ({
          editRoleData: {
            ...prevState.editRoleData,
            location: value ?? null,
          },
        }));
        break;
      case 'is_protected':
        this.setState((prevState) => ({
          editRoleData: {
            ...prevState.editRoleData,
            is_protected: value ?? false,
          },
        }));
        break;
      default:
    }
  }

  openRoleResources = (row) => {
    const { name, code, id, location, is_protected } = row.original;
    this.setState({
      editRoleData: {
        name,
        code,
        id,
        location,
        is_protected,
      },
      isEditRole: true,
      showRoleModal: true,
      showResources: true,
      currentRole: row.original,
    });
  }

  changeRoleResource = (create, accessRight, resourceId, roleId, roleResource) => {
    const { companyId } = this.props;

    return new Promise((resolve) => {
      if (create) {
        const data = {
          role: roleId,
          resource: resourceId,
          access: accessRight,
        };

        if (roleResource) {
          data.id = roleResource.id;
        }

        createOrUpdateRoleResource(companyId, data, roleResource ? roleResource.id : null)
          .then(() => {
            getRoleResources(companyId, roleId)
              .then((re) => {
                this.setState({
                  roleResources: get(re, 'data.results'),
                }, () => {
                  resolve();
                });
              })
              .catch((error) => console.log(error));
          })
          .catch((err) => console.log(err));
      } else {
        deleteRoleResource(companyId, roleResource.id)
          .then(() => {
            getRoleResources(companyId, roleId)
              .then((re) => {
                this.setState({
                  roleResources: get(re, 'data.results'),
                }, () => {
                  resolve();
                });
              })
              .catch((error) => console.log(error));
          })
          .catch((err) => console.log(err));
      }
    });
  }

  handleShowConfirmationModal = (e, row) => {
    e.stopPropagation();
    this.setState({ showConfirmationDialog: true, deleteData: row });
  }

  handleDeleteUser = () => {
    const { companyId } = this.props;
    const row = this.state.deleteData;
    const id = row && row.original && row.original.id ? row.original.id : null;
    deleteUser(companyId, id)
      .then(() => {
        this.fetchData();
      })
      .finally(() => this.setState({ showConfirmationDialog: false }));
  }

  handleSearchBy = (e) => {
    // eslint-disable-next-line no-restricted-globals
    const searchBy = e || name;
    this.setState({
      searchBy,
    });
  }

  handlFilterChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
    });
  }

  prepareSortedFilterRoles = (roles) => {
    const { t } = this.props;
    const roleFilterOptions = [
      ...(roles || []).map((role) => ({ id: role.code, name: role.name })),
      { id: 'no_roles', name: t('page_content.user_management.users.roles_filter_noRole_option') },
    ];

    return roleFilterOptions.sort((a, b) => a.name.localeCompare(b.name));
  }

  handleClearFilters = () => {
    this.setState({
      query: '',
      rolesFilter: '',
      queryDepartment: '',
      queryPosition: '',
    }, () => {
      this.handleStateQueryChange();
    });
  }

  fetchPaginationData = (url) => {
    const {
      match: {
        params: {
          companyId,
        },
      },
    } = this.props;

    this.setState({
      isLoadingUsers: true,
    });

    getUsersUrl(url)
      .then((res) => {
        this.setState({
          users: get(res, 'data.results') || [],
          count: get(res, 'data.count'),
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoadingUsers: false,
        });
        getUserRoles(companyId)
          .then((re) => this.setState({
            userRoles: get(re, 'data.results'),
          }))
          .catch((error) => console.log(error));
      })
      .catch((e) => console.error('Error while fetching users', e));
  };

  fetchPaginationDataRoles = (url) => {
    this.setState({
      isLoadingRoles: true,
    });
    getRoles(url)
      .then((re) => this.setState({
        roles: get(re, 'data.results'),
        previousRoles: get(re, 'data.previous'),
        nextRoles: get(re, 'data.next'),
        countRoles: get(re, 'data.count'),
        isLoadingRoles: false,
        currentUrl: url,
      }))
      .catch((error) => console.log(error));
  };

  handleEditResource = async (resourceId, data) => {
    const { companyId } = this.props;
    const { resources, roleResources } = this.state;

    this.setState({ isLoading: true });

    const res = get(await editResource(resourceId, companyId, data), 'data', null);
    if (!res) {
      this.setState({ isLoading: false });
      return;
    }

    const updatedResources = resources.map((r) => (r.id === res?.id ? res : r));
    const updatedRoleResources = roleResources.map((r) => (r.resource.id === res?.id ? { ...r, resource: res } : r));

    this.setState({ isLoading: false, resources: updatedResources, roleResources: updatedRoleResources });
  }

  render() {
    const {
      users,
      isLoadingUsers,
      next,
      previous,
      count,
      query,
      showUserModal,
      newUserData,
      editUserData,
      isEdit,
      isEditRole,
      roles,
      userRoles,
      showRoleModal,
      roleData,
      editRoleData,
      currentRole,
      resources,
      showResources,
      roleResources,
      isReadOnly,
      searchBy,
      workerCategories,
      rolesFilter,
      queryDepartment,
      isLoadingRoles,
      nextRoles,
      previousRoles,
      countRoles,
      queryPosition,
    } = this.state;

    const { companyId, locationId, t, userIsCompanyAdmin } = this.props;

    // user data for UserDetails
    const data = isEdit ? editUserData : newUserData;

    const searchBySelectorOptions = [
      { value: 'name', name: t('page_content.user_management.users.by_name') },
      { value: 'email', name: t('page_content.user_management.users.by_email') },
      { value: 'checkid', name: t('page_content.user_management.users.checkinId_filter_placeholder') },
      { value: 'external_id', name: t('page_content.user_management.users.table_column_external_id') },
    ];

    return (
      <div className="fullscreen user-management-tab-container tabs">
        <Tabs>
          <TabList>
            <Tab>{t('page_content.user_management.tabs.users')}</Tab>
            <Tab>{t('page_content.user_management.tabs.roles')}</Tab>
          </TabList>
          <TabPanel>
            <div>
              <div className="filter_row">
                <div className="input_container">
                  <input
                    onChange={(e) => this.handleChange(e.target.value)}
                    placeholder={t('page_content.user_management.users.search_for_user_placeholder')}
                    value={query}
                    type="text"
                  />
                  {query && <button
                    onClick={this.handleClearSearch}
                  >&times;</button>}
                  <Select
                    options={searchBySelectorOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    isSearchable={false}
                    onChange={(s) => this.handleSearchBy(s.value)}
                    value={(searchBySelectorOptions.find((opt) => opt.value === searchBy)) || ''}
                    styles={selectStyles}
                  />
                  <div className="icon_container">
                    <IconSearch
                      color="#555"
                      height="26px"
                      width="26px"
                    />
                  </div>
                </div>
                <div>
                  <Select
                    options={this.prepareSortedFilterRoles(roles)}
                    isClearable
                    getOptionLabel={(role) => role.name}
                    getOptionValue={(role) => role.id}
                    isMulti
                    placeholder={t('page_content.user_management.users.roles_filter_placeholder')}
                    onChange={(values) => this.handlFilterChange('rolesFilter', values || '')}
                    value={rolesFilter}
                    styles={multiSelectStylesAutoHeight}
                  />
                </div>
                <div className="input_container">
                  <input
                    onChange={(e) => this.handleChangeDepartment(e.target.value)}
                    placeholder={t('page_content.user_management.users.search_for_department_placeholder')}
                    value={queryDepartment}
                    type="text"
                  />
                </div>
                <div className="input_container">
                  <input
                    onChange={(e) => this.handleChangePosition(e.target.value)}
                    placeholder={t('page_content.user_management.users.positions_filter_placeholder')}
                    value={queryPosition}
                    type="text"
                  />
                </div>
                <div>
                  <Button onClick={() => this.handleClearFilters()}>Clear All</Button>
                </div>
                <div className="create_button_wrapper">
                  <Button
                    onClick={() => this.toggleShowUserModal(true)}
                    disabled={isReadOnly && !userIsCompanyAdmin}
                    type="add"
                  >
                    {t('page_content.user_management.users.create_a_user_button')}
                  </Button>
                </div>
              </div>
              <Table
                style={{ userSelect: 'text' }}
                columns={[
                  {
                    Header: () => <span>{t('page_content.user_management.users.table_column_first_name')}</span>,
                    accessor: 'first_name',
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.table_column_last_name')}</span>,
                    accessor: 'last_name',
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.table_column_external_id')}</span>,
                    accessor: 'external_id',
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.table_column_mail')}</span>,
                    width: 200,
                    accessor: 'email',
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.table_column_admin')}</span>,
                    accessor: 'is_admin',
                    width: 75,
                    Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>,
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.table_column_roles')}</span>,
                    accessor: 'roles',
                    sortable: false,
                    width: 100,
                    Cell: (row) => (row.value.map((r, index) => <p
                      style={{
                        margin: '2px 0px',
                        textAlign: 'center',
                        border: '1px solid #555',
                        borderRadius: '10px',
                      }}
                      key={index}
                    >{r.role.name}</p>) || ''),
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.user_modal.worker_category')}</span>,
                    accessor: 'worker_category',
                    Cell: (row) => (row.value ? (workerCategories.find((wCat) => wCat.id === row.value) || {}).name : '-'),
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.user_modal.department')}</span>,
                    accessor: 'department',
                    Cell: (row) => (row.value ? row.value : '-'),
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.user_modal.position')}</span>,
                    accessor: 'position',
                    Cell: (row) => (row.value ? row.value : '-'),
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.table_column_active')}</span>,
                    accessor: 'is_active',
                    width: 75,
                    Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>,
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.table_column_last_login')}</span>,
                    accessor: 'last_login',
                    width: 100,
                    Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : ''),
                    style: { textAlign: 'center', cursor: 'default' },
                  },
                  {
                    Header: () => <span>{t('page_content.user_management.users.table_column_check_in_id')}</span>,
                    accessor: 'check_in_id',
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => '',
                    Cell: (row) => (
                      <Button
                        disabled={isReadOnly && !userIsCompanyAdmin}
                        onClick={() => this.handleShowDetails(row)}
                      >
                        <IconEdit width="12px" height="12px" />
                      </Button>
                    ),
                    width: 70,
                    sortable: false,
                    style: { cursor: 'default' },
                  },
                  {
                    Header: () => '',
                    accessor: 'id',
                    width: 70,
                    sortable: false,
                    Cell: (row) => <Button type="delete" disabled={isReadOnly && !userIsCompanyAdmin} onClick={(e) => this.handleShowConfirmationModal(e, row)}><IconRemove width="12px" height="12px" /></Button>,
                    style: { cursor: 'default' },
                  },
                ]}
                data={users || []}
                defaultPageSize={100}
                loading={isLoadingUsers}
                minRows={0}
                noDataText=" "
                showPagination={false}
                defaultSorted={[{ id: 'last_name', desc: false }]}
                onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
              />
              <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginationData} count={count} />
            </div>

          </TabPanel>
          <TabPanel>
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => this.toggleShowRoleModal(true)}
                disabled={isReadOnly && !userIsCompanyAdmin}
                style={{ marginBottom: '10px', marginLeft: 'auto' }}
                type="add"
              >
                {t('page_content.user_management.roles.create_a_role_button')}
              </Button>
            </div>
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.user_management.roles.table_column_name')}</span>,
                  accessor: 'name',
                  Cell: (row) => (
                    <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      {row?.value}
                      {row?.original?.is_protected && (
                        <Tooltip
                          id="tooltip-zoom"
                          trigger={['hover']}
                          placement="top"
                          overlay={<p>This resource is protected, meaning it can only be seen by Super admins (Ascalia admins). To unlock the role you can edit the role in platform or in Django admin.</p>}
                          overlayClassName="where-filter-tooltip"
                        >
                          <span aria-describedby="tooltip-zoom">
                            <IconInfo color="#2e86de" height="15px" width="17px" />
                            <IconLocked height="18px" width="18px" />
                          </span>
                        </Tooltip>
                      )}
                    </span>
                  ),
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.user_management.roles.table_column_code')}</span>,
                  accessor: 'code',
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.user_management.roles.table_column_location')}</span>,
                  accessor: 'location.name',
                  style: { cursor: 'default' },
                },
                {
                  Header: () => '',
                  Cell: (row) => (
                    <Button
                      disabled={isReadOnly && !userIsCompanyAdmin}
                      onClick={() => this.handleShowEditRole(row)}
                    >
                      <IconEdit width="12px" height="12px" />
                    </Button>
                  ),
                  width: 70,
                  sortable: false,
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.user_management.roles.table_column_role_resources')}</span>,
                  accessor: 'id',
                  sortable: false,
                  width: 150,
                  Cell: (row) => <Button disabled={isReadOnly && !userIsCompanyAdmin} onClick={(e) => { e.stopPropagation(); this.openRoleResources(row); }}>Resources</Button>,
                  style: { cursor: 'default' },
                },
              ]}
              data={roles || []}
              defaultPageSize={100}
              minRows={0}
              loading={isLoadingRoles}
              noDataText=" "
              showPagination={false}
              defaultSorted={[{ id: 'name', desc: false }]}
              onSortedChange={(newSorted) => { this.handleSortingRoles(newSorted[0]); }}
              getTrProps={(state, rowInfo) => {
                if (rowInfo) {
                  const isProtected = rowInfo.original.is_protected;
                  return {
                    style: { backgroundColor: isProtected ? 'rgb(239, 195, 195)' : null },
                  };
                }
              }}
            />
            <TableButtons next={nextRoles} previous={previousRoles} fetchFunction={this.fetchPaginationDataRoles} count={countRoles} />
          </TabPanel>
        </Tabs>
        <UserDetails
          size={modalSizes.large}
          showUserModal={isReadOnly && !userIsCompanyAdmin ? false : showUserModal}
          toggleShowUserModal={this.toggleShowUserModal}
          data={data}
          handleChangeData={isEdit ? this.handleEditUserData : this.handleEditNewUserData}
          handleSubmit={this.handleCreateOrEditUser}
          title={isEdit ? t('page_content.user_management.users.user_modal.edit_title') : t('page_content.user_management.users.user_modal.add_title')}
          roles={roles || []}
          userRoles={userRoles || []}
          isEdit={isEdit}
          workerCategory={workerCategories}
          companyId={companyId}
        />
        {
          showRoleModal ?
            <RoleModal
              size={modalSizes.large}
              showRoleModal={showRoleModal}
              toggleShowRoleModal={this.toggleShowRoleModal}
              companyId={companyId}
              locationId={locationId}
              data={isEditRole ? editRoleData : roleData}
              handleChangeData={isEditRole ? this.handleChangeEditRoleData : this.handleChangeRoleData}
              handleSubmit={isEditRole ? this.handleEditRole : this.handleSaveRole}
              title={isEditRole ? t('page_content.user_management.roles.role_modal.edit_title') : t('page_content.user_management.roles.role_modal.add_title')}
              role={currentRole}
              resources={resources}
              handleEditResource={this.handleEditResource}
              changeRoleResource={this.changeRoleResource}
              isEditRole={isEditRole}
              showResources={showResources}
              roleResources={roleResources}
              fetchRoleResources={this.fetchRoleResources}
            /> : ''
        }
         <ConfirmationModal
           itemName={`${this.state.deleteData?.original?.first_name} ${this.state.deleteData?.original?.last_name}` || ''}
           showModal={this.state.showConfirmationDialog}
           handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
           handleConfirmModal={this.handleDeleteUser}
           type="warning"
         />
      </div>
    );
  }
}

UserManagement.propTypes = {
  match: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  t: PropTypes.func.isRequired,
  userIsCompanyAdmin: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  dispatchDateRange: setDateRange,
};

const mapStateToProps = (state) => {
  return {
    userIsCompanyAdmin: state.app.userIsCompanyAdmin,
    workerCategory: state.app.locationConfig && state.app.locationConfig.config && state.app.locationConfig.config.worker_category ? state.app.locationConfig.config.worker_category : [],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(UserManagement)));
