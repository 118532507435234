import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

class CycleTimesModal extends Component {
  constructor(props) {
    super();
    let average = '';
    let median = '';
    let percentile = '';
    let target_per_time = '';

    if (props.selectedCycleTime) {
      const { selectedCycleTime } = props;
      average = selectedCycleTime.avg_per_time || '';
      median = selectedCycleTime.median_per_time || '';
      percentile = selectedCycleTime.perc99_per_time || '';
      target_per_time = parseFloat(selectedCycleTime.target_per_time).toFixed(1) || '';
    }
    this.state = {
      average,
      median,
      percentile,
      target_per_time,
    };
  }

  onFieldChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  handleSaveOrEditCycleTime = () => {
    const { average, median, percentile, target_per_time } = this.state;
    const { handleSaveOrEditCycleTime, selectedCycleTime } = this.props;

    const data = {
      avg_per_time: average || 0,
      median_per_time: median || 0,
      perc99_per_time: percentile || 0,
      target_per_time: target_per_time || 0,
    };

    const id = selectedCycleTime && selectedCycleTime.id ? selectedCycleTime.id : null;
    handleSaveOrEditCycleTime(id, data);
  }

  handleBlur = () => {
    const { target_per_time } = this.state;

    if (target_per_time !== '') {
      const formattedValue = parseFloat(target_per_time).toFixed(1);
      this.setState((prevState) => ({
        ...prevState,
        target_per_time: formattedValue,
      }));
    }
  };

  render() {
    const { average, median, percentile, target_per_time } = this.state;
    const {
      selectedCycleTime,
      handleCloseModal,
      isModalOpen,
      isLoadingModal,
      t,
    } = this.props;

    return (
      <React.Fragment>
        <Modal
          size={modalSizes.medium}
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          handleSave={this.handleSaveOrEditCycleTime}
        >
          {
            isLoadingModal ?
              <ContentLoader /> :
              <div className="default-form">
                <table>
                  <tbody>
                  <tr>
                      <td className="label">
                        {t('page_content.oee.target_time')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('target_per_time', e.target.value)}
                          onBlur={this.handleBlur}
                          value={target_per_time || ''}
                          type="number"
                          step="0.1"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_average')}
                      </td>
                      <td className="input">
                        <input
                          disabled={selectedCycleTime?.id}
                          onChange={(e) => this.onFieldChange('average', e.target.value)}
                          value={average ? parseInt(average, 10) : ''}
                          type="number"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_median')}
                      </td>
                      <td className="input">
                        <input
                          disabled={selectedCycleTime?.id}
                          onChange={(e) => this.onFieldChange('median', e.target.value)}
                          value={median ? parseInt(median, 10) : ''}
                          type="number"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_percentile')}
                      </td>
                      <td className="input">
                        <input
                          disabled={selectedCycleTime?.id}
                          onChange={(e) => this.onFieldChange('percentile', e.target.value)}
                          value={percentile ? parseInt(percentile, 10) : ''}
                          type="number"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          }

        </Modal>
      </React.Fragment>
    );
  }
}

CycleTimesModal.propTypes = {
  t: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isLoadingModal: PropTypes.bool.isRequired,
  selectedCycleTime: PropTypes.object.isRequired,
  handleSaveOrEditCycleTime: PropTypes.func.isRequired,
};

export default CycleTimesModal;
