import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { get } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import { selectStyles, multiSelectStyles, selectModalStyles } from 'styles/modules/reactSelect';
import { getLocale } from 'shared/DatePicker/constants';
import { connect } from 'react-redux';
import { ContentLoader, Notification, Button, TableButtons } from 'shared';
import { IconSearch } from 'shared/Icons';
import ReactDatePicker from 'react-datepicker';
import { checkAccessOnPage, redirectToHomePage, checkModules, checkAccess } from 'industry/helpers';
import {
  getProductionRecords,
  getLogs,
  getPaginatedLogs,
  getLines,
  getCompanyData,
  getResources,
  addProductionRecord,
  editProductionRecord,
  deleteProductionRecord,
  addProductionRecordEntry,
  editProductionRecordEntry,
  deleteProductionRecordEntry,
  exportToPdf,
  signEntry,
  getAssets,
  exportToXlsx,
  getRoles,
  addProductionRecordLog,
  editProductionRecordLog,
  getOrders,
  getPaginatedProductionRecords,
  getFolders,
} from './actions';
// import RecordsDropdown from './components/RecordsDropdown';
import ProductionRecordsTable from './components/ProductionRecordsTable';
import ProductionRecordsEditTable from './components/ProductionRecordsEditTable';
import ProductionRecordsAddOrEditModal from './components/ProductionRecordsAddOrEditModal';
import ProductionRecordsHeatMap from './components/ProductionRecordsHeatMap';
import SignModal from './components/SignModal';
import './style.scss';
import AddRecord from './components/AddRecord';
import Folders from './components/Folders';
import RecordsList from './components/RecordsList';

class IndustryProductionRecords extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      listOfRecords: [],
      selectedRecord: null,
      dateFilterStart: moment().startOf('month'),
      dateFilterEnd: moment(),
      isLoadingRecords: true,
      transformedData: [],
      theadData: {},
      tbodyData: [],
      previous: null,
      next: null,
      lines: [],
      selectedLine: null,
      isProductionRecordsModalOpen: false,
      resources: [],
      editRecord: null,
      isLoadingRecordEntries: false,
      isExporting: false,
      exportPopup: false,
      isSignModalOpen: false,
      isDeleteAll: false,
      assets: [],
      orderExtIdFilterQuery: '',
      selectedAssetOptions: [],
      recordsTitleQuery: '',
      addRecordModalShow: false,
      userHasRole: false,
      uniqueSelectedRoles: [],
      signatoryRoles: [],
      orders: [],
      selectedRecordType: 'production',
      filteredRecords: [],
      dropdownRecords: [],
      listOfAllFolders: [],
      selectedFolder: null,
      selectedTab: 0,
      selectedRow: null,
      showOnlyActiveFilter: true,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Production records');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    checkAccess('records-delete-all', companyId)
      .then((re) => {
        const data = re.data;
        const access = data.access;
        if (access === 10) {
          this.setState({
            isDeleteAll: true,
          });
        }
      });

    getResources(companyId)
      .then((res) => {
        this.setState({
          resources: get(res, 'data') || [],
        });
      });

    getRoles(companyId, locationId)
      .then((re) => this.setState({
        roles: get(re, 'data.results'),
      }));

    getCompanyData(companyId)
      .then((re) => {
        const company_short_code = re.data.short_code || null;

        getLines(company_short_code, locationId)
          .then((res) => {
            this.setState({
              lines: get(res, 'data.results') || [],
            });
          });
      });

    getOrders(companyId, locationId)
      .then((res) => {
        this.setState({
          orders: get(res, 'data.results') || [],
        });
      });

    getAssets(companyId, locationId)
      .then((resp) => {
        this.setState({
          assets: get(resp, 'data.results', []),
        });
      });

    getProductionRecords(locationId, '', 9999)
      .then((res) => {
        this.setState({
          dropdownRecords: get(res, 'data.results') || [],
        });
      })
      .then(() => {
        this.fetchProductionRecords();
        this.filterRecordsForDropdown();
        this.fetchAllFolders();
      });
  }

  onSaveProductionRecordsModal = (record, close) => {
    const { editRecord } = this.state;
    const { locationId } = this.props;

    this.setState({
      isLoadingRecords: true,
    });

    let recordId;
    const saveProductionRecord = editRecord?.id ? editProductionRecord : addProductionRecord;

    record.location = locationId;

    saveProductionRecord(locationId, record)
      .then((res) => {
        recordId = editRecord?.id ? editRecord.id : res?.data?.id || null;
      })
      .then(() => {
        record.entries.map((entry) => {
          entry.production_record = recordId;
          return entry;
        });
        this.onSaveOrEditProductionRecordEntry(recordId, record.entries)
          .then(() => {
            this.fetchProductionRecords();
            if (close) {
              this.onCloseProductionRecordsModal();
              this.clearFilters();
            }
            this.setState({
              isLoadingRecords: false,
            });
          });
      });
  }

  onCloseProductionRecordsModal = () => {
    this.setState({
      isProductionRecordsModalOpen: false,
      editRecord: null,
    });
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      this.getFilteredLogs();
    });
  }

  onLineChange = (lineValue) => {
    const line = lineValue;

    this.setState({
      selectedLine: line,
    }, () => {
      this.getFilteredLogs();
    });
  }

  onSaveOrEditProductionRecordEntry = async (recordId, entries) => {
    const promises = entries && entries.length ? entries.map((entry) => {
      if (entry && entry.id) {
        return editProductionRecordEntry(entry.id, recordId, entry);
      }
      return addProductionRecordEntry(recordId, entry);
    }) : [];

    try {
      const responses = await Promise.all(promises);
      let entriesResponse = [];
      responses.forEach((response) => {
        entriesResponse = entriesResponse.concat(response.data.results || {});
      });
    } catch (error) {
      // handle error
      console.log('Error', error);
    }
  }

  getFilteredLogs = () => {
    const {
      selectedRecord,
      dateFilterStart,
      dateFilterEnd,
      dropdownRecords,
      selectedLine,
      orderExtIdFilterQuery,
    } = this.state;

    this.setState({
      isLoadingRecords: true,
    });

    let filters = '';

    if (dateFilterStart) {
      filters += `&time_created__gte=${moment(dateFilterStart).startOf('day').utc().toISOString()}`;
    }

    if (dateFilterEnd) {
      filters += `&time_created__lte=${moment(dateFilterEnd).endOf('day').utc().toISOString()}`;
    }

    if (selectedLine) {
      filters += `&line=${selectedLine}`;
    }

    if (orderExtIdFilterQuery) {
      filters += `&order_external_id=${orderExtIdFilterQuery}`;
    }

    getLogs(selectedRecord, filters)
      .then((re) => {
        this.setState({
          isLoadingRecords: false,
          selectedRecord,
          theadData: dropdownRecords.find((record) => record.id === parseInt(selectedRecord, 10)) || {},
          tbodyData: re.data.results || [],
          nextLogs: get(re, 'data.next'),
          previousLogs: get(re, 'data.previous'),
          countLogs: get(re, 'data.count'),
        });
      });
  }

  filterRecordsForDropdown = () => {
    const { dropdownRecords, isCanAddEntry } = this.state;
    const { currentUser } = this.props;

    if (currentUser.is_admin) {
      this.setState({ filteredRecords: dropdownRecords });
    } else {
      let recordsWithAddEntry = [];
      if (isCanAddEntry) {
        recordsWithAddEntry = dropdownRecords.filter(
          (l) => l.signatory_1_resource.code === 'records-add-entry' ||
          l.signatory_2_resource.code === 'records-add-entry' ||
          l.signatory_3_resource.code === 'records-add-entry' ||
          l.signatory_4_resource.code === 'records-add-entry',
        );
      }
      const recordsWithRoles = dropdownRecords.filter(
        (l) => currentUser.roles.find((r) => { if ((l?.signatory_1_role?.id === r?.role?.id) && l?.signatory_1_resource?.code === 'records-add-entry') { return true; } return false; }) ||
          currentUser.roles.find((r) => { if ((l?.signatory_2_role?.id === r?.role?.id) && l?.signatory_2_resource?.code === 'records-add-entry') { return true; } return false; }) ||
          currentUser.roles.find((r) => { if ((l?.signatory_3_role?.id === r?.role?.id) && l?.signatory_3_resource?.code === 'records-add-entry') { return true; } return false; }) ||
          currentUser.roles.find((r) => { if ((l?.signatory_4_role?.id === r?.role?.id) && l?.signatory_4_resource?.code === 'records-add-entry') { return true; } return false; }),
      );
      const allRecords = [...recordsWithAddEntry, ...recordsWithRoles];
      const filteredUniqueRecords = [...new Set(allRecords)];
      this.setState({ filteredRecords: filteredUniqueRecords });
    }
  }

  getUsedRoles = (selectedRecord) => {
    const { currentUser } = this.props;
    const { dropdownRecords } = this.state;
    const foundRecord = dropdownRecords.find((r) => r.id === selectedRecord);
    const signatoryRolesConfirm = [];
    const signatoryRoles = [];
    if (foundRecord) {
      if ((foundRecord.signatory_1_resource && foundRecord.signatory_1_resource.code === 'records-add-entry')) {
        const role1 = foundRecord.signatory_1_role;
        signatoryRoles[0] = role1;
      }
      if ((foundRecord.signatory_2_resource && foundRecord.signatory_2_resource.code === 'records-add-entry')) {
        const role2 = foundRecord.signatory_2_role;
        signatoryRoles[1] = role2;
      }
      if ((foundRecord.signatory_3_resource && foundRecord.signatory_3_resource.code === 'records-add-entry')) {
        const role3 = foundRecord.signatory_3_role;
        signatoryRoles[2] = role3;
      }
      if ((foundRecord.signatory_4_resource && foundRecord.signatory_4_resource.code === 'records-add-entry')) {
        const role4 = foundRecord.signatory_4_role;
        signatoryRoles[3] = role4;
      }
      if ((foundRecord.signatory_1_resource && foundRecord.signatory_1_resource.code === 'records-add-entry') ||
        (foundRecord.signatory_2_resource && foundRecord.signatory_2_resource.code === 'records-add-entry') ||
        (foundRecord.signatory_3_resource && foundRecord.signatory_3_resource.code === 'records-add-entry') ||
        (foundRecord.signatory_4_resource && foundRecord.signatory_4_resource.code === 'records-add-entry')) {
        this.setState({ resourceAddEntryExists: true });
      } else {
        this.setState({ resourceAddEntryExists: false });
      }
      const role1 = foundRecord.signatory_1_role;
      const role2 = foundRecord.signatory_2_role;
      const role3 = foundRecord.signatory_3_role;
      const role4 = foundRecord.signatory_4_role;
      signatoryRolesConfirm[0] = role1 || null;
      signatoryRolesConfirm[1] = role2 || null;
      signatoryRolesConfirm[2] = role3 || null;
      signatoryRolesConfirm[3] = role4 || null;
    }
    let userHasRole = false;
    if (signatoryRoles.length) {
      userHasRole = currentUser.roles.some((r) => signatoryRoles.find((role) => { if (role && role.id === r.role.id) { return true; } return false; }));
    }
    this.setState({ userHasRole, signatoryRoles: signatoryRolesConfirm }, () => this.filterRecordsForDropdown());
  }

  fetchAllFolders = () => {
    const { locationId } = this.props;

    getFolders(locationId, '', 9999)
      .then((re) => {
        this.setState({
          listOfAllFolders: get(re, 'data.results', []),
        });
      });
  }

  fetchProductionRecords = () => {
    const { locationId } = this.props;
    const { selectedAssetOptions, recordsTitleQuery, selectedRecordType, showOnlyActiveFilter } = this.state;
    let filters = '';
    if (selectedAssetOptions && selectedAssetOptions.length > 0) {
      filters += `&assets_id__in=${selectedAssetOptions}`;
    }
    if (recordsTitleQuery) {
      filters += `&title=${recordsTitleQuery}`;
    }
    if (selectedRecordType) {
      filters += `&record_type=${selectedRecordType}`;
    }
    if (showOnlyActiveFilter) {
      filters += '&is_active=true';
    }

    getProductionRecords(locationId, filters)
      .then((re) => this.setState({
        listOfRecords: get(re, 'data.results', []),
        next: get(re, 'data.next'),
        previous: get(re, 'data.previous'),
        count: get(re, 'data.count'),
      }, () => {
        const selectedRecord = re && re.data && re.data.results && re.data.results[0] ? re.data.results[0].id : null;
        this.changeRecord(selectedRecord);
      }));
  }

  fetchByUrl = (url) => {
    const { selectedRecord, dropdownRecords } = this.state;

    this.setState({
      isLoadingRecords: true,
    });

    getPaginatedLogs(url)
      .then((re) => {
        this.setState({
          isLoadingRecords: false,
          selectedRecord,
          // eslint-disable-next-line eqeqeq
          theadData: dropdownRecords.find((record) => record.id == selectedRecord) || {},
          tbodyData: re.data.results || [],
          nextLogs: get(re, 'data.next'),
          previousLogs: get(re, 'data.previous'),
          countLogs: get(re, 'data.count'),
        });
      }).catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  changeRecord = (selectedRecord) => {
    if (!selectedRecord) {
      this.setState({
        isLoadingRecords: false,
        selectedRecord,
        theadData: {},
        tbodyData: [],
        nextLogs: null,
        previousLogs: null,
        countLogs: null,
      });
    }

    if (selectedRecord) {
      this.setState({
        selectedRecord,
      }, () => {
        this.getFilteredLogs();
        this.getUsedRoles(selectedRecord);
      });
    }
  }

  changeFolder = (selectedFolder) => {
    const { locationId } = this.props;

    this.setState({
      selectedFolder,
    });

    let filters = '';

    if (selectedFolder && selectedFolder !== 'all') {
      filters += `&folder=${selectedFolder}`;
    }

    getProductionRecords(locationId, filters, 9999)
      .then((res) => {
        this.setState({
          dropdownRecords: get(res, 'data.results') || [],
        }, () => {
          const selectedRecord = res?.data?.results[0]?.id || null;
          this.changeRecord(selectedRecord);
        });
      });
  }

  clearFilters = () => {
    this.setState({
      isLoadingRecords: true,
      dateFilterStart: moment().startOf('month'),
      dateFilterEnd: moment(),
      selectedLine: '',
      orderExtIdFilterQuery: '',
      selectedFolder: null,
    }, () => {
      this.changeFolder();
      this.getFilteredLogs();
    });
  }

  openProductionRecordsModal = () => {
    this.setState({
      isProductionRecordsModalOpen: true,
    });
  }

  handleSelectedRecord = (record) => {
    this.setState({
      editRecord: record.original,
    }, () => {
      this.openProductionRecordsModal();
    });
  }

  handleDeleteRecord = (record) => {
    const { locationId, t } = this.props;
    const { isDeleteAll, selectedRecordType } = this.state;

    const id = record && record.original && record.original.id ? record.original.id : null;

    if (isDeleteAll === true) {
      deleteProductionRecord(id, locationId)
        .then(() => {
          let filters = '';

          if (selectedRecordType) {
            filters += `&record_type=${selectedRecordType}`;
          }

          getProductionRecords(locationId, filters)
            .then((re) => this.setState({
              listOfRecords: get(re, 'data.results', []),
              next: get(re, 'data.next'),
              previous: get(re, 'data.previous'),
              count: get(re, 'data.count'),
            }, () => {
              const selectedRecord = re && re.data && re.data.results && re.data.results[0] ? re.data.results[0].id : null;
              this.changeRecord(selectedRecord);
            }));
        });
    } else {
      getLogs(id)
        .then((res) => {
          const array = res.data.results || null;

          if (!this.checkLogEntries(array)) {
            deleteProductionRecord(id, locationId)
              .then(() => {
                let filters = '';

                if (selectedRecordType) {
                  filters += `&record_type=${selectedRecordType}`;
                }

                getProductionRecords(locationId, filters)
                  .then((re) => this.setState({
                    listOfRecords: get(re, 'data.results', []),
                    next: get(re, 'data.next'),
                    previous: get(re, 'data.previous'),
                    count: get(re, 'data.count'),
                  }, () => {
                    const selectedRecord = re && re.data && re.data.results && re.data.results[0] ? re.data.results[0].id : null;
                    this.changeRecord(selectedRecord);
                  }));
              });
          } else {
            return Notification('error', '', t('page_content.production_records.delete_record_error_msg'));
          }
        });
    }
  }

  checkLogEntries = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (Object.prototype.hasOwnProperty.call(array[i], 'log_entries')) {
        if (array[i].log_entries.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  checkLogEntriesByID = (array, id) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].log_entries) {
        for (let j = 0; j < array[i].log_entries.length; j++) {
          // eslint-disable-next-line eqeqeq
          if (array[i].log_entries[j].production_record_entry.id == id) {
            return true;
          }
        }
      }
    }

    return false;
  }

  handleDeleteRecordEntry = async (entryId, productionRecordId) => {
    const { locationId, t } = this.props;
    this.setState({
      isLoadingRecordEntries: true,
    });
    try {
      const res = await getLogs(productionRecordId);
      const array = res.data.results || null;
      if (!this.checkLogEntriesByID(array, entryId)) {
        await deleteProductionRecordEntry(entryId, productionRecordId);
        const re = await getProductionRecords(locationId);
        this.setState({
          listOfRecords: get(re, 'data.results', []),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
        }, () => {
          const selectedRecord = re && re.data && re.data.results && re.data.results[0] ? re.data.results[0].id : null;
          this.changeRecord(selectedRecord);
          const selectedEditRecord = re.data.results.find((obj) => obj.id === productionRecordId);
          this.setState({
            editRecord: selectedEditRecord,
            isLoadingRecordEntries: false,
          });
        });
        this.setState({
          isLoadingRecordEntries: false,
        });
        return true;
      }
      Notification('error', '', t('page_content.production_records.delete_entry_error_msg'));
      this.setState({
        isLoadingRecordEntries: false,
      });
      return false;
    } catch (err) {
      Notification('error', '', t('page_content.production_records.delete_entry_error_msg'));
      this.setState({
        isLoadingRecordEntries: false,
      });
      return false;
    }
  };

  pdfExport = () => {
    const { selectedRecord, selectedLine, dateFilterStart, dateFilterEnd } = this.state;
    this.setState({
      isExporting: true,
    });
    exportToPdf(selectedRecord, dateFilterStart, dateFilterEnd, selectedLine)
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'export.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({
          isExporting: false,
        });
      });
  }

  xlsxExport = () => {
    const { selectedRecord, selectedLine, dateFilterStart, dateFilterEnd } = this.state;
    const { companyId } = this.props;
    this.setState({
      isExporting: true,
    });
    exportToXlsx(selectedRecord, dateFilterStart, dateFilterEnd, selectedLine, companyId)
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'export.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({
          isExporting: false,
        });
      });
  }

  handleSignRecord = (formLog, signatory_number) => {
    this.setState({
      isSignModalOpen: true,
      signatory_number,
      orderToSign: formLog,
    });
  }

  handleCloseSignModal = () => {
    this.setState({
      isSignModalOpen: false,
    });
  }

  handleSaveSignModal = (comment, isApproved) => {
    const { currentUser } = this.props;
    const { signatory_number, orderToSign } = this.state;

    if (currentUser && currentUser.id && signatory_number && orderToSign) {
      const id = orderToSign && orderToSign.id ? orderToSign.id : null;
      const recordId = orderToSign && orderToSign.production_record && orderToSign.production_record.id ? orderToSign.production_record.id : null;

      const data = {
        [`signatory_${signatory_number}`]: currentUser.id,
        [`signatory_${signatory_number}_time`]: moment().utc().toISOString(),
        comment_approval: comment,
        is_approved: isApproved,
      };

      signEntry(id, recordId, data)
        .then(() => {
          this.setState({
            isSignModalOpen: false,
            signatory_number: null,
            orderToSign: null,
          }, () => {
            this.getFilteredLogs();
          });
        });
    } else {
      this.setState({
        isSignModalOpen: false,
        signatory_number: null,
        orderToSign: null,
      });
    }
  }

  handleQueryChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange(key);
    });
  }

  handleQueryClear = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: '',
    }), () => {
      this.handleStateQueryChange(key);
    });
  }

  handleStateQueryChange = (key) => {
    this.setState({ isLoadingRecords: true });
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      if (key === 'selectedAssetOptions' || key === 'recordsTitleQuery' || key === 'selectedRecordType' || key === 'showOnlyActiveFilter') {
        this.fetchProductionRecords();
      } else {
        this.getFilteredLogs();
      }
    }, 600);
  }

  handleCloseAddRecordModal = () => {
    this.setState({ addRecordModalShow: false, selectedRow: null });
  }

  fetchPaginationRecords = async (url) => {
    getPaginatedProductionRecords(url)
      .then((re) => this.setState({
        listOfRecords: get(re, 'data.results', []),
        next: get(re, 'data.next'),
        previous: get(re, 'data.previous'),
        count: get(re, 'data.count'),
      }, () => {
        const selectedRecord = re && re.data && re.data.results && re.data.results[0] ? re.data.results[0].id : null;
        this.changeRecord(selectedRecord);
      }));
  }

  handleSaveAddRecord = async (data, selectedRowId) => {
    const { currentUser } = this.props;
    const { listOfRecords, selectedRecord } = this.state;
    let res;
    if (selectedRowId) {
      res = await editProductionRecordLog(data, selectedRowId);
    } else {
      res = await addProductionRecordLog(data);

      let signatoryNumber = null;

      const recordFound = listOfRecords.find((r) => r.id === selectedRecord);
      if (recordFound) {
        const signatoryFound1 = (recordFound.signatory_1_resource && recordFound.signatory_1_resource.code === 'records-add-entry');
        const signatoryFound2 = (recordFound.signatory_2_resource && recordFound.signatory_2_resource.code === 'records-add-entry');
        const signatoryFound3 = (recordFound.signatory_3_resource && recordFound.signatory_3_resource.code === 'records-add-entry');
        const signatoryFound4 = (recordFound.signatory_4_resource && recordFound.signatory_4_resource.code === 'records-add-entry');
        if (signatoryFound1) {
          signatoryNumber = 1;
        } else if (signatoryFound2) {
          signatoryNumber = 2;
        } else if (signatoryFound3) {
          signatoryNumber = 3;
        } else if (signatoryFound4) {
          signatoryNumber = 4;
        }
      }

      if (res?.data && signatoryNumber) {
        const signatoryData = {
          [`signatory_${signatoryNumber}`]: currentUser.id,
          [`signatory_${signatoryNumber}_time`]: moment().utc().toISOString(),
        };

        signEntry(res.data.id, res.data.production_record, signatoryData)
          .then(() => {
            this.setState({
              signatory_number: null,
            }, () => {
              this.getFilteredLogs();
            });
          });
      }
    }

    this.getFilteredLogs();
    this.setState({ addRecordModalShow: false, selectedRow: null });
  }

  mapAssetIdsToNames = (ids) => {
    const { assets } = this.state;

    return ids.map((id) => {
      const matchingObject = assets.find((obj) => obj.id === id);
      return matchingObject ? matchingObject.name : '';
    });
  }

  handleTabSelect = (tab) => {
    this.setState({ selectedTab: tab });
  }

  setFilters = (filters) => {
    this.setState({
      ...filters,
    }, () => {
      this.getFilteredLogs();
      this.getUsedRoles(filters.selectedRecord);
    });
  }

  handleRecordRowClick = (row) => {
    this.setState({
      selectedRow: row,
      addRecordModalShow: true,
    });
  }

  render() {
    const { t, companyId, locationId, currentUser, numberOfSignatories } = this.props;
    const {
      selectedRecord,
      listOfRecords,
      isLoadingRecords,
      dateFilterStart,
      dateFilterEnd,
      theadData,
      tbodyData,
      previous,
      next,
      lines,
      selectedLine,
      isProductionRecordsModalOpen,
      resources,
      editRecord,
      isLoadingRecordEntries,
      isExporting,
      exportPopup,
      isSignModalOpen,
      orderToSign,
      assets,
      orderExtIdFilterQuery,
      selectedAssetOptions,
      recordsTitleQuery,
      roles,
      addRecordModalShow,
      userHasRole,
      signatoryRoles,
      orders,
      resourceAddEntryExists,
      selectedRecordType,
      count,
      nextLogs,
      previousLogs,
      filteredRecords,
      dropdownRecords,
      listOfAllFolders,
      selectedFolder,
      selectedTab,
      isReadOnly,
      selectedRow,
      showOnlyActiveFilter,
    } = this.state;

    const recordTypeOptions = [
      { value: 'maintenance', label: t('page_content.production_records.maintenance') },
      { value: 'production', label: t('page_content.production_records.production') },
    ];

    return (
      <div className="industry-tab industry-location-production-record-list fullscreen tabs">
        {
          isSignModalOpen &&
          <SignModal
            orderToSign={orderToSign}
            isSignModalOpen={isSignModalOpen}
            handleCloseSignModal={this.handleCloseSignModal}
            handleSaveSignModal={this.handleSaveSignModal}
          />
        }
        <Tabs selectedIndex={selectedTab} onSelect={(e) => this.setState({ selectedTab: e })}>
          <TabList>
            <Tab>{t('page_content.production_records.all_records_history')}</Tab>
            <Tab>{t('page_content.production_records.specific_record_history')}</Tab>
            <Tab>{t('page_content.production_records.records_editing')}</Tab>
            <Tab>{t('page_content.production_records.folders_editing')}</Tab>
            {(currentUser && currentUser.is_admin) && <Tab>{t('page_content.production_records.status_overview_tab')}</Tab>}
          </TabList>

          <TabPanel>
            <RecordsList
              t={t}
              locationId={locationId}
              handleTabSelect={this.handleTabSelect}
              setFilters={this.setFilters}
              numberOfSignatories={numberOfSignatories}
            />
          </TabPanel>

          <TabPanel>
            {addRecordModalShow ?
              <AddRecord
                handleChangeRecord={this.changeRecord}
                data={theadData}
                defaultSelectedRecord={selectedRecord}
                listOfRecords={listOfRecords}
                locationId={locationId}
                t={t}
                lines={lines}
                handleSave={this.handleSaveAddRecord}
                handleCloseModal={this.handleCloseAddRecordModal}
                showModalState={addRecordModalShow}
                orders={orders}
                filteredRecords={filteredRecords}
                selectedRow={selectedRow}
              /> :
              <React.Fragment>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <div style={{ width: '350px' }}>
                    <Select
                      options={dropdownRecords}
                      getOptionLabel={(record) => record.title}
                      getOptionValue={(record) => record.id}
                      isSearchable
                      onChange={(value) => this.changeRecord(value.id)}
                      value={(dropdownRecords.find((rec) => rec.id === selectedRecord)) || ''}
                      styles={selectModalStyles}
                    />
                  </div>
                  <div style={{ width: '350px' }}>
                    <Select
                      options={[...listOfAllFolders, { folder: t('page_content.production_records.all_folders'), id: 'all' }]}
                      getOptionLabel={(option) => option.folder}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      placeholder={t('page_content.production_records.folder')}
                      onChange={(value) => this.changeFolder(value.id)}
                      styles={selectModalStyles}
                      value={([...listOfAllFolders, { folder: 'All', id: 'all' }].find((rec) => rec.id === selectedFolder)) || ''}
                    />
                  </div>
                </div>
                <div className="filters">
                  <div className="input_container">
                    <input
                      onChange={(e) => this.handleQueryChange('orderExtIdFilterQuery', e.target.value)}
                      placeholder={t('page_content.production_records.external_id_filter_placeholder')}
                      value={orderExtIdFilterQuery}
                    />
                    {orderExtIdFilterQuery && <button
                      onClick={() => {
                        this.handleQueryClear('orderExtIdFilterQuery');
                      }}
                    >&times;</button>}
                    <div className="icon_container">
                      <IconSearch
                        color="#555"
                        height="26px"
                        width="26px"
                      />
                    </div>
                  </div>
                  <div>
                    <Select
                      options={lines}
                      getOptionLabel={(line) => line.name}
                      getOptionValue={(line) => line.code}
                      isSearchable
                      placeholder={t('page_content.production_records.all_lines')}
                      onChange={(value) => this.onLineChange(value.code)}
                      value={(lines.find((l) => l.code === selectedLine)) || ''}
                      styles={selectStyles}
                    />
                  </div>
                  <div className="records_datepicker input_container">
                    <ReactDatePicker
                      placeholderText={t('page_content.production_records.select_start_date_placeholder')}
                      dateFormat="dd.MM.yyyy"
                      selected={dateFilterStart ? moment(dateFilterStart).toDate() : null}
                      maxDate={dateFilterEnd ? moment(dateFilterEnd).toDate() : null}
                      selectsStart
                      onChange={(e) => this.onFilterChange('dateFilterStart', e)}
                      showTimeSelect={false}
                      disabledKeyboardNavigation
                      locale={getLocale(t)}
                    />
                  </div>
                  <div className="records_datepicker input_container">
                    <ReactDatePicker
                      placeholderText={t('page_content.production_records.select_end_date_placeholder')}
                      dateFormat="dd.MM.yyyy"
                      selected={dateFilterEnd ? moment(dateFilterEnd).toDate() : null}
                      minDate={dateFilterStart ? moment(dateFilterStart).toDate() : null}
                      selectsEnd
                      onChange={(e) => this.onFilterChange('dateFilterEnd', e)}
                      showTimeSelect={false}
                      disabledKeyboardNavigation
                      locale={getLocale(t)}
                    />
                  </div>
                  <div className="clear_filters_button">
                    <Button
                      onClick={this.clearFilters}
                    >{t('shared.clear_button')}</Button>
                  </div>
                  <div className="export_PDF_button">
                    {(resourceAddEntryExists && (userHasRole || currentUser?.is_admin)) && <div>
                      <Button type="add" onClick={() => this.setState({ addRecordModalShow: true })}>{t('page_content.production_records.button_add_record')}</Button>
                    </div>}
                    <div>
                      <Button
                        type="export"
                        disabled={!dateFilterStart || !dateFilterEnd || !selectedRecord || isExporting}
                        onMouseOver={() => {
                          if ((!dateFilterStart || !dateFilterEnd || !selectedRecord) && !exportPopup) {
                            Notification('info', t('page_content.production_records.export_pdf_info'));
                            this.setState({
                              exportPopup: true,
                            });
                            setTimeout(() => {
                              this.setState({
                                exportPopup: false,
                              });
                            }, 5000);
                          }
                        }}
                        onClick={this.pdfExport}
                        loading={isExporting}
                        loadingText={t('page_content.production_records.export_pdf_loading')}
                      >{t('page_content.production_records.export_pdf')}
                      </Button>
                    </div>
                    <div>
                      <Button
                        type="export"
                        disabled={!dateFilterStart || !dateFilterEnd || !selectedRecord || isExporting}
                        onMouseOver={() => {
                          if ((!dateFilterStart || !dateFilterEnd || !selectedRecord) && !exportPopup) {
                            Notification('info', t('page_content.production_records.export_xlsx_info'));
                            this.setState({
                              exportPopup: true,
                            });
                            setTimeout(() => {
                              this.setState({
                                exportPopup: false,
                              });
                            }, 5000);
                          }
                        }}
                        onClick={this.xlsxExport}
                        loading={isExporting}
                        loadingText={t('page_content.production_records.export_xlsx_loading')}
                      >{t('page_content.production_records.export_xlsx')}
                      </Button>
                    </div>
                  </div>
                </div></React.Fragment>}
            {
              isLoadingRecords && !addRecordModalShow && <ContentLoader />
            }

            {
              !isLoadingRecords && selectedRecord && !addRecordModalShow &&
              <ProductionRecordsTable
                theadData={theadData || {}}
                tbodyData={tbodyData || []}
                next={nextLogs}
                previous={previousLogs}
                fetchByUrl={this.fetchByUrl}
                handleSignRecord={this.handleSignRecord}
                signatoryRoles={signatoryRoles}
                currentUser={currentUser}
                handleRecordRowClick={this.handleRecordRowClick}
                numberOfSignatories={numberOfSignatories}
              />
            }
          </TabPanel>

          <TabPanel>
            <div className="records_editing_tab">
              <div className="filters">
                <div className="input_container">
                  <input
                    onChange={(e) => this.handleQueryChange('recordsTitleQuery', e.target.value)}
                    placeholder={t('page_content.production_records.table_column_title')}
                    value={recordsTitleQuery}
                  />
                  {recordsTitleQuery && <button
                    onClick={() => {
                      this.handleQueryClear('recordsTitleQuery');
                    }}
                  >&times;</button>}
                  <div className="icon_container">
                    <IconSearch
                      color="#555"
                      height="26px"
                      width="26px"
                    />
                  </div>
                </div>
                <Select
                  options={assets}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isMulti
                  placeholder={t('page_content.production_records.asset_placeholder')}
                  onChange={(val) => {
                    const assetId = val ? val.map((obj) => obj.id) : [];
                    this.handleQueryChange('selectedAssetOptions', assetId);
                  }}
                  styles={multiSelectStyles}
                  value={assets.filter((option) => selectedAssetOptions.includes(option.id))}
                />
                <Select
                  options={recordTypeOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder=""
                  onChange={(e) => { this.handleQueryChange('selectedRecordType', e.value); }}
                  value={recordTypeOptions.find((o) => o.value === selectedRecordType)}
                  styles={selectStyles}
                />
                <div className="show_only_active">
                  <input
                    type="checkbox"
                    onChange={(e) => this.handleQueryChange('showOnlyActiveFilter', e.target.checked)}
                    checked={showOnlyActiveFilter}
                  />
                  <span>{t('page_content.production_records.show_only_active')}</span>
                </div>
                <div className="right_button_wrapper">
                  <Button
                    type="add"
                    disabled={isReadOnly}
                    onClick={this.openProductionRecordsModal}
                  >
                    {t('page_content.production_records.add_production_record_button')}
                  </Button>
                </div>
              </div>
              <ProductionRecordsEditTable
                t={t}
                isReadOnly={isReadOnly}
                listOfRecords={listOfRecords}
                isLoadingRecords={isLoadingRecords}
                handleDeleteRecord={this.handleDeleteRecord}
                mapAssetIdsToNames={this.mapAssetIdsToNames}
                handleSelectedRecord={this.handleSelectedRecord}
              />
              <div style={{ float: 'right' }}>
                <TableButtons
                  next={next}
                  previous={previous}
                  count={count}
                  fetchFunction={this.fetchPaginationRecords}
                />
              </div>
              {
                isProductionRecordsModalOpen &&
                <ProductionRecordsAddOrEditModal
                  isProductionRecordsModalOpen={isProductionRecordsModalOpen}
                  onSaveProductionRecordsModal={this.onSaveProductionRecordsModal}
                  onCloseProductionRecordsModal={this.onCloseProductionRecordsModal}
                  companyId={companyId}
                  locationId={locationId}
                  resources={resources}
                  editRecord={editRecord}
                  t={t}
                  handleDeleteRecordEntry={this.handleDeleteRecordEntry}
                  isLoadingRecordEntries={isLoadingRecordEntries}
                  assets={assets}
                  roles={roles}
                  currentUser={currentUser}
                  recordTypeOptions={recordTypeOptions}
                  listOfAllFolders={listOfAllFolders}
                  numberOfSignatories={numberOfSignatories}
                />
              }
            </div>

          </TabPanel>

          <TabPanel>
            <Folders
              fetchFoldersOnSave={this.fetchAllFolders}
              locationId={locationId}
              isReadOnly={isReadOnly}
              t={t}
            />
          </TabPanel>

          <TabPanel>
            <ProductionRecordsHeatMap
              locationId={locationId}
              recordTypeOptions={recordTypeOptions}
              t={t}
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

IndustryProductionRecords.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  numberOfSignatories: PropTypes.number,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
  devices: state.industry.container.devices,
  currentUser: state.currentUser,
  numberOfSignatories: get(state, 'app.locationConfig.config.number_of_signatories.name', 3),
});

export default connect(mapStateToProps, null)(withTranslation()(IndustryProductionRecords));
