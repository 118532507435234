import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { get } from 'lodash';
import DatePicker from 'react-datepicker';
import api from 'helpers/api';
import Select from 'react-select';
import { connect } from 'react-redux';
import { defaultDateFormat, defaultTimeFormat } from 'shared/constants';
import { IconSearch } from 'shared/Icons';
import { Table, Button, AssetTypePicker, TableButtons, ContentLoader, ShiftPicker } from 'shared';
import { checkActiveFilters } from 'industry/helpers';
// import { shiftTimesValidation } from 'shared/helpers';
import { saveSortingAndFiltering } from 'industry/actions';
import { getCurrentUserSuccess } from 'user/actions';
import usersYellow from 'shared/Icons/workforce/users-yellow.png';
import usersTeal from 'shared/Icons/workforce/users-teal.png';
import userGreen from 'shared/Icons/workforce/user-green.png';
import userRed from 'shared/Icons/workforce/user-red.png';
import gridView from 'shared/Icons/workforce/grid-view.png';
import gridViewActive from 'shared/Icons/workforce/grid-view-active.png';
import listView from 'shared/Icons/workforce/list-view.png';
import listViewActive from 'shared/Icons/workforce/list-view-active.png';
import { getLocale } from 'shared/DatePicker/constants';
import { getDaySummary, getWorkdays, getWorkdaysUrl, getAssets, getWorkerCategories } from '../actions';
import { getShifts } from '../../../../company/CompanySettings/components/ShiftsTab/actions';
import '../styles.scss';

class BookedWorkers extends Component {
  constructor(props) {
    super(props);

    const sortingAndFiltering = props && props.currentUser && props.currentUser.data ? props.currentUser.data : {};
    let searchTerm = '';

    this.defaultFilters = {
      searchTerm,
    };

    let filtersActive = false;

    if (sortingAndFiltering.workforce) {
      const sAndF = sortingAndFiltering.workforce;
      searchTerm = sAndF.searchTerm || '';
      filtersActive = checkActiveFilters(this.defaultFilters, sAndF);
    }

    this.state = {
      searchTerm: '',
      selectedDate: moment(),
      filtersActive,
      activeQuery: '',
      assets: [],
      selectedAsset: '',
      models: [],
      types: [],
      isLoading: false,
      query: '',
      showDetails: false,
      showAssetForm: false,
      isListView: true,
      summaryWorkers: 0,
      summaryPresent: 0,
      workers: [],
      next: null,
      previous: null,
      count: null,
      uniqueCount: null,
      timeCheckbox: false,
      no_check_out: '',
      asset_type: null,
      workerCategories: [],
      selectedWorkerCategorys: [],
      startDate: moment().startOf('day').utc().toISOString(),
      endDate: moment().endOf('day').utc().toISOString(),
      shiftTimes: [],
      isLoadingExport: false,
      selectedAscDesc: 'desc',
      selectedSort: 'date',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    getWorkerCategories(companyId)
      .then((re) => {
        this.setState({
          workerCategories: re && re.data && re.data.results ? re.data.results : [],
        });
      });

    getAssets(companyId, locationId)
      .then((re) => {
        this.setState({
          assets: re.data.results,
        });
      }).catch((err) => console.log(err));

    this.fetchShiftTimes(locationId);
    this.search();
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      this.search();
    });
  }

  saveSortingAndFiltering = () => {
    const {
      searchTerm,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.workforce = {
        searchTerm,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
      this.checkActiveSortingAndFiltering(currentUser.data.workforce);
    }
  }

  checkActiveSortingAndFiltering = (userFilters) => {
    const filtersActive = checkActiveFilters(this.defaultFilters, userFilters);
    this.setState({ filtersActive });
  }

  toggleView = (isListView) => {
    this.setState({ isListView });
  }

  fetchPaginatedBookedWorkers = (url) => {
    this.setState({
      isLoading: true,
    });
    getWorkdaysUrl(url)
      .then((re) => {
        this.setState({
          workers: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoading: false,
        });
      })
      .catch((e) => console.error('Error while fetching documents', e));
  }

  fetchShiftTimes = (locationId) => {
    this.setState({ isLoading: true });
    getShifts(locationId)
      .then((re) => {
        this.setState({
          shiftTimes: get(re, 'data.results') || [],
          isLoading: false,
        });
      });
  }

  handleSorting = (sortData) => {
    let column = sortData.id;

    if (column === 'worker.external_id' ||
      column === 'worker.name' ||
      column === 'worker.last_name' ||
      column === 'asset.name'
    ) {
      column = column.replace('.', '__');
    }

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.search();
    });
  }

  search = () => {
    const { companyId, locationId } = this.props;
    const { isLoading, selectedDate, searchTerm, selectedAscDesc, selectedSort, no_check_out, selectedAsset, asset_type, selectedWorkerCategorys, startDate, endDate, timeCheckbox } = this.state;

    if (!isLoading) {
      this.setState({
        isLoading: true,
      });

      let filters = '';

      if (searchTerm?.length) {
        if (searchTerm.includes(' ')) {
          filters += `&full_name=${searchTerm}`;
        } else {
          filters += `&first_or_last_name=${searchTerm}`;
        }
      }

      if (no_check_out !== '') {
        filters += `&no_check_out=${no_check_out}`;
      }

      if (startDate && endDate && timeCheckbox) {
        filters += `&check_in__gte=${moment(startDate).utc().toISOString()}&check_in__lte=${moment(endDate).utc().toISOString()}`;
      }

      if (startDate && endDate && !timeCheckbox) {
        filters += `&date__gte=${moment(startDate).format('YYYY-MM-DD')}&date__lte=${moment(endDate).format('YYYY-MM-DD')}`;
      }

      if (selectedAsset) {
        filters += `&asset=${selectedAsset}`;
      }

      if (asset_type) {
        filters += `&asset_type=${asset_type}`;
      }

      if (selectedWorkerCategorys && selectedWorkerCategorys.length) {
        const workerCategoryIds = selectedWorkerCategorys.length ? selectedWorkerCategorys.map((obj) => obj.id) : [];

        filters += `&worker_category__in=${workerCategoryIds}`;
      }

      const asc = selectedAscDesc === 'desc' ? '-' : '';
      filters += `&order_by=${asc}${selectedSort}`;

      getDaySummary(companyId, locationId, selectedDate)
        .then((re) => {
          this.setState({
            summaryPresent: re && re.data && re.data.present_workers ? re.data.present_workers : 0,
            summaryWorkers: re && re.data && re.data.workers ? re.data.workers : 0,
          });
        });

      getWorkdays(companyId, locationId, filters)
        .then((re) => {
          this.setState({
            count: re && re.data ? re.data.count : null,
            workers: re.data.results,
            next: re.data.next,
            previous: re.data.previous,
            isLoading: false,
          }, () => {
            this.saveSortingAndFiltering();
          });
        });

      getWorkdays(companyId, locationId, filters, 99999)
        .then((re) => {
          const uniques = get(re, 'data.results');
          const uniqueWorkerIds = new Set(uniques.map((element) => element.worker.id));
          this.setState({
            uniqueCount: uniqueWorkerIds.size,
          });
        });
    }
  }

  handleDatePickerChange = (date, isStartDate) => {
    const selectedDate = date ? moment(date) : null;
    const newState = { [isStartDate ? 'startDate' : 'endDate']: selectedDate };

    this.setState(newState, () => {
      this.saveSortingAndFiltering();
      this.search();
    });
  }

  // handleShiftTime = (shift) => {
  //   const { startDate } = this.state;
  //   let newStartDate;
  //   let newEndDate;

  //   const parseTimeString = (timeString) => {
  //     const [hour, minute, second] = timeString.split(':').map(Number);
  //     return { hour, minute, second };
  //   };

  //   if (shift && shift.default_begin && shift.default_end) {
  //     const startTime = parseTimeString(shift.default_begin);
  //     const endTime = parseTimeString(shift.default_end);
  //     newStartDate = moment(startDate).startOf('day').set({ hour: startTime.hour, minute: startTime.minute, second: startTime.second });
  //     if (startTime.hour > endTime.hour) {
  //       newEndDate = moment(startDate).startOf('day').add(1, 'day').set({ hour: endTime.hour, minute: endTime.minute, second: endTime.second });
  //     } else {
  //       newEndDate = moment(startDate).startOf('day').set({ hour: endTime.hour, minute: endTime.minute, second: endTime.second });
  //     }
  //   }

  //   this.setState({
  //     startDate: newStartDate.toISOString(),
  //     endDate: newEndDate.toISOString(),
  //     timeCheckbox: true,
  //   }, () => {
  //     this.saveSortingAndFiltering();
  //     this.search();
  //   });
  // };

  handleDatePickerShiftChange = (startDate, endDate) => {
    this.setState({
      timeCheckbox: true,
      startDate,
      endDate,
    }, () => {
      this.search();
      this.saveSortingAndFiltering();
    });
  }

  clearSearch = () => {
    this.setState({
      searchTerm: '',
      next: null,
      previous: null,
      timeCheckbox: false,
      no_check_out: '',
      selectedAsset: '',
      asset_type: null,
      selectedWorkerCategorys: [],
      startDate: moment().startOf('day').utc().toISOString(),
      endDate: moment().endOf('day').utc().toISOString(),
    }, () => {
      this.search();
    });
  }

  exportToExcel = () => {
    const { companyId, locationId } = this.props;
    const { searchTerm, count, no_check_out, selectedAsset, selectedSort, selectedAscDesc, timeCheckbox, asset_type, selectedWorkerCategorys, startDate, endDate } = this.state;

    let filters = '';

    if (searchTerm.includes(' ')) {
      filters += `&full_name=${searchTerm}`;
    } else {
      filters += `&first_or_last_name=${searchTerm}`;
    }

    if (no_check_out !== '') {
      filters += `&no_check_out=${no_check_out}`;
    }

    if (timeCheckbox) {
      filters += `&check_in__gte=${moment(startDate).utc().toISOString()}&check_in__lte=${moment(endDate).utc().toISOString()}`;
    }

    if (startDate) {
      filters += `&date__gte=${moment(startDate).format('YYYY-MM-DD')}`;
    }

    if (endDate) {
      filters += `&date__lte=${moment(endDate).format('YYYY-MM-DD')}`;
    }

    if (selectedAsset) {
      filters += `&asset=${selectedAsset}`;
    }

    if (asset_type) {
      filters += `&asset_type=${asset_type}`;
    }

    if (selectedWorkerCategorys && selectedWorkerCategorys.length) {
      const workerCategoryIds = selectedWorkerCategorys.length ? selectedWorkerCategorys.map((obj) => obj.id) : [];

      filters += `&worker_category__in=${workerCategoryIds}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    this.setState({
      isLoadingExport: true,
    });

    api.get(`/api/v1/workforce/workdays/?company=${companyId}&location=${locationId}${filters}&format=xlsx&limit=${count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'workforce_list.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({
          isLoadingExport: false,
        });
      });
  }

  renderListView = () => {
    const { t } = this.props;
    const { workers, isLoading, next, previous, count } = this.state;

    return (
      <div>
        <Table
          columns={[
            {
              Header: () => <span>{t('page_content.workforce_management.table_column_date')}</span>,
              accessor: 'date',
              Cell: (row) => moment(row.value).format(defaultDateFormat),
            },
            {
              Header: () => <span>{t('page_content.workforce_management.table_column_employee_id')}</span>,
              accessor: 'worker.external_id',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.workforce_management.table_column_name')}</span>,
              accessor: 'worker.name',
            },
            {
              Header: () => <span>{t('page_content.workforce_management.table_column_last_name')}</span>,
              accessor: 'worker.last_name',
            },
            {
              Header: () => <span>{t('page_content.workforce_management.table_column_asset')}</span>,
              accessor: 'asset.name',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.workforce_management.table_column_check_in_time')}</span>,
              accessor: 'check_in',
              Cell: (row) => (row.value ? moment(row.value).format(defaultTimeFormat) : '-'),
            },
            {
              Header: () => <span>{t('page_content.workforce_management.table_column_check_out_time')}</span>,
              accessor: 'last_check_out',
              Cell: (row) => (row.value && row.original.status === 'absent' ? moment(row.value).format(defaultTimeFormat) : ''),
            },
            {
              Header: () => <span>{t('page_content.workforce_management.table_column_total_booked')}</span>,
              accessor: 'present_time',
              Cell: (row) => (row.value ? moment.utc(row.value * 1000).format(defaultTimeFormat) : '-'),
            },
          ]}
          data={workers}
          defaultPageSize={100}
          loading={isLoading}
          minRows={0}
          noDataText=" "
          showPagination={false}
          defaultSorted={[{ id: 'date', desc: true }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        <TableButtons previous={previous} next={next} fetchFunction={this.fetchPaginatedBookedWorkers} count={count} />
      </div>

    );
  }

  renderGridView = () => {
    const { t } = this.props;
    const { workers, isLoading, next, previous, count } = this.state;

    if (isLoading) {
      return <ContentLoader />;
    }
    return (
      <div>
        <div className="workforce__grid">
          {workers.map((w) => {
            const profilePlaceholder = w.status === 'present' ? userGreen : userRed;

            return (
              <div className="workforce__grid-item" key={w.id}>
                <table className="workforce__table">
                  <tbody>
                    <tr>
                      <td className="workforce__name">
                        {w.worker.name}<br />
                        {w.worker.last_name}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <img className="workforce__profile-photo" src={w?.worker?.profile_picture?.thumbnail || profilePlaceholder} alt="" />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        {w.asset.name || '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>{t('page_content.workforce_management.grid_check_in')}</td>
                      <td className="workforce__table-value">
                        {w.check_in ? moment(w.check_in).format('HH:mm:ss') : ''}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('page_content.workforce_management.grid_check_out')}</td>
                      <td className="workforce__table-value" style={{ padding: '5px 0' }}>
                        {w.check_out && w.status === 'absent' ? moment(w.last_check_out).format('HH:mm:ss') : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className={`workforce__status workforce__status--${w.status}`}>
                  {t(`page_content.workforce_management.status_${w.status}`)}
                  <span>{w.status === 'absent'
                    ?
                    `${Math.round(w.absent_time / 60)} m` : ''}</span>
                </div>
              </div>
            );
          })}
        </div>
        <TableButtons previous={previous} next={next} fetchFunction={this.fetchPaginatedBookedWorkers} count={count} />
      </div>
    );
  }

  render() {
    const { t, companyId, currentUser, locationId } = this.props;
    const {
      isListView,
      summaryPresent,
      summaryWorkers,
      searchTerm,
      timeCheckbox,
      no_check_out,
      assets,
      selectedAsset,
      asset_type,
      workerCategories,
      selectedWorkerCategorys,
      startDate,
      endDate,
      shiftTimes,
      isLoadingExport,
      workers,
      uniqueCount,
    } = this.state;

    // React-select custom styles
    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '200px',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0px 0px 0px 5px',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        padding: '0px 3px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: '100%',
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    const widerSelectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '260px',
        minHeight: '34px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0px 0px 0px 3px',
        overflowY: 'scroll',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        padding: '0px 3px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 260,
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    const loggedOptions = [
      { value: true, name: t('page_content.workforce_management.only_logged_in') },
      { value: false, name: t('page_content.workforce_management.only_logged_out') },
    ];
    return (
      <div className="booked_workers_container">
        <div className="booked_workers_card_container">
          <div className="booked_workers_info_card">
            <div className="leftColumn">
              <img src={usersYellow} alt="" />
            </div>
            <div className="rightColumn">
              <div className="title">{t('page_content.workforce_management.workers_title')}</div>
              <div className="workerSum_first">{summaryWorkers || 0}</div>
            </div>
          </div>
          <div className="booked_workers_info_card">
            <div className="leftColumn">
              <img src={usersTeal} alt="" />
            </div>
            <div className="rightColumn">
              <div className="title">{t('page_content.workforce_management.workers_present_title')}</div>
              <div className="workerSum_second">{summaryPresent || 0}</div>
            </div>
          </div>
        </div>
        <div className="booked_workers_toolbar">
          <div className="toolbar_row">
            <div className="input_container">
              <input onChange={(e) => this.onFilterChange('searchTerm', e.target.value)} placeholder="" value={searchTerm} />
              <div className="icon_container">
                <IconSearch
                  color="#555"
                  height="26px"
                  width="26px"
                />
              </div>
            </div>
            <Select
              options={workerCategories}
              getOptionLabel={(category) => category.name}
              getOptionValue={(category) => category.id}
              isSearchable
              isMulti
              placeholder={t('page_content.workforce_management.worker_category_placeholder')}
              onChange={(value) => this.onFilterChange('selectedWorkerCategorys', value ?? '')}
              value={selectedWorkerCategorys}
              styles={widerSelectStyles}
            />

            <Select
              options={loggedOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              isSearchable
              isClearable
              placeholder={t('page_content.workforce_management.checked_in_out_placeholder')}
              onChange={(e) => this.onFilterChange('no_check_out', e?.value ?? '')}
              value={(loggedOptions.find((opt) => opt.value === no_check_out)) || ''}
              styles={widerSelectStyles}
            />

            <Select
              options={assets}
              getOptionLabel={(asset) => asset.name}
              getOptionValue={(asset) => asset.id}
              isSearchable
              isClearable
              placeholder={t('page_content.workforce_management.asset_placeholder')}
              onChange={(e) => this.onFilterChange('selectedAsset', e?.id ?? '')}
              value={(assets.find((a) => a.id === selectedAsset)) || ''}
              styles={selectStyles}
            />

            {
              currentUser && currentUser.is_admin &&
              <AssetTypePicker
                isClearable
                asset_type={asset_type || null}
                placeholder={t('page_content.workforce_management.asset_type_placeholder')}
                handleChange={(val) => this.onFilterChange('asset_type', val?.id ?? '')}
                htmlId="type"
                companyId={companyId}
                locationId={locationId}
                customStyles={selectStyles}
              />
            }
            <div className="clear_all_button">
              <Button
                onClick={this.clearSearch}
              >
                {t('shared.clear_button')}
              </Button>
            </div>
          </div>
          <div className="toolbar_row layout_export">
            <div className="datepicker_container">
              {`${t('page_content.workforce_management.start_date')}:`}
              <div className="datepicker_selector">
                <DatePicker
                  className="datepicker_input"
                  dateFormat={timeCheckbox ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
                  selected={startDate ? moment(startDate).toDate() : null}
                  onChange={(date) => this.handleDatePickerChange(date, true)}
                  maxDate={new Date()}
                  timeFormat="HH:mm"
                  calendarContainer={this.myContainer}
                  locale={getLocale(t)}
                  showTimeSelect={timeCheckbox}
                  timeCaption={t('date_picker_locale.time_translation')}
                />
              </div>
              {`${t('page_content.workforce_management.end_date')}:`}
              <div className="datepicker_selector">
                <DatePicker
                  className="datepicker_input"
                  dateFormat={timeCheckbox ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
                  selected={endDate ? moment(endDate).toDate() : null}
                  onChange={(date) => this.handleDatePickerChange(date, false)}
                  minDate={moment(startDate).toDate()}
                  timeFormat="HH:mm"
                  maxDate={new Date()}
                  calendarContainer={this.myContainer}
                  showTimeSelect={timeCheckbox}
                  locale={getLocale(t)}
                  timeCaption={t('date_picker_locale.time_translation')}
                />
              </div>
              <div className="datepicker_checkbox_wrapper">
                <input
                  type="checkbox"
                  checked={timeCheckbox}
                  onChange={() => this.onFilterChange('timeCheckbox', !timeCheckbox)}
                />
                {t('page_content.workforce_management.show_time_select')}
              </div>
              <ShiftPicker shifts={shiftTimes} handleShiftPickerChange={this.handleDatePickerShiftChange} start={startDate} end={endDate} />
              {/* {shiftTimes && shiftTimes.length > 0 && shiftTimesValidation(shiftTimes) && shiftTimes.map((shift) => (
                <Button key={shiftTimes.id} onClick={() => this.handleShiftTime(shift)}>{`${shift.name}`}</Button>
              ))} */}
            </div>
            <div className="layout_export">
              <div className="wrapper_buttons_container">
              <div className="wrapper_button" onClick={() => { this.toggleView(false); }}>
                <img src={isListView ? gridView : gridViewActive} alt="grid" />
                <div style={{ color: isListView ? '' : '#2cd9c5' }}>Grid View</div>
              </div>
              <div className="wrapper_button" onClick={() => { this.toggleView(true); }}>
                <img src={isListView ? listViewActive : listView} alt="grid" />
                <div style={{ color: isListView ? '#2cd9c5' : '' }}>List View</div>
              </div>
              </div>
              <div className="export_button">
                <Button
                  type="export"
                  onClick={this.exportToExcel}
                  loading={isLoadingExport}
                >{t('page_content.workforce_management.export_to_excel_button')}</Button>
              </div>
            </div>
          </div>
          {workers &&
            <div className="workers_count">
                <span>{t('page_content.workforce_management.present_workers')}{uniqueCount}</span>
            </div>}
        </div>
        {
          isListView ?
            this.renderListView() : this.renderGridView()
        }
      </div>
    );
  }
}

BookedWorkers.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BookedWorkers));
