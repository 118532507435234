import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Table, ContentLoader, Modal } from 'shared';
import { defaultDateTimeFormat, modalSizes } from 'shared/constants';

const OrderGroupsModal = ({
  t,
  isOrderGroupsModalOpen,
  closeOrderGroupsModal,
  output,
  product_type,
  isLoadingOrderGroupsModal,
  getLocationName,
  firstLetterUpperCase,
  orderItemHistory,
  isLoading,
}) => {
  const createHtmlList = (obj) => {
    if (obj === null) {
      return null;
    }

    return Object.keys(obj).map((item, i) => (
      <li key={i}>
        <b>{firstLetterUpperCase(item)}:</b>
        {typeof obj[item] !== 'object' ? (
          ` ${obj[item]}`
        ) : Array.isArray(obj[item]) ? (
          ` ${obj[item].join(', ')}`
        ) : (
          <ul>{createHtmlList(obj[item])}</ul>
        )}
      </li>
    ));
  };

  return (
    <Modal
      isOpen={isOrderGroupsModalOpen}
      handleClose={closeOrderGroupsModal}
      size={modalSizes.large}
      title={t('page_content.orders.order_details.order_groups_tab.order_groups_modal.modal_title')}
    >
      {
        isLoadingOrderGroupsModal &&
        <ContentLoader />
      }
      {!isLoadingOrderGroupsModal &&
        <Tabs>
          <TabList>
            <Tab>{t('page_content.orders.order_details.order_groups_tab.order_groups_modal.tabs.details')}</Tab>
            <Tab>{t('page_content.orders.order_details.order_groups_tab.order_groups_modal.tabs.product')}</Tab>
            <Tab>{t('page_content.orders.order_details.order_groups_tab.order_groups_modal.tabs.history')}</Tab>
          </TabList>

          <TabPanel>
            {output && Object.keys(output).length !== 0 ?
              <div className="details_list">{createHtmlList(output)}</div>
              : <div style={{ textAlign: 'center' }}>No details data</div>}
          </TabPanel>
          <TabPanel>
            {product_type && Object.keys(product_type).length !== 0 ?
              <div className="details_list">{createHtmlList(product_type)}</div>
              : <div style={{ textAlign: 'center' }}>No product data</div>}
          </TabPanel>
          <TabPanel>
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.order_groups_modal.history_tab.table_column_asset_group_name')}</span>,
                  accessor: 'asset_group.name',
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.order_groups_modal.history_tab.table_column_location')}</span>,
                  accessor: 'asset_group.location',
                  Cell: (row) => <span>{row.value ? getLocationName(row.value) : '-'}</span>,
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.order_groups_modal.history_tab.table_column_operators')}</span>,
                  accessor: 'operator',
                  Cell: (row) => (row.value ? row.value : '-'),
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.order_groups_modal.history_tab.table_column_time')}</span>,
                  accessor: 'created_at',
                  Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
                  style: {
                    cursor: 'default',
                  },
                },
              ]}
              data={orderItemHistory || []}
              showPagination={false}
              sortable
              minRows={0}
              F
              noDataText=" "
              loading={isLoading}
              manual={false}
              defaultSorted={[{ id: 'created_at', desc: true }]}
            />
          </TabPanel>
        </Tabs>}
    </Modal>
  );
};

OrderGroupsModal.propTypes = {
  t: PropTypes.func.isRequired,
  isOrderGroupsModalOpen: PropTypes.bool.isRequired,
  closeOrderGroupsModal: PropTypes.func.isRequired,
  output: PropTypes.object,
  product_type: PropTypes.object,
  isLoadingOrderGroupsModal: PropTypes.bool.isRequired,
  getLocationName: PropTypes.func.isRequired,
  firstLetterUpperCase: PropTypes.func.isRequired,
  orderItemHistory: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default OrderGroupsModal;
