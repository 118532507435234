import React, { Component } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Modal, ContentLoader } from 'shared';
import { getPalletTypes } from '../actions';

class AddPalletsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      pallet_type: null,
      tare_weight: 0,
      create_pdf: true,
      isLoadingPalletTypes: false,
      pallets_types: [],
    };
  }

  componentDidMount() {
    this.getPalletsTypes();
  }

  getPalletsTypes = () => {
    const { companyId } = this.props;
    this.setState({
      isLoadingPalletTypes: true,
      pallet_type: null,
    });

    getPalletTypes(companyId)
      .then((re) => {
        this.setState({
          pallets_types: get(re, 'data.results') || [],
          pallet_type: get(re, 'data.results[0]'),
          isLoadingPalletTypes: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoadingPalletTypes: false,
        });
      });
  }

  onSave = () => {
    const { pallet_type, quantity, tare_weight, create_pdf } = this.state;
    const { addPallets } = this.props;

    addPallets({ pallet_type: pallet_type?.id || null, count: quantity || 1, tare_weight: tare_weight || 0, create_pdf: quantity > 50 ? false : create_pdf });
  }

  onChange = (key, value) => {
    if (key === 'quantity' && value > 50) {
      this.setState({
        create_pdf: false,
      });
    }
    this.setState({
      [key]: value,
    });
  }

  blockInvalidChar = (e) => ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault();

  render() {
    const { quantity, pallet_type, tare_weight, create_pdf, pallets_types, isLoadingPalletTypes } = this.state;
    const { closeAddPalletsModal, t } = this.props;

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '400px',
        minHeight: '34px',
        height: '34px',
        padding: 0,
        fontSize: '14px',
        color: '#555',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0 10px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: '0',
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 400,
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    return (
      <Modal
        isOpen
        handleSave={this.onSave}
        handleClose={closeAddPalletsModal}
        title={t('page_content.warehouse.add_pallet')}
      >

        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.warehouse.pallet_type')}:
                  </label>
                </td>
                <td className="input">
                  <Select
                    options={pallets_types}
                    getOptionLabel={(o) => o.prefix}
                    getOptionValue={(o) => o.id}
                    isSearchable
                    menuPosition="fixed"
                    onChange={(value) => this.onChange('pallet_type', value)}
                    value={pallet_type || ''}
                    styles={selectStyles}
                    isLoading={isLoadingPalletTypes}
                    loadingMessage={() => <ContentLoader />}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.warehouse.quantity')}:
                  </label>
                </td>
                <td className="input">
                  <input
                    type="number"
                    min="1"
                    value={quantity}
                    onChange={(e) => { this.onChange('quantity', e.target.value); }}
                    onKeyDown={this.blockInvalidChar}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.warehouse.tare_weight')}:
                  </label>
                </td>
                <td className="input">
                  <input
                    type="text"
                    value={tare_weight}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) this.onChange('tare_weight', value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.warehouse.create_pdf')}:
                  </label>
                </td>
                <td className="input">
                  <input
                    type="checkbox"
                    disabled={quantity > 50}
                    checked={create_pdf}
                    onChange={(e) => { this.onChange('create_pdf', e.target.checked); }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

AddPalletsModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeAddPalletsModal: PropTypes.func.isRequired,
  addPallets: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default withTranslation()(AddPalletsModal);
