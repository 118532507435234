import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, Button } from 'shared';
import { modalSizes } from 'shared/constants';

class FinalBookingModal extends Component {
  render() {
    const {
      t,
      isFinalBookingModalOpen,
      handleBookTT,
      closeFinalBookingModal,
    } = this.props;

    return (
      <Modal
        isOpen={isFinalBookingModalOpen}
        handleClose={() => closeFinalBookingModal()}
        size={modalSizes.small}
      >
        <div style={{ textAlign: 'center' }}>
          <h4>{t('page_content.orders.order_details.bookings_tab.final_booking')}</h4>

          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '10px' }}>
            <Button type="success" onClick={() => handleBookTT(true)}>{t('page_content.orders.order_details.bookings_tab.final_booking_final')}</Button>
            <Button onClick={() => handleBookTT(false)}>{t('page_content.orders.order_details.bookings_tab.final_booking_partial')}</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

FinalBookingModal.propTypes = {
  t: PropTypes.func.isRequired,
  isFinalBookingModalOpen: PropTypes.bool,
  handleBookTT: PropTypes.func.isRequired,
  closeFinalBookingModal: PropTypes.func.isRequired,
};

export default withTranslation()(FinalBookingModal);
