import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { IconCloudDownload } from 'shared/Icons';
import { getLocale } from 'shared/DatePicker/constants';
import { Modal, Button, Table, ContentLoader } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { modalSizes, defaultISODateFormat } from 'shared/constants';
import './styles.scss';

import {
  addEmployment,
  deleteDocument,
  editDocument,
  editEmployment,
  getDocumentTypes,
  getForms,
  getQuestionOptions,
  getQuestions,
  getSurveyAnswers,
  getWorkerDocuments,
  getWorkerSurveys,
  uploadDocument,
} from './actions';

import FormLayoffModal from './FormLayoffModal';
import AddReasonModalPage from './components/AddReasonModalPage';
import EmploymentAssignedEquipmentModalTable from './components/EmploymentAssignedEquipmentModalTable';

const AddEmploymentModal = ({
  t,
  reasons,
  workers,
  showModal,
  companyId,
  handleClose,
  currentUser,
  initialData,
  fetchReasons,
  handleAddLayoff,
  layoffCategoryCode,
}) => {
  const [showAddReasons, setShowAddReason] = useState(false);
  const [reasonOptions, setReasonOptions] = useState([]);

  const [employmentData, setEmploymentData] = useState(initialData || {});

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [initialDocuments, setInitialDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  const [surveyData, setSurveyData] = useState({});
  const [workerSurveyId, setWorkerSurveyId] = useState(null);
  const [workerSurveyData, setWorkerSurveyData] = useState(null);

  const [showFormModal, setShowFormModal] = useState(false);
  const [layoffForms, setLayoffForms] = useState([]);
  const [error, setError] = useState('');
  const [isLoadingForm, setIsLoadingForm] = useState(true);

  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [documentsValid, setDocumentsValid] = useState(true);
  const [documentsUploaded, setDocumentsUploaded] = useState(false);

  useEffect(() => {
    if (workerSurveyData) {
      setIsLoadingForm(false);
    }
  }, [workerSurveyData]);

  useEffect(() => {
    if (selectedDocuments?.length) {
      setIsLoadingDocuments(false);
    }
  }, [selectedDocuments]);

  const handleChange = (value, key) => {
    if (key === 'fixed_term_length') {
      setEmploymentData((prevState) => ({
        ...prevState,
        probationary_period_end: value ? moment(employmentData?.start_working).add(value, 'months') : null,
        [key]: value,
      }));
    } else if (key === 'start_working') {
      setEmploymentData((prevState) => ({
        ...prevState,
        probationary_period_end: employmentData?.fixed_term_length ? moment(value).add(employmentData?.fixed_term_length, 'months') : null,
        [key]: value,
      }));
    } else {
      setEmploymentData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const fetchDocumentTypes = async () => {
    const res = await getDocumentTypes(companyId);
    const docTypes = get(res, 'data.results', []);
    setDocumentTypes(docTypes);
  };

  const fetchWorkerDocuments = async () => {
    setIsLoadingDocuments(true);
    const resp = await getWorkerDocuments(companyId, employmentData?.worker?.id);
    const workerDocuments = get(resp, 'data.results', []);
    const layoffWorkerDocuments = workerDocuments?.filter((d) => d.employment === employmentData.id);
    if (!layoffWorkerDocuments?.length) {
      setIsLoadingDocuments(false);
      return;
    }
    setIsLoadingDocuments(false);

    // Create deep copies of the array
    const initialDocsCopy = JSON.parse(JSON.stringify(layoffWorkerDocuments));
    const selectedDocsCopy = JSON.parse(JSON.stringify(layoffWorkerDocuments));

    setInitialDocuments(initialDocsCopy);
    setSelectedDocuments(selectedDocsCopy);
  };

  const fetchForms = async () => {
    setIsLoadingForm(true);
    const res = await getForms(companyId, `&category_code=${layoffCategoryCode}&is_published=true&who_can_start_survey=hr`);
    const formsData = get(res, 'data.results', []);
    if (formsData.length > 0) setLayoffForms(formsData);
    else setError(t('page_content.human_resources.employments.error_no_layoff_form'));
    setIsLoadingForm(false);
  };

  const handleNewWorkerSurveyId = (value) => {
    setWorkerSurveyId(value);
  };

  const fetchSurveyAnswers = async (values, surveyId) => {
    if (!surveyId || !employmentData?.worker?.id) {
      setSurveyData(values);
      return;
    }
    if (!workerSurveyId && surveyId) {
      setWorkerSurveyId(surveyId);
    }
    const res = await getSurveyAnswers(surveyId);
    const surveyAnswers = get(res, 'data.results', []);
    const newData = values?.map((question) => {
      const data = question;
      const foundQuestion = surveyAnswers?.filter((q) => q?.question?.id === question?.id);
      if (foundQuestion[0]?.question?.question_type === 'choice') {
        const foundIndex = question?.answers.findIndex((a) => a?.value === foundQuestion[0]?.answer);
        data.answer = foundQuestion[0].answer;
        data.answers[foundIndex].answer = true;
      }
      if (foundQuestion[0]?.question?.question_type === 'select') {
        if (foundQuestion.length > 1) {
          const answers = foundQuestion.map((q) => q.answer);
          data.answer = answers.join(',');
        } else {
          data.answer = foundQuestion[0].answer;
        }
      }
      if (foundQuestion[0]?.question?.question_type === 'number') {
        data.answer = foundQuestion[0].answer;
      }
      if (foundQuestion[0]?.question?.question_type === 'free_text') {
        data.text_answer = foundQuestion[0].text_answer;
      }
      if (foundQuestion[0]?.question?.question_type === 'date') {
        data.date_answer = foundQuestion[0].date_answer;
      }
      return data;
    });
    setSurveyData(newData);
  };

  const fetchQuestionsAndAnswers = async () => {
    if (layoffForms[0]?.id) {
      setIsLoadingForm(true);
      const res = await getQuestions(layoffForms[0]?.id);
      const questionsData = get(res, 'data.results', []);
      await Promise.all(questionsData.map(async (data, i) => {
        const resp = await getQuestionOptions(data.id);
        const respData = get(resp, 'data.results', []);
        if (respData?.length) questionsData[i].answers = respData;
      }));
      let surveyFound;
      if (employmentData?.id || initialData?.id) {
        const resp = await getWorkerSurveys(employmentData?.worker?.id, companyId);
        const resData = get(resp, 'data.results', []);
        surveyFound = resData.find((f) => layoffForms.find((val) => f.survey.id === val.id));
        setWorkerSurveyData(surveyFound);
        if (!surveyFound) setIsLoadingForm(false);
      } else setIsLoadingForm(false);
      fetchSurveyAnswers(questionsData, surveyFound?.id);
    }
  };

  useEffect(() => {
    if (initialData?.id) fetchWorkerDocuments();
    fetchDocumentTypes();
    fetchForms();
  }, [initialData]);

  useEffect(() => {
    if (employmentData?.id) fetchQuestionsAndAnswers();
  }, [layoffForms]);

  useEffect(() => {
    const newReasons = reasons.filter((r) => r.id !== 'all');
    setReasonOptions(newReasons);
  }, [reasons]);

  const handleDocumentUpload = async (layoffId) => {
    // deleting documents
    await Promise.all(initialDocuments.map(async (document) => {
      const documentFound = selectedDocuments?.find((d) => d.id === document.id);
      if (!documentFound) {
        await deleteDocument(companyId, document.id);
      }
    }));
    // uploading documents
    await Promise.all(selectedDocuments?.map(async (document) => {
      if (document?.file?.name) {
        const documentName = document?.file?.name?.split('.').slice(0, -1).join('.');
        const formData = new FormData();
        formData.append('worker', employmentData?.worker);
        formData.append('file', document.file);
        formData.append('user', currentUser?.id);
        formData.append('document_type', document?.document_type?.id);
        if (document?.valid_until) formData.append('valid_until', moment(document?.valid_until).format(defaultISODateFormat));
        formData.append('name', documentName);
        formData.append('employment', layoffId);
        await uploadDocument(formData);
      }
      // editing documents
      if (!document?.file?.name) {
        const initialDocument = initialDocuments?.find((d) => d.id === document.id);
        if (document?.document_type?.id !== initialDocument?.document_type?.id) {
          const editedDocument = { document_type: document.document_type.id };
          await editDocument(editedDocument, document.id, companyId);
        }
        if (document?.valid_until !== initialDocument?.valid_until) {
          const editedDocument = { valid_until: document?.valid_until ? moment(document.valid_until).format(defaultISODateFormat) : null };
          await editDocument(editedDocument, document.id, companyId);
        }
      }
    }));
  };

  const handleCloseModal = () => {
    handleClose();
    setWorkerSurveyData(null);
    setShowFormModal(false);
    setSelectedDocuments(null);
    setWorkerSurveyId(null);
    setEmploymentData({});
    setSurveyData(null);
    setInitialDocuments([]);
    setDocumentsValid(true);
    setShowAddReason(false);
    setDocumentsUploaded(false);
  };

  const handleSave = async () => {
    if (selectedDocuments?.length) {
      let documentTypesSelected = true;
      selectedDocuments.forEach((d) => {
        if (!d.document_type) {
          documentTypesSelected = false;
        }
      });
      setDocumentsValid(documentTypesSelected);
      if (!documentTypesSelected) {
        return null;
      }
    }
    if (showAddReasons) {
      return null;
    }

    const data = employmentData;

    if (data?.worker) data.worker = data?.worker?.id;
    if (data?.termination_reason) data.termination_reason = data?.termination_reason?.id;
    if (data?.start_working) data.start_working = moment(data?.start_working).format(defaultISODateFormat);
    if (data?.end_working) data.end_working = moment(data?.end_working).format(defaultISODateFormat);
    if (data?.probationary_period_end) data.probationary_period_end = moment(data?.probationary_period_end).format(defaultISODateFormat);

    let layoffId;

    if (employmentData?.id) {
      const res = await editEmployment(data, employmentData?.id, companyId);
      layoffId = res?.data?.id;
    } else {
      const res = await addEmployment(data);
      layoffId = res?.data?.id;
    }
    if ((selectedDocuments?.length || initialDocuments?.length) && !documentsUploaded) {
      await handleDocumentUpload(layoffId);
    }
    setEmploymentData({});
    handleAddLayoff();
    handleCloseModal();
  };

  const handleSelectFile = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files).map((file) => ({
      file,
      name: file.name.slice(0, file.name.lastIndexOf('.')),
    }));
    const currentFiles = [];
    if (selectedDocuments?.length) {
      currentFiles.push(...selectedDocuments);
    }
    currentFiles.push(...newFiles);

    setSelectedDocuments(currentFiles);
  };

  const handlePreviewForm = () => {
    const currentUrl = window.origin;
    const url = `${currentUrl}/surveys/worker-survey/${workerSurveyData?.id}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleRemoveDocument = (row) => {
    const documents = [...selectedDocuments];
    const newDocuments = documents.filter((d) => d.name !== row.name);
    setSelectedDocuments(newDocuments);
  };

  const handleDocumentChange = (key, value, row) => {
    const documents = [...selectedDocuments];
    const documentIndex = selectedDocuments?.findIndex((d) => d.name === row.original.name || d?.id === row?.original?.id);
    if (documentIndex !== -1) {
      documents[documentIndex][key] = value;
      setSelectedDocuments(documents);
    }
  };

  const handleSaveAndFillButton = async () => {
    if (selectedDocuments?.length) {
      let documentTypesSelected = true;
      selectedDocuments.forEach((d) => {
        if (!d.document_type) {
          documentTypesSelected = false;
        }
      });
      setDocumentsValid(documentTypesSelected);
      if (!documentTypesSelected) {
        return null;
      }
    }
    setShowFormModal(true);
  };

  const closeFormModal = () => {
    setShowFormModal(false);
    fetchQuestionsAndAnswers();
  };

  const handleDownloadDocument = (row) => {
    const documentUrl = row;
    const link = document.createElement('a');
    link.href = documentUrl;
    link.target = '_blank';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.human_resources.employments.table_column_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row && row.value ? row.value : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.human_resources.employments.table_column_document_valid_until')}</span>,
      accessor: 'valid_until',
      Cell: (row) => <DatePicker
        className="datepicker_input"
        placeholderText={t('page_content.human_resources.employments.document_placeholder_valid_until')}
        dateFormat="dd.MM.yyyy"
        selected={row?.value ? new Date(row?.value) : null}
        onChange={(date) => handleDocumentChange('valid_until', date, row)}
        locale={getLocale(t)}
        popperProps={{ strategy: 'fixed' }}
      />,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.human_resources.employments.table_column_document_type')} *</span>,
      accessor: 'document_type',
      Cell: (row) => <Select
        options={documentTypes}
        getOptionLabel={(reason) => reason.name}
        getOptionValue={(reason) => reason.id}
        menuPosition="fixed"
        placeholder={t('page_content.human_resources.employments.document_placeholder')}
        onChange={(value) => handleDocumentChange('document_type', value, row)}
        value={row.value || ''}
        styles={selectModalStyles}
      />,
      style: { cursor: 'default' },
    },
    {
      Header: () => (<span>{t('page_content.human_resources.employments.button_download')}</span>),
      accessor: 'file',
      width: 100,
      sortable: false,
      Cell: (row) => (row?.value ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button style={{ width: 55, padding: '5px 8px' }} onClick={() => handleDownloadDocument(row.value)}>
              <IconCloudDownload height="14px" width="14px" color="#555" />
            </Button>
          </div>
      ) : ('-')),
      style: { cursor: 'default' },
    },
  ];

  const fullSelectModalStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      width: '100%',
      minHeight: '34px',
      height: '36px',
      padding: 0,
      fontSize: '13px',
      color: '#555',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '34px',
      padding: '0px 0px 0px 5px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0px 3px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      paddingRight: 10,
      color: 'black',
      svg: {
        width: '15px',
        height: '15px',
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      borderRadius: 0,
      zIndex: 2,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '13px',
      fontWeight: 500,
      padding: '6px 12px',
    }),
  };

  const typeOfEmploymentOptions = [
    { id: 'F', name: t('page_content.human_resources.employments.typeOfEmploymentOptions.fixed_job') },
    { id: 'P', name: t('page_content.human_resources.employments.typeOfEmploymentOptions.permanent_job') },
  ];

  const workingPeriodOptions = [
    { id: 1, name: t('page_content.human_resources.employments.workingPeriodOptions.tryout_1m') },
    { id: 3, name: t('page_content.human_resources.employments.workingPeriodOptions.tryout_3m') },
    { id: 6, name: t('page_content.human_resources.employments.workingPeriodOptions.tryout_6m') },
  ];

  return (
    <Modal
      title={showAddReasons ? t('page_content.human_resources.employments.modal_title_reason') : t('page_content.human_resources.employments.modal_title_layoff')}
      disableSave={
        showFormModal ||
        showAddReasons ||
        !employmentData?.worker ||
        !employmentData?.start_working ||
        !employmentData?.employment_type ||
        (Boolean(employmentData?.fixed_term_length) && Boolean(!employmentData?.probationary_period_end))
      }
      isOpen={showModal}
      size={modalSizes.fullPlus}
      handleClose={handleCloseModal}
      handleSave={handleSave}
      error={!documentsValid && t('page_content.human_resources.employments.select_document_types_error')}
      enableSaveOnEnter={false}
    >
      <div className="layoff_modal_container">
        {
          !showFormModal ? (
            <div>
              { initialData?.id && <AddReasonModalPage companyId={companyId} showAddReasons={showAddReasons} setShowAddReason={setShowAddReason} fetchReasons={fetchReasons} /> }
              {
                !showAddReasons && (
                  <div>
                    <div className="modal_row">
                      <div className="left_text">{t('page_content.human_resources.employments.label_worker')} *</div>
                      <div className="right_select">
                        <Select
                          options={workers}
                          getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                          getOptionValue={(option) => option.id}
                          menuPosition="fixed"
                          isSearchable
                          placeholder={t('page_content.human_resources.employments.placeholder_worker')}
                          onChange={(value) => handleChange(value, 'worker')}
                          value={workers?.find((w) => w.id === employmentData?.worker?.id) || ''}
                          styles={selectModalStyles}
                        />
                      </div>
                    </div>
                    {
                      initialData?.id &&
                      <div className="modal_row">
                        <div className="left_text">{t('page_content.human_resources.employments.label_reason')}</div>
                        <div className="right_select">
                          <Select
                            options={reasonOptions}
                            getOptionLabel={(reason) => reason.name}
                            getOptionValue={(reason) => reason.id}
                            menuPosition="fixed"
                            isClearable
                            placeholder={t('page_content.human_resources.employments.placeholder_reason')}
                            onChange={(value) => handleChange(value, 'termination_reason')}
                            value={(reasonOptions && reasonOptions?.find((r) => r.id === employmentData?.termination_reason?.id)) || ''}
                            styles={selectModalStyles}
                          />
                        </div>
                      </div>
                    }

                    <div className="modal_row">
                      <div className="left_text">{t('page_content.human_resources.employments.table_column_working_start')} *</div>
                      <DatePicker
                        className="datepicker_input"
                        placeholderText={t('page_content.human_resources.employments.table_column_working_start')}
                        dateFormat="dd.MM.yyyy"
                        selected={employmentData?.start_working ? new Date(employmentData?.start_working) : null}
                        selectsStart
                        onChange={(date) => handleChange(date, 'start_working')}
                        locale={getLocale(t)}
                      />
                    </div>

                    <div className="modal_row">
                      <div className="left_text">{t('page_content.human_resources.employments.label_employment_type')} *</div>
                      <div className="double_select">
                        <Select
                          options={typeOfEmploymentOptions}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          menuPosition="fixed"
                          isDisabled={!employmentData?.start_working}
                          placeholder={t('page_content.human_resources.employments.placeholder_employment_type')}
                          onChange={(value) => handleChange(value.id, 'employment_type')}
                          value={typeOfEmploymentOptions?.find((eT) => eT.id === employmentData?.employment_type) || ''}
                          styles={fullSelectModalStyles}
                        />
                        <Select
                          options={workingPeriodOptions}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          menuPosition="fixed"
                          isClearable
                          isDisabled={!employmentData?.employment_type}
                          placeholder={t('page_content.human_resources.employments.placeholder_employment_tryout')}
                          onChange={(value) => handleChange(value?.id || 0, 'fixed_term_length')}
                          value={workingPeriodOptions?.find((wP) => wP.id === employmentData?.fixed_term_length) || ''}
                          styles={fullSelectModalStyles}
                        />
                        <DatePicker
                          className="datepicker_input"
                          placeholderText={
                            !employmentData?.fixed_term_length ?
                              t('page_content.human_resources.employments.placeholder_employment_tryout_date')
                              : `${t('page_content.human_resources.employments.placeholder_employment_tryout_date')}*`
                          }
                          dateFormat="dd.MM.yyyy"
                          disabled={!employmentData?.fixed_term_length}
                          selected={employmentData?.probationary_period_end ? new Date(employmentData?.probationary_period_end) : null}
                          onChange={(date) => handleChange(date, 'probationary_period_end')}
                          locale={getLocale(t)}
                        />
                      </div>
                    </div>

                    {
                      initialData?.id &&
                      <div className="modal_row">
                        <div className="left_text">{t('page_content.human_resources.employments.label_layoff_date')}</div>
                        <DatePicker
                          className="datepicker_input"
                          placeholderText={t('page_content.orders.end_date_placeholder')}
                          dateFormat="dd.MM.yyyy"
                          selected={employmentData?.end_working ? new Date(employmentData?.end_working) : null}
                          selectsStart
                          onChange={(date) => handleChange(date, 'end_working')}
                          locale={getLocale(t)}
                        />
                      </div>
                    }

                    <div className="modal_row">
                      <div className="left_text">{t('page_content.human_resources.employments.label_comment')}</div>
                      <div className="right_textarea">
                        <textarea value={employmentData?.note} onChange={(e) => handleChange(e.target.value, 'note')} />
                      </div>
                    </div>

                    <div className="modal_row upload_footer">
                      <div className="left_text">{t('page_content.human_resources.employments.label_upload_document')}</div>
                      <div className="right_textarea">
                        <div className="upload_footer_content">
                          <input type="file" multiple onChange={handleSelectFile} />
                          {isLoadingDocuments ?
                            <ContentLoader text="" rowVariant /> : selectedDocuments?.length ?
                              <Table
                                style={{ userSelect: 'text' }}
                                columns={tableColumns}
                                data={selectedDocuments || []}
                                noDataText=" "
                                showPagination={false}
                                enableDelete
                                onDelete={(original) => handleRemoveDocument(original)}
                                minRows={0}
                                sortable={false}
                                defaultPageSize={30}
                                selectedRow={null}
                              /> : null}
                        </div>
                      </div>
                    </div>

                    {
                      initialData?.id &&
                      <div className="modal_row">
                        <div className="left_text">{t('page_content.human_resources.employments.label_layoff_form')}</div>
                        <div className="right_select">
                          {
                            isLoadingForm ? <ContentLoader rowVariant text="" /> :
                              error ? <span style={{ color: 'red', fontSize: 14, fontWeight: 600 }}>{error}</span> :
                              <div>
                                <div>
                                  <Button disabled={workerSurveyData?.is_finished || !employmentData?.worker || !employmentData?.end_working} onClick={handleSaveAndFillButton}>
                                    {t('page_content.human_resources.employments.button_fill_form')}
                                  </Button>
                                  {
                                    workerSurveyData?.is_finished &&
                                      <span style={{ marginLeft: 20 }}>
                                        <Button onClick={handlePreviewForm}>{t('page_content.human_resources.employments.preview_form_button')}</Button>
                                        <span className="form_finished_message">{t('page_content.human_resources.employments.form_filled_message')}</span>
                                      </span>
                                  }
                                </div>
                              </div>
                          }
                        </div>
                      </div>
                    }

                    {employmentData?.worker && initialData?.id && <EmploymentAssignedEquipmentModalTable companyId={companyId} workerId={employmentData?.worker?.id} />}
                  </div>
                )
              }
            </div>
          ) : (
            <div>
              <Button type="dark-blue" onClick={() => setShowFormModal(false)}>{t('page_content.human_resources.employments.button_back')}</Button>
              <FormLayoffModal
                currentUser={currentUser}
                companyId={companyId}
                formData={layoffForms[0]}
                surveyData={surveyData}
                workerSurveyId={workerSurveyId}
                closeFormModal={closeFormModal}
                workerId={employmentData?.worker?.id}
                handleNewWorkerSurveyId={handleNewWorkerSurveyId}
              />
            </div>
          )
        }
      </div>
    </Modal>
  );
};

AddEmploymentModal.propTypes = {
  workers: PropTypes.array,
  reasons: PropTypes.array,
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
  t: PropTypes.func.isRequired,
  fetchReasons: PropTypes.func,
  currentUser: PropTypes.object,
  initialData: PropTypes.object,
  handleAddLayoff: PropTypes.func,
  companyId: PropTypes.number.isRequired,
  layoffCategoryCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    layoffCategoryCode: get(state, 'app.location.config.layoff_category_code', ''),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(AddEmploymentModal)));
