/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Table, Button, TableButtons } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { IconEdit } from 'shared/Icons';
import '../../../styles.scss';

import {
  getDataDefinitions,
  addPersonnelDataRating,
  getPersonnelDataRatings,
  getPaginatedDataDefinitions,
  refreshWorkerPersonnelValues,
} from '../../../actions';

const PersonnelDataRating = ({ selectedWorker, currentUser, companyId, t }) => {
  const [personnelData, setPersonnelData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });

  const currentYear = moment().year();
  const [filters, setFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'definition.order',
    year: null,
  });

  const fetchPersonnelDataRatings = async () => {
    setPersonnelData((prevState) => ({ ...prevState, isLoading: true }));

    const sort = filters?.selectedAscDesc === 'desc' ? '-' : '';
    let sortField = filters?.selectedSort;

    if (filters?.selectedSort === 'definition.order') sortField = ''; // other api field sort *below*
    if (filters?.selectedSort === 'definition.name') sortField = ''; // other api field sort *below*
    if (filters?.selectedSort === 'updated_at' && !filters?.year) sortField = 'updated_at'; // local sort *below*

    let apiFilters = `&limit=999&order_by=${sort}${sortField}&worker=${selectedWorker.id}`;

    if (filters?.year) apiFilters += `&year=${filters.year}&is_active=false`;
    else apiFilters += `&year=${currentYear}&is_active=true`;

    await getPersonnelDataRatings(companyId, apiFilters)
      .then(async (res) => {
        let data = get(res, 'data.results', []);

        if (data?.length > 0 && !filters?.year) {
          sortField = '';
          if (filters?.selectedSort === 'definition.order') sortField = 'order';
          if (filters?.selectedSort === 'definition.name') sortField = 'name';

          const apiFilters1 = `&limit=999&is_active=true&order_by=${sort}${sortField}`;
          const dataDefinitions = get(await getDataDefinitions(companyId, apiFilters1), 'data.results', []);

          data = dataDefinitions?.map((item) => {
            const found = data?.find((i) => i.definition.id === item.id);
            if (found) {
              return {
                ...found,
                definition: item,
                isEditing: false,
                prevValue: found?.value,
                prevScore: found?.score,
              };
            }
            return {
              definition: item,
              isEditing: false,
              prevValue: null,
              prevScore: null,
              value: null,
              score: null,
              year: currentYear,
            };
          });
        }

        if (filters?.selectedSort === 'updated_at') {
          if (filters?.selectedAscDesc === 'desc') data?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
          else data?.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
        }

        setPersonnelData({
          data,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setPersonnelData((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  useEffect(() => {
    fetchPersonnelDataRatings();
    if (filters?.selectedSort === 'definition.order' && !!filters?.year) setFilters({ ...filters, selectedSort: 'updated_at' });
    if (filters?.selectedSort === 'definition.name' && !!filters?.year) setFilters({ ...filters, selectedSort: 'updated_at' });
  }, [filters]);

  const handleChangeScoring = (definition_id, value, score) => {
    setPersonnelData((prevState) => ({
      ...prevState,
      data: prevState.data.map((i) => {
        if (i?.definition?.id === definition_id) {
          return {
            ...i,
            value,
            score,
          };
        }
        return i;
      }),
    }));
  };

  const handleSubmitAll = () => {
    if (!selectedWorker?.id || !currentUser?.id || !personnelData?.data || Object.keys(personnelData?.data).length === 0) return;

    const data = personnelData?.data?.filter((item) => item.isEditing && (item.value !== item.prevValue || item.score !== item.prevScore))
      .map((item) => ({
        definition: item?.definition?.id,
        value: item?.value,
        score: item?.score,
        worker: selectedWorker.id,
        user: currentUser.id,
        year: currentYear,
      }));

    if (data?.some((item) => !item?.definition || (!item?.value && item?.value !== '') || (!item?.score && item?.score !== 0) || !item?.worker || !item?.user || !item?.year)) return;

    setPersonnelData((prevState) => ({ ...prevState, isLoading: true }));

    Promise.all(data?.map((item) => addPersonnelDataRating(companyId, item)))
      .then(() => { fetchPersonnelDataRatings(); })
      .catch(() => { setPersonnelData((prevState) => ({ ...prevState, isLoading: false })); });
  };

  const handleEditValue = (row) => {
    setPersonnelData((prevState) => ({
      ...prevState,
      data: prevState.data.map((i) => {
        if (i?.definition?.id === row?.definition?.id) {
          return {
            ...i,
            isEditing: true,
            prevValue: i?.prevValue ? i?.prevValue : i?.value,
            prevScore: i?.prevScore ? i?.prevScore : i?.score,
          };
        }
        return i;
      }),
    }));
  };

  const handleRemoveEdit = (row) => {
    setPersonnelData((prevState) => ({
      ...prevState,
      data: prevState.data.map((i) => {
        if (i?.definition?.id === row?.definition?.id) {
          return {
            ...i,
            value: i?.prevValue,
            score: i?.prevScore,
            prevValue: null,
            prevScore: null,
            isEditing: false,
          };
        }
        return i;
      }),
    }));
  };

  const handleYearChange = (value) => {
    setFilters({ ...filters, year: value });
  };

  const totalScore = personnelData?.data?.reduce((total, item) => total + (item?.score ? item.score : 0), 0);

  const columns = [
    {
      Header: () => <span>#</span>,
      accessor: 'definition.order',
      minWidth: 50,
      show: !filters?.year,
      sortable: !filters?.year,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_definition')}</span>,
      accessor: 'definition.name',
      minWidth: 300,
      sortable: !filters?.year,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_updated_at')}</span>,
      accessor: 'updated_at',
      Cell: (row) => (row?.value ? moment(row.value)?.format(defaultDateFormat) : '-'),
      width: 150,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_value')}</span>,
      Footer: () => personnelData?.data?.filter((item) => item?.isEditing && item?.prevValue !== item?.value).length > 0 && (
          <div style={{ width: '100%', display: 'flex' }}>
            <Button style={{ marginLeft: 'auto' }} size="small" type="add" onClick={handleSubmitAll}>
              {t('page_content.human_resources.rating.personnel_data_tab.button_placeholder_submit_all')}
            </Button>
          </div>),
      Cell: (row) => {
        const selectedOption = row?.original;
        if (
          !filters?.year &&
          selectedOption?.isEditing &&
          !selectedOption?.definition?.is_predefined &&
          Object.keys(selectedOption?.definition?.scoring?.values).length > 0
        ) {
          if (selectedOption?.definition?.scoring?.type === 'map') {
            const selectOptions = Object.entries(selectedOption?.definition?.scoring?.values)?.map(([key, value]) => ({ label: key, value }));
            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  <div style={selectedOption?.id ? { width: '95%' } : { width: '100%' }}>
                    <Select
                      options={selectOptions || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      isClearable
                      isDisabled={personnelData?.isLoading}
                      placeholder={t('page_content.human_resources.rating.personnel_data_tab.select_placeholder_value')}
                      menuPosition="fixed"
                      onChange={(e) => handleChangeScoring(selectedOption?.definition?.id, e?.label || '', e?.value || 0)}
                      value={selectOptions?.find((val) => (val.value === selectedOption?.score && val.label === selectedOption?.value) || null)}
                      styles={selectModalStyles}
                    />
                  </div>
              {
                selectedOption?.definition?.id && (
                  <div style={{ cursor: 'pointer' }} onClick={() => handleRemoveEdit(selectedOption)}>
                    <Button style={{ padding: '4px 8px' }}>{t('page_content.human_resources.rating.personnel_data_tab.button_placeholder_cancel')}</Button>
                  </div>
                )
              }
            </div>
            );
          }
          if (selectedOption?.definition?.scoring?.type === 'range') {
            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  <div style={selectedOption?.id ? { width: '95%' } : { width: '100%' }}>
                    <input
                      className="range_input"
                      type="text"
                      disabled={personnelData?.isLoading}
                      value={selectedOption?.value || ''}
                      onChange={(e) => handleChangeScoring(selectedOption?.definition?.id, e.target.value || '', 0)}
                      placeholder={t('page_content.human_resources.rating.personnel_data_tab.input_placeholder_score')}
                    />
                  </div>
                  {
                    selectedOption?.id && (
                      <div style={{ cursor: 'pointer' }} onClick={() => handleRemoveEdit(row.original)}>
                        <Button style={{ padding: '4px 8px' }}>{t('page_content.human_resources.rating.personnel_data_tab.button_placeholder_cancel')}</Button>
                      </div>
                    )
                  }
                </div>
            );
          }
        }
        return (
            <div style={{ display: 'flex', gap: 10, padding: 5 }}>
              {Object.keys(selectedOption?.humanized_score ?? {}).length > 0 ? `${selectedOption?.humanized_score?.years ? `${selectedOption.humanized_score.years}${t('page_content.human_resources.workers.column_seniority_in_y')}` : ''} ${selectedOption?.humanized_score?.months ? `${selectedOption.humanized_score.months}${t('page_content.human_resources.workers.column_seniority_in_m')}` : ''} ${selectedOption?.humanized_score?.days ? `${selectedOption.humanized_score.days}${t('page_content.human_resources.workers.column_seniority_in_d')}` : ''}` : selectedOption?.value || '-'}
              {
                !filters?.year && selectedOption?.year === Number(currentYear) && !selectedOption?.definition?.is_predefined &&
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEditValue(selectedOption)}
                  >
                    <IconEdit height="14px" width="14px" />
                  </div>
              }
            </div>
        );
      },
      minWidth: 300,
      sortable: false,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_score')}</span>,
      Footer: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_footer_total')}: {totalScore}</span>,
      show: !filters?.year,
      accessor: 'score',
      width: 125,
      sortable: false,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_score')}</span>,
      show: filters?.year,
      accessor: 'score',
      width: 125,
      sortable: false,
      style: {
        cursor: 'default',
      },
    },
  ];

  return (
    <div className="ratings__screen">
      <div className="ratings__screen__filters">
        <div className="double_box">
          <span style={{ fontSize: '13px', color: '#555', fontWeight: '700' }}>{t('page_content.human_resources.rating.personnel_data_tab.filter_by_year')}:</span>
          <DatePicker
            selected={filters?.year ? moment(filters?.year, 'YYYY').toDate() : null}
            onChange={(date) => handleYearChange(date ? moment(date).format('YYYY') : null)}
            placeholderText="----"
            showYearPicker
            dateFormat="yyyy"
            isClearable
            maxDate={moment().toDate()}
          />
        </div>
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={columns}
        data={personnelData?.data || []}
        defaultPageSize={100}
        loading={personnelData.isLoading}
        minRows={0}
        defaultSorted={[{ id: filters?.selectedSort, desc: filters?.selectedAscDesc === 'desc' }]}
        onSortedChange={(newSorted) => {
          setFilters({
            ...filters,
            selectedSort: newSorted[0].id,
            selectedAscDesc: newSorted[0].desc ? 'desc' : 'asc',
          });
        }}
        selectedRow={null}
      />
      {/* <TableButtons
        previous={additionalData?.previous}
        next={additionalData?.next}
        fetchFunction={fetchPaginatedDataDefinitions}
        count={additionalData?.count}
      /> */}
    </div>
  );
};

PersonnelDataRating.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectedWorker: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(PersonnelDataRating));
