import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, AssetTypePicker, ContentLoader, ConfirmationModal } from 'shared';
import Select from 'react-select';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { modalSizes } from 'shared/constants';
import { IconRemove } from 'shared/Icons';
import { sortBy } from 'lodash';

import '../style.scss';
import LimitedTextarea from 'shared/LimitedTextarea/index';

class ProductionRecordsAddOrEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: props && props.editRecord ? props.editRecord :
        {
          entries: [],
          signatory_1_resource: '',
          signatory_2_resource: '',
          signatory_3_resource: '',
          signatory_4_resource: '',
          document_label: '',
          title: '',
          frequency: '',
          frequency_num: '',
          signatory_1_title: '',
          signatory_2_title: '',
          signatory_3_title: '',
          signatory_4_title: '',
          asset_types: [],
          assets: [],
          is_active: false,
          record_type: '',
          showConfirmationDialog: false,
          deleteData: {},
        },
      modalError: false,
    };
    this.entryListRef = React.createRef();
  }

  scrollToNewEntry = () => {
    const newEntry = this.entryListRef.current.lastElementChild;
    newEntry.scrollIntoView({ behavior: 'smooth' });
  };

  onSaveProductionRecordsModal = (signatoryOptions, close) => {
    const { record } = this.state;
    const { onSaveProductionRecordsModal } = this.props;

    let modalError = false;

    // Check for general required fields
    if (!record.title || !record.frequency || (record.frequency !== 'on_demand' && !record.frequency_num) || !record.record_type) {
      modalError = true;
    }

    // Check each signatory for the required conditions
    for (const signatory of signatoryOptions) {
      if (record[signatory.stateTitle] && record[signatory.stateTitle].length) {
        if (!record[signatory.stateResource] || !record[signatory.stateRole]) {
          modalError = true;
          break;
        }
      }
    }

    if (modalError) {
      this.setState({
        modalError: true,
      });
    } else {
      const isEdit = !!(record && record.id);
      onSaveProductionRecordsModal(isEdit ? this.modifyDataStructure(record) : this.modifyRecordAssetTypes(record), close);
      if (close) this.clearState();
    }
  }

  onCloseProductionRecordsModal = () => {
    const { onCloseProductionRecordsModal } = this.props;

    this.clearState();
    onCloseProductionRecordsModal();
  }

  onResourceChange = (key, value) => {
    const { record } = this.state;
    if (key === 'frequency' && value === 'on_demand') {
      this.setState({
        record: { ...record, [key]: value, frequency_num: 0 },
      });
    } else if (key === 'title') {
      value = value.slice(0, 300);
      this.setState({
        record: { ...record, [key]: value },
      });
    } else {
      this.setState({
        record: { ...record, [key]: value },
      });
    }
  }

  clearState = () => {
    this.setState({
      record: {},
      modalError: false,
    });
  }

  addRow = () => {
    const { record } = this.state;
    // it is -1 because sorting starts from 0
    let maxSortOrder = -1;

    if (record?.entries?.length) {
      maxSortOrder = Math.max(...record?.entries?.map((o) => o.sort_order));
    }
    const newRow = { title: '', entry_type: 'bool', required: false, description: '', sort_order: maxSortOrder + 1 };
    this.setState((prevState) => ({
      record: {
        ...prevState.record,
        entries: [...prevState.record.entries, newRow],
      },
    }), () => this.scrollToNewEntry());
  }

  handleRecordEntryChange = (index, key, value) => {
    this.setState((prevState) => ({
      record: {
        ...prevState.record,
        entries: prevState.record.entries.map((row, i) => {
          // eslint-disable-next-line eqeqeq
          if (index === i) {
            return { ...row, [key]: value };
          }
          return row;
        }),
      },
    }));
  }

  handleShowConfirmationDialog = (row, index, signatoryOptions) => {
    const data = row;
    data.index = index;
    this.setState({ deleteData: data, showConfirmationDialog: true, signatoryOptionsCopy: [...signatoryOptions] });
  }

  handleDeleteRecordEntry = () => {
    const { record, deleteData, signatoryOptionsCopy } = this.state;
    const { handleDeleteRecordEntry } = this.props;

    const entryId = deleteData.id || null;
    const productionRecordId = deleteData.production_record || null;

    if (entryId && productionRecordId) {
      handleDeleteRecordEntry(entryId, productionRecordId)
        .then((res) => {
          if (res === true) {
            const updatedRecords = [...record.entries];
            updatedRecords.splice(deleteData.index, 1);
            updatedRecords.forEach((entry, i) => {
              entry.sort_order = i;
            });
            this.setState((prevState) => ({
              record: {
                ...prevState.record,
                entries: [...updatedRecords],
              },
            }), () => this.onSaveProductionRecordsModal(signatoryOptionsCopy, false));
          }
        });
    } else if (!(entryId && productionRecordId) || deleteData.index) {
      this.setState((prevState) => {
        const updatedRecords = [...prevState.record.entries];
        if (deleteData.index >= 0 && deleteData.index < updatedRecords.length) {
          updatedRecords.splice(deleteData.index, 1);
        }
        return {
          record: {
            ...prevState.record,
            entries: updatedRecords,
          },
        };
      });
    }
    this.setState({ showConfirmationDialog: false });
  }

  handleShiftRecordEntry = (index, direction) => {
    this.setState((prevState) => {
      const updatedRecords = JSON.parse(JSON.stringify(prevState.record.entries));

      if (index >= 0 && index < updatedRecords.length) {
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex >= 0 && newIndex < updatedRecords.length) {
          [updatedRecords[index], updatedRecords[newIndex]] = [
            updatedRecords[newIndex],
            updatedRecords[index],
          ];
        }

        updatedRecords.forEach((entry, i) => {
          if (!('sort_order' in entry)) {
            entry.sort_order = i;
          } else {
            entry.sort_order = updatedRecords.indexOf(entry);
          }
        });
      }

      return {
        record: {
          ...prevState.record,
          entries: updatedRecords,
        },
      };
    });
  };

  modifyRecordAssetTypes = (obj) => {
    const modifyedRecord = { ...obj };
    const modifiedArray = modifyedRecord.asset_types.map((item) => item.id);
    modifyedRecord.asset_types = modifiedArray;
    return modifyedRecord;
  }

  modifyDataStructure = (obj) => {
    const modifyedRecord = { ...obj };

    if (modifyedRecord && modifyedRecord.signatory_1_resource && typeof modifyedRecord.signatory_1_resource === 'object') {
      modifyedRecord.signatory_1_resource = modifyedRecord.signatory_1_resource.id;
    }

    if (modifyedRecord && modifyedRecord.signatory_2_resource && typeof modifyedRecord.signatory_2_resource === 'object') {
      modifyedRecord.signatory_2_resource = modifyedRecord.signatory_2_resource.id;
    }

    if (modifyedRecord && modifyedRecord.signatory_3_resource && typeof modifyedRecord.signatory_3_resource === 'object') {
      modifyedRecord.signatory_3_resource = modifyedRecord.signatory_3_resource.id;
    }

    if (modifyedRecord && modifyedRecord.signatory_4_resource && typeof modifyedRecord.signatory_4_resource === 'object') {
      modifyedRecord.signatory_4_resource = modifyedRecord.signatory_4_resource.id;
    }

    if (modifyedRecord && modifyedRecord.signatory_1_role && typeof modifyedRecord.signatory_1_role === 'object') {
      modifyedRecord.signatory_1_role = modifyedRecord.signatory_1_role.id;
    }

    if (modifyedRecord && modifyedRecord.signatory_2_role && typeof modifyedRecord.signatory_2_role === 'object') {
      modifyedRecord.signatory_2_role = modifyedRecord.signatory_2_role.id;
    }

    if (modifyedRecord && modifyedRecord.signatory_3_role && typeof modifyedRecord.signatory_3_role === 'object') {
      modifyedRecord.signatory_3_role = modifyedRecord.signatory_3_role.id;
    }

    if (modifyedRecord && modifyedRecord.signatory_4_role && typeof modifyedRecord.signatory_4_role === 'object') {
      modifyedRecord.signatory_4_role = modifyedRecord.signatory_4_role.id;
    }

    if (modifyedRecord && modifyedRecord.folder && typeof modifyedRecord.folder === 'object') {
      modifyedRecord.folder = modifyedRecord.folder.id;
    }

    return modifyedRecord;
  }

  filterRecords = (array) => {
    return array.filter((obj) => {
      return obj.code === 'records-add-entry' || obj.code === 'records-confirm-entry';
    });
  }

  render() {
    const { record, modalError } = this.state;
    const {
      isProductionRecordsModalOpen,
      companyId,
      resources,
      t,
      isLoadingRecordEntries,
      locationId,
      assets,
      roles,
      recordTypeOptions,
      listOfAllFolders,
      numberOfSignatories,
    } = this.props;

    const filteredRecords = resources ? this.filterRecords(resources) : [];

    const frequencyOptions = [
      { id: 'order', label: t('page_content.production_records.order') },
      { id: 'hourly', label: t('page_content.production_records.hourly') },
      { id: 'daily', label: t('page_content.production_records.daily') },
      { id: 'shift', label: t('page_content.production_records.shift') },
      { id: 'weekly', label: t('page_content.production_records.weekly') },
      { id: 'monthly', label: t('page_content.production_records.monthly') },
      { id: 'quarterly', label: t('page_content.production_records.quarterly') },
      { id: 'semiannually', label: t('page_content.production_records.semiannually') },
      { id: 'annual', label: t('page_content.production_records.annual') },
      { id: 'order_stop', label: t('page_content.production_records.order_stop') },
      { id: 'on_demand', label: t('page_content.production_records.on_demand') },
    ];

    const signatoryOptions = [
      { title: t('page_content.production_records.sig_1_title'), stateTitle: 'signatory_1_title', stateResource: 'signatory_1_resource', stateRole: 'signatory_1_role' },
      { title: t('page_content.production_records.sig_2_title'), stateTitle: 'signatory_2_title', stateResource: 'signatory_2_resource', stateRole: 'signatory_2_role' },
      { title: t('page_content.production_records.sig_3_title'), stateTitle: 'signatory_3_title', stateResource: 'signatory_3_resource', stateRole: 'signatory_3_role' },
      { title: t('page_content.production_records.sig_4_title'), stateTitle: 'signatory_4_title', stateResource: 'signatory_4_resource', stateRole: 'signatory_4_role' },
    ].slice(0, numberOfSignatories);

    const viewTypeOptions = [
      { label: t('page_content.production_records.all'), id: 'all' },
      { label: t('page_content.production_records.mes_only'), id: 'mes' },
    ];

    const resourceOptions = [
      ...filteredRecords.map((fRec) => ({
        code: fRec.code,
        company: fRec.company,
        control_level: fRec.control_level,
        id: fRec.id,
        is_active: fRec.is_active,
        name: fRec.name,
        label: fRec.code === 'records-add-entry' ? t('page_content.production_records.can_only_add_entry') : t('page_content.production_records.can_confirm_entry'),
      })),
    ];

    return (
      <Modal
        size={modalSizes.fullPlus}
        isOpen={isProductionRecordsModalOpen}
        handleSave={() => this.onSaveProductionRecordsModal(signatoryOptions, true)}
        handleClose={() => this.onCloseProductionRecordsModal()}
        title={record && record.id ? 'Edit resource' : 'Add new resource'}
        error={modalError ? t('page_content.production_records.error_msg') : ''}
        enableSaveOnEnter={false}
      >
        <div className="production_records_add_edit_modal">
          <div className="modal_row">
            <div className="left_text">{`${t('page_content.production_records.title')} * / ${t('page_content.production_records.record_type')} *`}</div>
            <div className="right_multiple">
              <input
                className="input"
                onChange={(e) => { this.onResourceChange('title', e.target.value); }}
                value={record.title || ''}
                maxLength="300"
              />
              <div className="select_wrap">
                <Select
                  options={recordTypeOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  menuPosition="fixed"
                  placeholder=""
                  onChange={(e) => { this.onResourceChange('record_type', e.value); }}
                  value={recordTypeOptions.find((o) => o.value === record.record_type)}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('page_content.production_records.table_column_description')}</div>
            <div className="right_textarea">
            <textarea
              value={record?.description || ''}
              onChange={(e) => { this.onResourceChange('description', e?.target?.value); }}
            />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('page_content.production_records.document_label')}</div>
            <div className="right_input">
            <input
              type="input"
              value={record?.document_label || ''}
              onChange={(e) => { this.onResourceChange('document_label', e?.target?.value); }}
            />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('page_content.production_records.view_type')}</div>
            <div className="right_select">
              <Select
                options={viewTypeOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                isSearchable
                isClearable
                menuPosition="fixed"
                placeholder={t('page_content.production_records.view_type')}
                onChange={(value) => this.onResourceChange('view_type', value && value.id ? value.id : '')}
                styles={selectModalStyles}
                value={(viewTypeOptions.find((p) => p.id === (record.view_type ? typeof record.view_type === 'object' ? record.view_type.id : record.view_type : ''))) || ''}
              />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('page_content.production_records.folder')}</div>
            <div className="right_select">
              <Select
                options={listOfAllFolders}
                getOptionLabel={(option) => option.folder}
                getOptionValue={(option) => option.id}
                isSearchable
                isClearable
                menuPosition="fixed"
                placeholder={t('page_content.production_records.folder')}
                onChange={(value) => this.onResourceChange('folder', value && value.id ? value.id : '')}
                styles={selectModalStyles}
                value={(listOfAllFolders.find((p) => p.id === (record.folder ? typeof record.folder === 'object' ? record.folder.id : record.folder : ''))) || ''}
              />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('page_content.production_records.asset')}</div>
            <div className="right_select">
              <Select
                options={assets}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isSearchable
                isMulti
                menuPosition="fixed"
                placeholder={t('page_content.production_records.asset_placeholder')}
                onChange={(val) => {
                  const assetId = val ? val.map((obj) => obj.id) : [];
                  this.onResourceChange('assets', assetId);
                }}
                styles={selectModalStyles}
                value={record.assets && (Array.isArray(record.assets) && record.assets.every((item) => typeof item === 'number')) ?
                  assets.filter((option) => record.assets.includes(option.id)) :
                  record.assets ?
                    assets.find((o) => o.id === record.assets) :
                    null}
              />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('page_content.production_records.asset_type')}</div>
            <div className="right_select">
              <AssetTypePicker
                isMulti
                asset_type={record.asset_types || []}
                handleChange={(val) => {
                  if (record.id) {
                    const assetId = val ? val.map((obj) => obj.id) : [];
                    this.onResourceChange('asset_types', assetId);
                  } else {
                    this.onResourceChange('asset_types', val);
                  }
                }}
                htmlId="type"
                companyId={companyId}
                locationId={locationId}
                customStyles={selectModalStyles}
              />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{`${t('page_content.production_records.frequency')} * ${t('page_content.production_records.frequency_number')} *`}</div>
            <div className="right_multiple">
              <div className="select_wrap">
                <Select
                  options={frequencyOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  menuPosition="fixed"
                  placeholder={t('page_content.production_records.none')}
                  onChange={(value) => this.onResourceChange('frequency', value.id)}
                  value={(frequencyOptions.find((p) => p.id === record.frequency)) || ''}
                  styles={selectModalStyles}
                />
              </div>

              <input
                className="input"
                onChange={(e) => { this.onResourceChange('frequency_num', e.target.value); }}
                value={record.frequency_num || ''}
                disabled={record.frequency === 'on_demand'}
              />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{`${t('page_content.production_records.table_column_is_active')}`}</div>
            <div className="right_multiple_column">
              <input
                className="input__checkbox"
                type="checkbox"
                onChange={(e) => this.onResourceChange('is_active', e.target.checked)}
                checked={record.is_active}
              />

              <div className="modal_row" style={{ margin: 0 }}>
                <div className="left_text">{t('page_content.production_records.order_required')}</div>
                <input
                  className="input__checkbox"
                  type="checkbox"
                  onChange={(e) => this.onResourceChange('order_required', e.target.checked)}
                  checked={record.order_required}
                />
              </div>

            </div>
          </div>
          {signatoryOptions.sort((a, b) => (b.sort_order || 0) - (a.sort_order || 0)).map((signatory) => (
            <div key={signatory.stateTitle} className="modal_row">
              <div className="left_text">{`${signatory.title} / ${t('page_content.production_records.role')}* / ${t('page_content.production_records.action')}*`}</div>
              <div className="right_multiple">
                <input
                  type="text"
                  className="input"
                  value={record[signatory.stateTitle]}
                  onChange={(e) => { this.onResourceChange(signatory.stateTitle, e.target.value); }}
                />

                <div className="select_wrap">
                  <Select
                    options={sortBy(roles, [(role) => role.name.toLowerCase()])}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    menuPosition="fixed"
                    isClearable
                    placeholder={t('page_content.production_records.select_role_placeholder')}
                    onChange={(value) => this.onResourceChange(signatory.stateRole, value?.id || '')}
                    value={
                      record &&
                      record[signatory.stateRole] &&
                      roles &&
                      roles.find((resOption) => resOption.id === (record[signatory.stateRole] && typeof record[signatory.stateRole] === 'object'
                        ? record[signatory.stateRole].id : record[signatory.stateRole]))
                    }
                    styles={{
                      ...selectModalStyles,
                      control: (provided, state) => ({
                        ...selectModalStyles.control(provided, state),
                        borderColor: (record[signatory.stateTitle] && record[signatory.stateTitle].length && !record[signatory.stateRole]) ? 'red' : provided.borderColor,
                        '&:hover': {
                          borderColor: (record[signatory.stateTitle] && record[signatory.stateTitle].length && !record[signatory.stateRole]) ? 'red' : provided['&:hover'].borderColor,
                        },
                      }),
                    }}
                  />
                </div>

                <div className="select_wrap">
                  <Select
                    options={resourceOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    menuPosition="fixed"
                    isClearable
                    placeholder={t('page_content.production_records.select_action_placeholder')}
                    onChange={(value) => this.onResourceChange(signatory.stateResource, value?.id || '')}
                    value={
                      record &&
                      record[signatory.stateResource] &&
                      resourceOptions &&
                      resourceOptions.find((resOption) => resOption.id === (record[signatory.stateResource] && typeof record[signatory.stateResource] === 'object'
                        ? record[signatory.stateResource].id : record[signatory.stateResource]))
                    }
                    styles={{
                      ...selectModalStyles,
                      control: (provided, state) => ({
                        ...selectModalStyles.control(provided, state),
                        borderColor: (record[signatory.stateTitle] && record[signatory.stateTitle].length && !record[signatory.stateResource]) ? 'red' : provided.borderColor,
                        '&:hover': {
                          borderColor: (record[signatory.stateTitle] && record[signatory.stateTitle].length && !record[signatory.stateResource]) ? 'red' : provided['&:hover'].borderColor,
                        },
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <hr />

        <div style={{ padding: '10px' }}>
          <Button
            type="add"
            style={{
              width: '120px',
              height: '33px',
              background: '#70B7AB',
              color: 'white',
              marginRight: '10px',
              marginBottom: '10px',
              fontSize: '12px',
              padding: '8px 18px',
            }}
            onClick={this.addRow}
          >
            {t('page_content.production_records.add_entry_button')}
          </Button>
        </div>
        {
          isLoadingRecordEntries && <ContentLoader />
        }
        {
          record && record.entries && record.entries.length && !isLoadingRecordEntries ?
            <table id="production_records_modal_table">
              <thead>
                <tr>
                  <th>
                    {t('page_content.production_records.change_order')}
                  </th>
                  <th>
                    {t('page_content.production_records.title')}
                  </th>
                  <th>
                    {t('page_content.production_records.table_column_description')}
                  </th>
                  <th>
                    {t('page_content.production_records.table_column_type')}
                  </th>
                  <th>
                    {t('page_content.production_records.table_column_instruction_title')}
                  </th>
                  <th>
                    {t('page_content.production_records.table_column_required')}
                  </th>
                  <th>
                    {t('page_content.production_records.table_column_delete')}
                  </th>
                </tr>
              </thead>

              <tbody>
                {record?.entries?.sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0)).map((row, index) => (
                  <tr
                    ref={this.entryListRef}
                    key={index}
                  >
                    <td>
                      <Button onClick={() => this.handleShiftRecordEntry(index, 'up')}>↑</Button>
                      <Button onClick={() => this.handleShiftRecordEntry(index, 'down')}>↓</Button>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={row.title}
                        onChange={(e) => this.handleRecordEntryChange(index, 'title', e.target.value)}
                      />
                    </td>

                    <td>
                      <LimitedTextarea
                        autoResize
                        limit={1000}
                        width="95%"
                        value={row.description || ''}
                        onChange={(value) => this.handleRecordEntryChange(index, 'description', value)}
                      />
                    </td>

                    <td>
                      <select style={{ width: '100%' }} onChange={(e) => this.handleRecordEntryChange(index, 'entry_type', e.target.value)} value={row.entry_type || 'bool'}>
                        <option value="bool">{t('page_content.production_records.boolean')}</option>
                        <option value="text">{t('page_content.production_records.text')}</option>
                        <option value="numeric">{t('page_content.production_records.numeric')}</option>
                        <option value="instruction">{t('page_content.production_records.instruction')}</option>
                        <option value="decimal">{t('page_content.production_records.decimal')}</option>
                        {record?.view_type && (
                          (typeof record.view_type === 'object' && record.view_type.id === 'mes') ||
                          (typeof record.view_type === 'string' && record.view_type === 'mes')
                        ) &&
                          <option value="json">JSON</option>}
                        {record?.view_type && (
                          (typeof record.view_type === 'object' && record.view_type.id === 'mes') ||
                          (typeof record.view_type === 'string' && record.view_type === 'mes')
                        ) &&
                          <option value="time">{t('page_content.production_records.time')}</option>}
                      </select>
                    </td>

                    <td>
                      {
                        row.entry_type === 'instruction' ?
                          <input
                            type="text"
                            value={row.instruction_title}
                            onChange={(e) => this.handleRecordEntryChange(index, 'instruction_title', e.target.value)}
                          /> : '-'
                      }
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        checked={row.required}
                        onChange={(e) => this.handleRecordEntryChange(index, 'required', e.target.checked)}
                      />
                    </td>

                    <td>
                      <Button type="delete" onClick={() => this.handleShowConfirmationDialog(row, index, signatoryOptions)}><IconRemove width="14px" height="14px" /></Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> : ''
        }
        <ConfirmationModal
          itemName={this.state.deleteData?.title}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={() => this.handleDeleteRecordEntry()}
          type="warning"
        />
      </Modal>
    );
  }
}

ProductionRecordsAddOrEditModal.propTypes = {
  isProductionRecordsModalOpen: PropTypes.bool.isRequired,
  onSaveProductionRecordsModal: PropTypes.func.isRequired,
  onCloseProductionRecordsModal: PropTypes.func.isRequired,
  handleDeleteRecordEntry: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  resources: PropTypes.array.isRequired,
  editRecord: PropTypes.object,
  t: PropTypes.func.isRequired,
  isLoadingRecordEntries: PropTypes.bool.isRequired,
  assets: PropTypes.array.isRequired,
  roles: PropTypes.array,
  recordTypeOptions: PropTypes.array,
  listOfAllFolders: PropTypes.array,
  numberOfSignatories: PropTypes.number,
};

export default ProductionRecordsAddOrEditModal;
