/* eslint-disable react/prop-types */
import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props} viewBox="0 0 48 48" fill={props.color || 'none'}>
  <path d="M44 24V9H24H4V24V39H24" stroke={props.stroke || '#000000'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M44 34L30 34" stroke={props.stroke || '#000000'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M39 29L44 34L39 39" stroke={props.stroke || '#000000'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M4 9L24 24L44 9" stroke={props.stroke || '#000000'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round" />
</Base>);
